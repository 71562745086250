import { useState } from 'react'
import { gql } from 'graphql-request'
import { useParams, Routes, Route, Link } from 'react-router-dom'
import {
  HomeIcon,
  ArrowUpOnSquareIcon,
  Cog6ToothIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import Button from '@components/Button'
import BreadCrumbs from '@components/Breadcrumbs'
import TabNav from '@components/TabNav'

import Overview from './Overview'
import Settings from './Settings'
import AssignModal from './AssignModal'

const SERIES_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        id
        name
        description
        notes
        visibility
        totalPossibleScore
        coverImage {
          url
        }
        classrooms {
          id
          name
        }
        profile {
          id
          fullName
        }
        organization {
          id
          name
        }
        educatorProjects {
          id
          name
          playground
          position
          totalPossibleScore
        }
      }
    }
  }
`

const SeriesDetails = () => {
  const { id } = useParams()
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)

  const { isLoading, refetch, data: { node: series = {} } = {} } = useQuery({
    queryKey: ['seriesDetails', id],
    gqlQuery: SERIES_QUERY,
    variables: { id }
  })

  if (isLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Pathways' to='/educators/courses' />
        <BreadCrumbs.Text label={series.name} />
      </BreadCrumbs>

      <TabNav>
        <TabNav.Link
          to=''
          end
          icon={<HomeIcon className='size-6 mr-1' />}
          label='Overview'
        />
        <TabNav.Link
          to='settings'
          label='Settings'
          icon={<Cog6ToothIcon className='size-6 mr-1' />}
        />
        
        <div className='ml-auto flex items-center'>
          <Button
            onClick={() => setIsAssignModalOpen(true)}
            className='py-2 sm:mr-5 mr-1 my-1.5 px-3 ml-5'
            label={<span className='flex gap-2 items-center'>Assign<ArrowUpOnSquareIcon className='h-6 w-6' /></span>}
          />
        </div>
      </TabNav>

      <Routes>
        <Route
          index
          element={
            <Overview
              id={series.id}
              name={series.name}
              description={series.description}
              notes={series.notes}
              visibility={series.visibility}
              educatorProjects={series.educatorProjects}
              classrooms={series.classrooms}
              imageUrl={series.coverImage?.url}
              educatorName={series.profile?.fullName}
              organizationName={series.organization?.name}
              projectCount={series.educatorProjects?.length}
              refetch={refetch}
            />
          }
        />

        <Route
          path='settings'
          element={
            <Settings
              id={series.id}
              refetch={refetch}
              name={series.name}
              description={series.description}
              visibility={series.visibility}
              notes={series.notes}
              imageUrl={series.coverImage?.url}
            />
          }
        />
      </Routes>

      <AssignModal
        id={id}
        isOpen={isAssignModalOpen}
        closeModal={() => setIsAssignModalOpen(false)}
        refetch={refetch}
        classrooms={series.classrooms}
        name={series.name}
      />
    </>
  )
}

export default SeriesDetails
