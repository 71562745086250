import { useSearchParams, useNavigate, Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useState } from 'react'
import { UserPlusIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import { useQuery, useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import { useFlashMessage } from '@components/FlashMessage'

import Spinner from '@components/Spinner'
import Notification from '@components/Notification'

import SignUpForm from './SignUpForm'

const CLASSROOM_BY_INVITATION_TOKEN_QUERY = gql`
  query classroomByInvitationToken($invitationToken: String!) {
    classroomByInvitationToken(invitationToken: $invitationToken) {
      name
      educatorProfileName
    }
  }
`
const JOIN_CLASSROOM_BY_INVITATION_MUTATION = gql`
  mutation joinClassroom($input: JoinClassroomInput!) {
    joinClassroom(input: $input) {
      success
      errors {
        message
      }
      organization {
        id
        name
      }
    }
  }
`

const StudentSignUp = () => {
  const { setFlashMessage } = useFlashMessage()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const invitationToken = searchParams.get('invitation_token')
  const { user: { signedIn, single_organization_membership: singleOrganizationMembership } = {}, currentMembership: { role } = {}, refetchUser, switchOrganization } = useCurrentUser()
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  
  const signInUrl = `/sign_in?redirect_to=${window.location.pathname + window.location.search}`

  const { data: { classroomByInvitationToken: classroom } = {}, isLoading } = useQuery({
    queryKey: ['classroomByInvitationToken', invitationToken],
    gqlQuery: CLASSROOM_BY_INVITATION_TOKEN_QUERY,
    variables: { invitationToken },
    disabled: !invitationToken
  })

  const { mutateAsync: joinClassroom, isLoading: isJoining, isError } = useMutation({
    gqlMutation: JOIN_CLASSROOM_BY_INVITATION_MUTATION,
    onSuccess: response => {
      if (response.joinClassroom.success) {
        refetchUser()
        const { organization } = response.joinClassroom
        switchOrganization(organization.id)
        setFlashMessage(`You have joined ${classroom.name} in ${organization.name} 🎉`, 'success')
      }
    }
  })

  if (isLoading) return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />

  return (
    <>
      <Card className='flex flex-col md:w-[450px] self-center md:mt-10 mt-5 p-5'>
        <img src='/brainstorm-board.svg' alt='Brainstorming on a board' className='self-center w-[200px] h-[200px]' />

        <h1 className='text-2xl font-bold mt-3'>Join classroom</h1>

        <Choose>
          <When condition={!classroom}>
            <Notification theme='error' className='my-3'>
              Hmmm we couldn't find that classroom 🤔
            </Notification>

            <p>Oops! Your invitation doesn't seem to be working.</p>
            <p>You may have used it already, or it expired.</p>
          </When>

          <When condition={signedIn}>
            <h2 className='text-base mt-3'>
              You are joining <span className='font-semibold'>{classroom.name}</span> with <span className='font-semibold'>{classroom.educatorProfileName}</span>.
            </h2>

            <If condition={isError}>
              <Notification theme='error' className='mt-3'>
                Oops, something went wrong 🤔
              </Notification>
            </If>

            <Button
              disabled={isJoining}
              label={isJoining ? <Spinner className='**:bg-white' /> : 'Join classroom'}
              className='mt-5 w-full'
              onClick={() => joinClassroom({ input: { invitationToken } })}
            />
          </When>

          <Otherwise>
            <h2 className='text-base mt-3'>
              You are joining <span className='font-semibold'>{classroom.name}</span> with <span className='font-semibold'>{classroom.educatorProfileName}</span>.
            </h2>

            <Choose>
              <When condition={showSignUpForm}>
                <SignUpForm existingAccountUrl={signInUrl} />
              </When>
              <Otherwise>
                <div className='flex flex-col space-y-4 mt-5'>
                  <Button 
                    label={
                      <div className="flex items-center justify-center">
                        <UserPlusIcon className="h-5 w-5 mr-2 mb-1" />
                        I'm new here
                      </div>
                    }
                    className='w-full'
                    onClick={() => setShowSignUpForm(true)}
                  />
                  <Button
                    label={
                      <div className="flex items-center justify-center">
                        <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2 mb-1" />
                        I already have an account
                      </div>
                    }
                    className='w-full'
                    theme='light'
                    variant='outlined'
                    onClick={() => navigate(signInUrl)}
                  />
                </div>
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </Card>
      <If condition={showSignUpForm}>
        <p className='text-center mt-5'>
          Already have an account? <Link className='text-blue-500' to={signInUrl}>Sign in</Link>
        </p>
      </If>
    </>
  )
}

export default StudentSignUp
