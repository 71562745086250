import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, Navigate, useSearchParams, Link } from 'react-router-dom'
import { setUser as setSentryUser } from '@sentry/react'

import { useCurrentUser } from '@contexts/currentUser'
import { useAnalytics } from '@contexts/analytics'
import { client } from '@helpers/api'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import PasswordInput from '@components/PasswordInput'

const SignUpForm = () => {
  const [searchParams] = useSearchParams()
  const { identify } = useAnalytics()
  const token = searchParams.get('invitation_token')
  const navigate = useNavigate()

  const { register, handleSubmit, setError, formState: { errors, isSubmitting } } = useForm({ mode: 'onTouched' })
  const { user: { signedIn }, setUser } = useCurrentUser()

  const { mutateAsync: signUp } = useMutation(
    ['signUpStudent'],
    async variables => await client.post('/student/profiles', variables),
    {
      onSuccess: response => {
        setUser({ loading: false, signedIn: true, ...response.data })
        setSentryUser({ id: response.data.id, email: response.data.email, role: response.data.role })
        identify(response.data.id)
        navigate('/students')
      },
      onError: error => {
        const message = error?.response?.data?.error

        if (message === 'Email has already been taken') {
          setError('email', { message })
        } else if (message === 'Invitation is invalid') {
          setError('general', { message: 'Oops! Your invitation doesn\'t seem to be working. You may have used it already, or it expired.' })
        } else if (message === 'Student limit reached') {
          setError('general', { message: 'Oops! This classroom has reached it\'s student limit. ' })
        } else {
          setError('general', { message: 'Oops! Something went wrong.' })
        }
      }
    }
  )

  const submit = data => {
    signUp({ ...data, token })
  }

  if (signedIn) {
    return <Navigate to='/students' />
  }

  return (
    <div>
      <If condition={errors.general}>
        <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
          {errors.general.message}
        </p>
      </If>

      <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(submit)}>
        <div>
          <TextInput
            id='first-name'
            label='First name'
            required
            {...register('first_name', { required: 'First name is required' })}
          />
          <If condition={errors.first_name}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.first_name.message}</p>
          </If>
        </div>

        <div>
          <TextInput
            id='last-name'
            label='Last name'
            required
            {...register('last_name', { required: 'Last name is required' })}
          />
          <If condition={errors.last_name}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.last_name.message}</p>
          </If>
        </div>

        <div>
          <PasswordInput
            id='password'
            label='Password'
            required
            {...register('password', { required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
          />
          <If condition={errors.password}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password.message}</p>
          </If>
        </div>

        <Button type='submit' label='Sign up' disabled={isSubmitting} />
      </form>
    </div>
  )
}

export default SignUpForm
