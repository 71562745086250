import { Choose, When } from 'babel-plugin-jsx-control-statements'

import Image from '@components/Image'
import PDFDisplay from '@components/DisplayAttachment/PdfDisplay'



const DisplayAttachment = ({ id, url, filename, outline = true }) => {
    const isPDF = filename?.toLowerCase().endsWith('.pdf')

    return (
        <div tabIndex='0' className='group relative rounded-lg w-full'>
            <Choose>
                <When condition={isPDF}>
                    <PDFDisplay url={url} filename={filename} outline={outline} />
                </When>
                <When condition={!isPDF}>
                    <a href={url} target="_blank" rel="noopener noreferrer block w-[auto]">
                        <Image
                            placeholder={<div className='w-[200px] h-[150px] bg-gray-200 animate-pulse rounded-lg' />}
                            key={id}
                            className='object-cover w-[200px] h-[auto] rounded-lg cursor-pointer'
                            src={url}
                            alt={filename}
                        />
                    </a>
                </When>
            </Choose>
        </div>
    )
}

export default DisplayAttachment
