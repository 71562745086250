import { LightBulbIcon, UserPlusIcon, ChatBubbleLeftEllipsisIcon, BookOpenIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import { Squares2X2Icon } from '@heroicons/react/24/solid'

import { useCurrentUser } from '@contexts/currentUser'
import TechnicalDifficultiesBanner from './TechnicalDifficultiesBanner'
import MembershipPicker from './MembershipPicker'
import OnboardingChecklist from '../students/components/OnboardingChecklist'

import Navbar from './Navbar'

const StudentNav = ({ children }) => {
  const { currentMembership } = useCurrentUser()
  const features = currentMembership?.organization?.features || []

  const navigation = [
    { name: 'Home', href: '/students', icon: Squares2X2Icon, end: true },
    { name: 'Tutors', href: '/students/tutors', icon: ChatBubbleLeftEllipsisIcon }
  ]

  if (features.includes('legacy_features')) {
    navigation.push({ name: 'Pathways', href: '/students/courses', icon: BookOpenIcon })
  }

  if (features.includes('tutor_insights')) {
    navigation.push({ name: 'Insights', href: '/students/tutor_insights', icon: LightBulbIcon })
  }

  if (features.includes('student_events')) {
    navigation.push({ name: 'Workshops', href: '/students/workshops', icon: CalendarDaysIcon })
  }

  navigation.push({ name: 'Student join code', href: '/join_short_code', icon: UserPlusIcon })

  const technicalDifficultiesBannerEnabled = features.includes('technical_difficulties_banner')

  return (
    <Navbar
      membershipPicker={<MembershipPicker />}
      navigation={navigation}
      message={
        <>
          <If condition={technicalDifficultiesBannerEnabled}>
            <TechnicalDifficultiesBanner />
          </If>

          <OnboardingChecklist />
        </>
      }
    >
      {children}
    </Navbar>
  )
}

export default StudentNav
