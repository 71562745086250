import { gql } from 'graphql-request'
import { useQueryClient } from '@tanstack/react-query'

import { useQuery } from '@hooks/graphql'

const ONBOARDING_CHECKLIST_QUERY = gql`
  query studentOnboardingChecklist {
    me {
      profile: newProfile {
        studentOnboardingChecklistCompleted
        studentOnboardingChecklist {
          chatWithTutor
          startedSeries
          submittedAssignment
        }
      }
    }
  }
`

const useOnboardingChecklist = () => {
  const {
    isLoading,
    data: {
      me: {
        profile: {
          studentOnboardingChecklistCompleted: onboardingChecklistCompleted,
          studentOnboardingChecklist: onboardingChecklist = {}
        } = {}
      } = {}
    } = {}
  } = useQuery({
    queryKey: ['studentOnboardingChecklist'],
    gqlQuery: ONBOARDING_CHECKLIST_QUERY,
    staleTime: 86400000
  })

  const queryClient = useQueryClient()

  const markOnboardingItemCompleted = (itemKey) => {
    queryClient.setQueryData(['studentOnboardingChecklist'], (old) => {
      if (!old?.me?.profile?.studentOnboardingChecklist) {
        return old
      }    

      const newChecklist = {
        ...old.me.profile.studentOnboardingChecklist
      }

      newChecklist[itemKey] = true

      return {
        me: {
          profile: {
            ...old.me.profile,
            studentOnboardingChecklist: newChecklist
          }
        }
      }
    })
  }

  const dismissOnboardingChecklist = () => {
    queryClient.setQueryData(['studentOnboardingChecklist'], (_old) => ({
      me: {
        profile: {
          studentOnboardingChecklistCompleted: true
        }
      }
    }))
  }

  return {
    isLoading,
    onboardingChecklistCompleted,
    onboardingChecklist,
    markOnboardingItemCompleted,
    dismissOnboardingChecklist
  }
}

export { useOnboardingChecklist }
