const ProfileCardSection = ({ title, children }) => {
  return (<>
    <div className='py-5 px-6'>
      <If condition={title}>
        <h1 className='font-dmMono uppercase font-medium opacity-50 text-mirage-950 text-xs mb-3'>
          {title}
        </h1>
      </If>
      {children}
    </div>
  </>)
}

export default ProfileCardSection
