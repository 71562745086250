import { Popover, Transition } from '@headlessui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { twMerge } from 'tailwind-merge'
import { Link, useNavigate } from 'react-router-dom'
import {
  ChevronDownIcon,
  CheckIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
  ArrowLeftEndOnRectangleIcon,
  BuildingOffice2Icon
} from '@heroicons/react/24/outline'
import { startCase } from 'lodash'

import { useCurrentUser } from '@contexts/currentUser'
import { client } from '@helpers/api'

const organizationInitial = name => {
  if (name) return name.charAt(0)

  return 'M'
}

const MembershipPicker = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    user: { email, memberships } = {},
    clearUser,
    currentMembership: { organization: { id, name: organizationName }, role: currentRole } = {},
    switchOrganization
  } = useCurrentUser()

  const { mutate: signOut } = useMutation(['signOut'], async () => client.delete('/users/sign_out'),
    {
      onSuccess: () => {
        clearUser()
        queryClient.clear()
        navigate('/sign_in')
      }
    }
  )

  return (
    <Popover className='relative -mx-2'>
      <Transition
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <Popover.Panel className='absolute w-full bottom-full left-0 z-10 py-2 mb-2 origin-bottom-left rounded-lg bg-white shadow-xl ring-1 ring-black/5 focus:outline-hidden'>
          <If condition={memberships.length > 1}>
            <div className='border-b border-gray-200'>
              <For each='membership' of={memberships}>
                <button
                  key={membership.organization.id}
                  onClick={() => switchOrganization(membership.organization.id)}
                  className={twMerge(
                    'flex items-start gap-x-3 w-full px-2 py-2 text-left hover:bg-gray-50 leading-none',
                    membership.organization.id === id && 'bg-gray-50'
                  )}
                >
                  <div className='flex items-center justify-center shrink-0 size-7 rounded-lg bg-gray-100 text-gray-600 text-base leading-none'>
                    {organizationInitial(membership.organization.name)}
                  </div>

                  <div className='flex flex-col'>
                    <span className='font-base'>{membership.organization.name}</span>
                    <span className='text-xs text-gray-600'>{startCase(membership.role)}</span>
                  </div>

                  <If condition={membership.organization.id === id}>
                    <CheckIcon className='h-5 w-5 text-blue-600' aria-hidden='true' />
                  </If>
                </button>
              </For>
            </div>
          </If>

          <div className='px-4 py-2 space-y-2 leading-none'>
            <div className='text-sm text-gray-900 mb-3 break-words'>{email}</div>

            <Choose>
              <When condition={currentRole === 'admin'}>
                <Popover.Button
                  as={Link}
                  to='/educators/settings'
                  className='flex items-center gap-x-2 text-sm text-gray-600'
                >
                  <Cog6ToothIcon className='size-5 text-gray-600' aria-hidden='true' />
                  Settings
                </Popover.Button>

                <Popover.Button
                  as={Link}
                  to='/educators/organization'
                  className='flex items-center gap-x-2 text-sm text-gray-600'
                >
                  <BuildingOffice2Icon className='size-5 text-gray-600' aria-hidden='true' />
                  Organization
                </Popover.Button>

                <Popover.Button
                  as='a'
                  href='https://coda.io/d/_dazL7wOvRR3/Frequently-Asked-Questions_suRhK'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-x-2 text-sm text-gray-600'
                >
                  <QuestionMarkCircleIcon className='size-5 text-gray-600' aria-hidden='true' />
                  Help
                </Popover.Button>
              </When>

              <When condition={currentRole === 'educator'}>
                <Popover.Button
                  as={Link}
                  to='/educators/settings'
                  className='flex items-center gap-x-2 text-sm text-gray-600'
                >
                  <Cog6ToothIcon className='size-5 text-gray-600' aria-hidden='true' />
                  Settings
                </Popover.Button>

                <Popover.Button
                  as='a'
                  href='https://coda.io/d/_dazL7wOvRR3/Frequently-Asked-Questions_suRhK'
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-x-2 text-sm text-gray-600'
                >
                  <QuestionMarkCircleIcon className='size-5 text-gray-600' aria-hidden='true' />
                  Help
                </Popover.Button>
              </When>

              <When condition={currentRole === 'student'}>
                <Popover.Button
                  as={Link}
                  to='/students/settings'
                  className='flex items-center gap-x-2 text-sm text-gray-600'
                >
                  <Cog6ToothIcon className='size-5 text-gray-600' aria-hidden='true' />
                  Settings
                </Popover.Button>
              </When>
            </Choose>

            <Popover.Button
              as='button'
              onClick={signOut}
              className='flex items-center gap-x-2 text-sm text-gray-600'
            >
              <ArrowLeftEndOnRectangleIcon className='size-5 text-gray-600' aria-hidden='true' />
              Sign out
            </Popover.Button>
          </div>

        </Popover.Panel>
      </Transition>

      <Popover.Button className='w-full flex items-center gap-x-3 text-lg text-gray-700 p-1 rounded-lg leading-none hover:bg-gray-50 ring-inset focus:ring-2 focus:ring-blue-600 focus:outline-hidden'>
        <div className='flex items-center justify-center size-7 text-base shrink-0 rounded-lg bg-gray-100 text-gray-600'>
          {organizationInitial(organizationName)}
        </div>

        <span className='text-base leading-tight truncate'>{organizationName}</span>

        <ChevronDownIcon className='shrink-0 ml-auto size-5 text-gray-500' aria-hidden='true' />
      </Popover.Button>
    </Popover>
  )
}

export default MembershipPicker
