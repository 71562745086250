import { twMerge } from 'tailwind-merge'

const buttonTheme = (variant, theme) => {
  if (variant === 'default') {
    switch (theme) {
      case 'success':
        return 'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600'
      case 'secondary':
        return 'bg-purple-600 hover:bg-purple-500 focus-visible:outline-purple-600'
      case 'error':
        return 'bg-red-600 hover:bg-red-500 focus-visible:outline-blue-600'
      default:
        return 'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600'
    }
  }

  if (variant === 'outlined') {
    switch (theme) {
      case 'secondary':
        return 'border bg-white text-purple-600 border-purple-600 hover:bg-purple-50 focus-visible:outline-purple-600'
      case 'error':
        return 'border bg-white text-red-600 border-red-600 hover:bg-red-50 focus-visible:outline-red-600'
      case 'success':
        return 'border bg-white text-green-600 border-green-600 hover:bg-green-50 focus-visible:outline-green-600'
      case 'light':
        return 'border bg-white text-gray-600 border-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
      default:
        return 'border bg-white text-blue-600 border-blue-600 hover:bg-blue-50 focus-visible:outline-blue-600'
    }
  }
}

const Button = ({
  id,
  label,
  onClick,
  type = 'button',
  className,
  variant = 'default',
  theme = 'primary',
  disabled = false,
  ...rest
}) => (
  <button
    id={id}
    type={type}
    className={
      twMerge(
        'rounded-md px-3.5 py-2.5 text-base font-semibold text-white shadow-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        'disabled:cursor-not-allowed',
        buttonTheme(variant, theme),
        className
      )
    }
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {label}
  </button>
)

export default Button
export { buttonTheme }
