import { gql } from 'graphql-request'
import { useParams, Routes, Route, useLocation } from 'react-router-dom'
import { Cog6ToothIcon, IdentificationIcon, UsersIcon } from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import BreadCrumbs from '@components/Breadcrumbs'
import TabNav from '@components/TabNav'

import Overview from './Overview'
import EducatorList from './EducatorList'
import EducatorDetails from './EducatorDetails'
import Settings from './Settings'

const ORGANIZATION_QUERY = gql`
  query organization($id: ID!) {
    node(id: $id) {
      ... on Organization {
        id
        name
        createdAt
        verificationStatus
        privateStudentChatsEnabled
        organizationType
        accountStatus
        educators {
          role
          profile {
            id
            fullName
            email
            user {
              id
            }
          }
        }
      }
    }
  }
`

const OrganizationDetails = () => {
  const { id } = useParams()
  const location = useLocation()

  const previousListParams = location.state?.previousListParams || ''

  const { isLoading, refetch, data: { node: organization = {} } = {} } = useQuery({
    queryKey: ['organization', id],
    gqlQuery: ORGANIZATION_QUERY,
    variables: { id }
  })

  if (isLoading) return

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Organizations' to={`/admin/organizations${previousListParams}`} />
        <BreadCrumbs.Text label={organization.name} />
      </BreadCrumbs>

      <TabNav>
        <TabNav.Link
          to=''
          end
          label='Overview'
          icon={<IdentificationIcon className='h-6 w-6 mr-1' />}
        />
        <TabNav.Link
          to='educators'
          label='Educators'
          icon={<UsersIcon className='h-6 w-6 mr-1' />}
        />
        <TabNav.Link
          to='settings'
          label='Settings'
          icon={<Cog6ToothIcon className='h-6 w-6 mr-1' />}
        />
      </TabNav>

      <Routes>
        <Route
          index
          element={
            <Overview
              organizationId={id}
              name={organization.name}
              createdAt={organization.createdAt}
              verificationStatus={organization.verificationStatus}
              educatorCount={organization.educators.length}
            />
          }
        />

        <Route
          path='educators/:id'
          element={
            <EducatorDetails
              organizationId={id}
            />
          }
        />

        <Route
          path='educators'
          element={
            <EducatorList
              organizationId={id}
              educators={organization.educators}
              refetch={refetch}
            />
          }
        />

        <Route
          path='settings'
          element={
            <Settings
              organizationId={id}
              name={organization.name}
              verificationStatus={organization.verificationStatus}
              privateStudentChatsEnabled={organization.privateStudentChatsEnabled}
              organizationType={organization.organizationType}
              accountStatus={organization.accountStatus}
              refetch={refetch}
            />
          }
        />
      </Routes>
    </>
  )
}

export default OrganizationDetails
