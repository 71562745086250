import { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { createConsumer } from '@rails/actioncable'

// Inspired by https://github.com/aersoftware/react-use-action-cable

const ActionCableContext = createContext()
const ActionCableProvider = ({ actionCable, children }) => (
  <ActionCableContext.Provider value={{ actionCable }}>
    {children}
  </ActionCableContext.Provider>
)

const useActionCable = (url, { verbose } = { verbose: false }) => {
  const actionCable = useMemo(() => createConsumer(url), [])

  useEffect(() => {
    return () => {
      actionCable.disconnect()
    }
  }, [])

  return {
    actionCable
  }
}

const useChannel = () => {
  const context = useContext(ActionCableContext)

  if (!context) {
    throw new Error('useChannel must be used within an ActionCableProvider')
  }

  const { actionCable } = context
  const channelRef = useRef()

  const subscribe = (data, callbacks) => {
    const channel = actionCable.subscriptions.create(data, {
      received: response => {
        if (callbacks.received) callbacks.received(response)
      },
      initialized: () => {
        if (callbacks.initialized) callbacks.initialized()
      },
      connected: () => {
        if (callbacks.connected) callbacks.connected()
      },
      disconnected: () => {
        if (callbacks.disconnected) callbacks.disconnected()
      },
      rejected: () => {
        channelRef.current = null
        if (callbacks.rejected) callbacks.rejected()
      }
    })

    channelRef.current = channel
  }

  const unsubscribe = () => {
    if (channelRef.current) {
      actionCable.subscriptions.remove(channelRef.current)
      channelRef.current = null
    }
  }

  useEffect(() => {
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    subscribe,
    unsubscribe
  }
}

export {
  ActionCableProvider,
  useActionCable,
  useChannel
}
