import { useCurrentUser } from '@contexts/currentUser'
import { gql } from 'graphql-request'
import { useQuery } from '@hooks/graphql'

import { Link } from 'react-router-dom'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

import Card from './Card'
import CourseCard from './CourseCard'
import EmptyNotice from './EmptyNotice'
import { useBackground } from '@hooks/useBackground'

const HOME_PAGE_QUERY = gql`
  query homePage {
    assignedSeries(page: 1, perPage: 4, closed: false) {
      nodes {
        id
        name
        description
        createdAt
        coverImage {
          url
        }
        assignment {
          id
          closed
          startDate
          dueDate
          currentTime
        }
      }
      pagesCount
      nodesCount
    }
    tutorAssignments(page:1, perPage: 4, closed: false) {
      nodes {
        id
        tutor {
          id
          name
          summary
          image {
            url
          }
        }
      }
    }
  }
`

const Home = () => {
  const { user } = useCurrentUser()
  useBackground('white')

  const {
    isLoading,
    data: {
      assignedSeries: { nodes: series = [] } = {},
      tutorAssignments: { nodes: tutorAssignments = [] } = {}
    } = {}
  } = useQuery({
    queryKey: ['homePage'],
    gqlQuery: HOME_PAGE_QUERY,
    keepPreviousData: true,
    refetchInterval: 60000
  })

  const hour = new Date().getHours()
  const salutation = hour < 12 ? "🌅 Morning" : hour < 18 ? "☀️ Afternoon" : "🌙 Evening"

  const hasTutors = !!tutorAssignments.length
  const hasSeries = !!series.length
  const pageEmpty = !hasTutors && !hasSeries

  return (
    <div className="min-h-screen bg-white py-6 px-8">

      <div className="border-b border-mirage-100 pb-7 pt-2">
        <h1 className="text-2xl font-semibold text-mirage-800">
          {salutation}, {user.first_name}
        </h1>
      </div>

      <If condition={hasSeries}>
        <div className="py-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-semibold text-mirage-950">
              Pathways
            </h2>

            <Link to='/students/courses' className="text-lg text-mirage-950 hover:text-mirage-800 font-semi flex gap-2 items-center">
              <div>See All</div>
              <ArrowRightIcon className="w-5 h-5 mb-1" />
            </Link>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <For each='item' index='index' of={series}>
              <CourseCard
                courseId={item.id}
                imageUrl={item.coverImage?.url}
                name={item.name}
                description={item.description}
                startDate={item.assignment?.startDate}
                dueDate={item.assignment?.dueDate}
                currentTime={item.assignment?.currentTime}
              />
            </For>
          </div>
        </div>
      </If>

      <If condition={hasTutors}>
        <div className="py-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-semibold text-mirage-950">
              Tutors
            </h2>

            <Link to='/students/tutors' className="text-lg text-mirage-950 hover:text-mirage-800 font-semi flex gap-2 items-center">
              <div>See All</div>
              <ArrowRightIcon className="w-5 h-5 mb-1" />
            </Link>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <For each='tutorAssignment' index='index' of={tutorAssignments}>
              <Link key={index} to={`/students/tutors/assignments/${tutorAssignment.id}`} className={index == 3 ? 'xl:hidden' : ''}>
                <With tutor={tutorAssignment.tutor}>
                  <Card
                    imageUrl={tutor.image?.url}
                    title={tutor.name}
                    subtitle={tutor.summary}
                  />
                </With>
              </Link>
            </For>
          </div>
        </div>
      </If>

      <If condition={pageEmpty && !isLoading}>
        <EmptyNotice />
      </If>
    </div>
  )
}

export default Home
