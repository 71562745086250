import { RadioGroup } from '@headlessui/react'

const SelectFieldOption = ({ option, children }) => {
  return (
    <RadioGroup.Option value={option.value}>
      {({ checked }) => (
        <div
          className={`
            hover:cursor-pointer text-mirage-950 py-3 px-5 rounded-xl
            transition-colors duration-500 ease-in-out
            ${checked
              ? 'bg-mirage-100 border-2 border-dashed border-mirage-950'
              : 'bg-mirage-50 shadow-[0_2px_1px_0_#EBEBF3] border-2 border-gray-50'}
          `}
        >
          {children}
        </div>
      )}
    </RadioGroup.Option>
  )
}

export default SelectFieldOption
