import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'

import { client } from '@helpers/api'
import { useFlashMessage } from '@components/FlashMessage'
import Button from '@components/Button'
import Card from '@components/Card'
import PasswordInput from '@components/PasswordInput'

const ResetPassword = () => {
  const { register, handleSubmit, watch, formState: { errors }, setError, clearErrors } = useForm({ mode: 'onTouched' })
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setFlashMessage } = useFlashMessage()
  const resetPasswordToken = searchParams.get('reset_password_token')

  const { mutate: resetPassword, isLoading } = useMutation({
    mutationFn: async variables => await client.patch('/users/password', variables),
    onSuccess: () => {
      queryClient.clear()
      navigate('/', { replace: true })
      setFlashMessage('Password updated successfully', 'success')
    },
    onError: () => {
      setError('general', { message: 'Oops! It looks like your password reset link has expired.' })
    }
  })

  const submit = data => {
    resetPassword({ user: { ...data, reset_password_token: resetPasswordToken } })
  }

  const password = watch('password')

  return (
    <Card className='sm:w-[400px] md:mt-10 mt-5 self-center p-5'>
      <h1 className='text-2xl font-bold'>Update password</h1>

      <form className='flex flex-col space-y-4 mt-5' onSubmit={handleSubmit(submit)}>
        <If condition={errors.general}>
          <p className='text-sm text-red-500 font-semibold' role='alert'>
            {errors.general.message}
          </p>

          <Link to='/request_password_reset' className='inline text-blue-600 underline text-sm font-semibold'>Request a new link</Link>
        </If>

        <div>
          <PasswordInput
            id='password'
            label='Password'
            required
            {...register('password', { required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
          />

          <If condition={errors.password}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password.message}</p>
          </If>
        </div>

        <div>
          <PasswordInput
            id='password-confirmation'
            label='Password confirmation'
            required
            {...register('password_confirmation', { required: 'Password confirmation is required', validate: value => value === password || 'Passwords must match' })}
          />

          <If condition={errors.password_confirmation}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.password_confirmation.message}</p>
          </If>
        </div>

        <Button
          type='submit'
          label={isLoading ? 'Updating...' : 'Update password'}
        />
      </form>
    </Card>
  )
}

export default ResetPassword
