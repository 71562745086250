import { useState } from 'react'
import { useNavigate, Link, useSearchParams, useLocation } from 'react-router-dom'
import { gql } from 'graphql-request'

import { useCurrentUser } from '@contexts/currentUser'
import { useQuery } from '@hooks/graphql'
import PageHeading from '@components/PageHeading'
import Spinner from '@components/Spinner'
import Button from '@components/Button'
import Pagination from '@components/Pagination'
import Select from '@components/Select'
import LinkButton from '@components/LinkButton'
import TutorCard from '@components/TutorCard'
import SearchInput from '@components/SearchInput'

import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'
import NoResults from './NoResults'
import EmptyList from './EmptyList'
import Tag from './Tag'

const TAGS_QUERY = gql`
  query tutorTags {
    tutorTags {
      id
      slug
      name
      color
    }
  }
`

const TUTORS_QUERY = gql`
  query tutors($audience: AudienceEnum!, $search: String, $tags: [String!], $page: Int) {
    tutors(audience: $audience, search: $search, tags: $tags, page: $page, perPage: 8) {
      nodes {
        id
        name
        createdAt
        description
        summary
        visibility
        organizationName
        userName
        image {
          url
        }
      }
      pagesCount
      nodesCount
    }
  }
`

const TutorList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { hasProFeatures } = useSubscription()

  const audience = searchParams.get('audience') || 'me'
  const page = searchParams.get('page') || 1
  const search = searchParams.get('search') || ''
  const tags = searchParams.getAll('tags') || []
  const filtersApplied = (audience && audience !== 'me' && audience !== 'organization') || (page && page !== 1) || search || tags.length > 0
  const [searchTerm, setSearchTerm] = useState(search)

  const { currentMembership: { organization: { name: organizationName } } } = useCurrentUser()

  const { isLoading, data: { tutors: { nodes: tutors = [], nodesCount, pagesCount } = {} } = {} } = useQuery({
    queryKey: ['tutors', page, audience, tags, search],
    gqlQuery: TUTORS_QUERY,
    variables: { page: parseInt(page), audience: audience.toUpperCase(), tags, search }
  })

  const { data: { tutorTags: allTags = [] } = {} } = useQuery({
    queryKey: ['tutorTags'],
    gqlQuery: TAGS_QUERY
  })

  const updateParams = newParams => {
    const params = {
      audience,
      page,
      search,
      tags,
      ...newParams
    }

    if (params.page === 1) delete params.page
    if (params.search === '') delete params.search
    if (params.audience === 'me') delete params.audience
    if (params.tags.length === 0) delete params.tags

    setSearchParams(params)
  }

  const clearFilters = () => {
    setSearchTerm('')
    setSearchParams({})
  }

  const clearSearch = () => {
    setSearchTerm('')
    updateParams({ search: '', page: 1 })
  }

  return (
    <>
      <PageHeading title='Tutors'>
        <Button
          id='new-tutor-button'
          disabled={!hasProFeatures}
          size='sm'
          theme='secondary'
          className='ml-auto'
          onClick={() => navigate('/educators/tutors/new', { state: { previousListParams: location.search } })}
          label='New tutor'
        />

        <If condition={!hasProFeatures}>
          <ProFeatureTooltip anchorSelect='#new-tutor-button' />
        </If>
      </PageHeading>

      <div className='h-[calc(100%-45px)] flex flex-col sm:px-5'>
        <div className='flex mt-5 gap-3 sm:px-0 px-5'>
          <Select
            value={audience}
            className='[&_select]:h-full w-[200px]'
            onChange={event => updateParams({ audience: event.target.value, page: 1 })}
          >
            <Select.Option value='me'>My tutors</Select.Option>
            <Select.Option value='organization'>{organizationName ? `${organizationName}'s tutors` : 'Your organization\'s tutors'}</Select.Option>
            <Select.Option value='community'>Community tutors</Select.Option>
          </Select>

          <SearchInput
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
            onSearch={() => updateParams({ search: searchTerm, page: 1 })}
            onClear={clearSearch}
          />
        </div>

        <div className='flex relative items-center'>
          <div className='absolute top-0 right-0 h-full z-10 w-[100px] sm:mr-[100px] mr-0 bg-linear-to-l from-gray-50 to-transparent pointer-events-none' />
          <div className='relative z-0 sm:px-0 px-5 flex mt-3 mb-5 gap-3 w-full overflow-x-auto'>
            <For each='tag' of={allTags}>
              <Tag
                selected={tags.includes(tag.slug)}
                key={tag.slug}
                onClick={() => updateParams({ tags: [tag.slug], page: 1 })}
                color={tag.color}
                name={tag.name}
              />
            </For>
          </div>

          <LinkButton
            disabled={!filtersApplied}
            className='w-[100px] sm:block hidden shrink-0 text-gray-600 hover:text-gray-900' onClick={clearFilters}
          >
            clear search
          </LinkButton>
        </div>

        <Choose>
          <When condition={isLoading}>
            <Spinner className='flex items-center justify-center w-full h-full' />
          </When>

          <When condition={filtersApplied && nodesCount === 0}>
            <NoResults clearFilters={clearFilters} />
          </When>

          <When condition={!filtersApplied && nodesCount === 0}>
            <EmptyList />
          </When>

          <Otherwise>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5'>
              <For each='tutor' of={tutors}>
                <Link
                  key={tutor.id}
                  to={`/educators/tutors/${tutor.id}`}
                  state={{ previousListParams: location.search }}
                >
                  <TutorCard
                    id={tutor.id}
                    organizationName={tutor.organizationName}
                    userName={tutor.userName}
                    name={tutor.name}
                    description={tutor.summary || tutor.description}
                    imageUrl={tutor.image?.url}
                  />
                </Link>
              </For>
            </div>

            <Pagination page={parseInt(page)} pagesCount={pagesCount} setPage={page => updateParams({ page })} />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default TutorList
