import { twMerge } from "tailwind-merge"

const ProfileCardProgressStat = ({ title, percent, className='' }) => {

  return (
    <div className='my-1 flex flex-row w-full items-center gap-3'>
      <div className='basis-1/2'>
        {title}
      </div>
      <div className='basis-1/2 h-2 bg-mirage-100 rounded-sm overflow-hidden'>
        <div className={twMerge('h-full bg-blue-400', className)} style={{ width: `${percent}%` }}></div>
      </div>
    </div>
  )
}

export default ProfileCardProgressStat
