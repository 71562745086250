import { useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { SparklesIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { useMutation } from '@hooks/graphql'
import CircleSpinner from '@components/CircleSpinner'
import TextEditor from '@components/TextEditor'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'
import Tooltip from '@components/Tooltip'
import DisplayAttachment from '@components/DisplayAttachment/DisplayAttachment'
import { usePrompt } from '@hooks/prompt'
import { useFlashMessage } from '@components/FlashMessage'

const CREATE_FREE_RESPONSE_SUBMISSION_MUTATION = gql`
  mutation CreateFreeResponseSubmission($input: CreateFreeResponseSubmissionInput!) {
    createFreeResponseSubmission(input: $input) {
      blockSubmission {
        content {
          ... on FreeResponseSubmission {
            answer
            attachments {
              id
              filename
              url
            }
          }
        }
      }

      errors {
        message
      }

    }
  }
`

const UPLOAD_ATTACHMENTS_MUTATION = gql`
  mutation uploadEducatorProjectAttachments($input: UploadEducatorProjectAttachmentsInput!) {
    uploadEducatorProjectAttachments(input: $input) {
      attachments {
        id
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const MAX_ATTACHMENTS = 5
const MAX_PDF_ATTACHMENTS = 3

const FreeResponseBlock = ({
  blockId,
  submissionId,
  assignmentId,
  question,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  isSubmissionClosed,
  submissionStatus,
  answer,
  marks,
  markAllocation,
  feedback,
  automarkedResult,
  attachments = [],
  isTimeUp
}) => {
  const queryClient = useQueryClient()
  const [isSaving, setIsSaving] = useState(false)
  const { setFlashMessage } = useFlashMessage()

  const { mutateAsync: createFreeResponseSubmission } = useMutation({
    gqlMutation: CREATE_FREE_RESPONSE_SUBMISSION_MUTATION,
    onSuccess: (data) => { 
      // Check for errors in the successful response
      const errors = data?.createFreeResponseSubmission?.errors || [];
      if (errors.length > 0) {
        // Display the error message from the server
        setError('attachments', { message: errors[0].message });
        return;
      }
      queryClient.invalidateQueries('educatorProjectSubmission') 
    },
    onError: (error) => {
      setFlashMessage('Oops, we cannot save your work there seems to be an issue. Please try again.', 'error')
    }
  })

  const { mutateAsync: uploadAttachments, isLoading: isUploading } = useMutation({
    gqlMutation: UPLOAD_ATTACHMENTS_MUTATION
  })

  const { handleSubmit, formState: { errors, isDirty }, control, setError, clearErrors, getValues } = useForm({
    mode: 'onTouched',
    defaultValues: { answer, attachments }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments'
  })

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave?',
    isDirty && !isTimeUp && !isLastBlock
  )

  // Wrapper to check if the form is dirty before submitting
  const submitAnswer = async data => {
    if (!isDirty) return true
    
    setIsSaving(true)
    
    const attachmentIds = data.attachments?.map(attachment => attachment.id) || []
    try {
      const result = await createFreeResponseSubmission({ 
        input: { 
          blockId, 
          educatorProjectSubmissionId: submissionId, 
          assignmentId, 
          answer: data.answer, 
          attachmentIds 
        } 
      })
      
      const errors = result?.createFreeResponseSubmission?.errors || []
      return errors.length === 0
    } catch (error) {
      return false
    } finally {
      setIsSaving(false)
    }
  }

  const submit = async data => {
    if (isLastBlock) {
      const success = await submitAnswer(data)
      if (success) {
        openSubmitModal()
      }
    } else if (submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed || isTimeUp) {
      moveToNextBlock()
    } else {
      const success = await submitAnswer(data)
      if (success) {
        moveToNextBlock()
      } 
    }
  }

  const handleBack = async () => {
    const data = getValues()
    setIsSaving(true)
    const success = await submitAnswer(data)
    if (success) {
      moveToPreviousBlock()
    }
  }

  const handleFilesAttached = async event => {
    clearErrors()

    const files = Array.from(event.target.files)

    // Check that files are either images or PDFs
    if (files.some(file => !file.type.startsWith('image/') && file.type !== 'application/pdf')) {
      setError('attachments', { message: 'Files must be images or PDFs.' })
      return
    }

    // File size limit
    if (files.some(file => file.size > 10000000)) { // 10mb limit
      setError('attachments', { message: 'Files must be less than 10MB in size.' })
      return
    }

    // Count PDFs in the current upload
    const pdfCount = files.filter(file => file.type === 'application/pdf').length
    if (pdfCount > MAX_PDF_ATTACHMENTS) {
      setError('attachments', { message: `You can only upload up to ${MAX_PDF_ATTACHMENTS} PDF files at a time.` })
      return
    }
    
    // Check total attachment limit (5)
    if (fields.length + files.length > MAX_ATTACHMENTS) {
      setError('attachments', { message: `You can only have a maximum of ${MAX_ATTACHMENTS} attachments in total.` })
      return
    }

    const { uploadEducatorProjectAttachments: { attachments = [], errors = [] } } = await uploadAttachments({ input: { attachments: files } })

    if (errors.length > 0) {
      setError('attachments', { message: errors[0].message })
    } else {
      append(attachments)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(submit)}
    >
      <SecureMarkdown className='mb-3' content={question} />

      <Choose>
        <When condition={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED'}>
          <div className='bg-gray-200 p-3 rounded-lg mt-5'>
            <SecureMarkdown content={answer} />

            <If condition={attachments.length > 0}>
              <div className='flex flex-wrap gap-3'>
                <For each='attachment' of={attachments}>
                  <DisplayAttachment
                    key={attachment.id}
                    id={attachment.id}
                    filename={attachment.filename}
                    url={attachment.url}
                    outline={false}
                  />

                </For>
              </div>
            </If>
          </div>

          <If condition={submissionStatus === 'RETURNED'}>
            <div className='w-fit bg-gray-200 px-2 py-1 rounded-lg leading-tight mt-3'>
              <span className='text-lg font-semibold mb-1'>{marks} / {markAllocation}</span>
            </div>

            <If condition={feedback}>
              <div className='flex flex-col  bg-purple-100 p-3 rounded-lg leading-tight mt-3'>
                <If condition={automarkedResult}>
                  <div className='flex items-center mb-1'>
                    <SparklesIcon className='size-5 block mr-2 shrink-0' />
                    <h3 className='text-lg font-semibold'>AI feedback</h3>
                  </div>
                </If>

                <SecureMarkdown content={feedback} />
              </div>
            </If>
          </If>
        </When>

        <Otherwise>
          <TextEditor.Container>
            <If condition={fields.length > 0}>
              <TextEditor.AttachmentList>
                <For each='attachment' of={fields}>
                  <TextEditor.Attachment
                    key={attachment.id}
                    id={attachment.id}
                    filename={attachment.filename}
                    url={attachment.url}
                    remove={() => {
                      remove(fields.findIndex(field => field.id === attachment.id));
                      clearErrors('attachments');
                    }}
                  />
                </For>
              </TextEditor.AttachmentList>
            </If>

            <Controller
              name='answer'
              control={control}
              render={({ field }) => (
                <TextEditor
                  disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isTimeUp}
                  className='min-h-[200px]'
                  {...field}
                />
              )}
            />

            <TextEditor.Actions>
              <Choose>
                <When condition={isUploading}>
                  <CircleSpinner className='p-1 mr-2' />
                </When>

                <Otherwise>
                  <TextEditor.FileInput
                    onChange={handleFilesAttached}
                    disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isTimeUp}
                    accept="image/*,.pdf,application/pdf"
                    fileType="document"
                  />
                  <TextEditor.FileInput
                    onChange={handleFilesAttached}
                    disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isTimeUp}
                    accept="image/*,.pdf,application/pdf"
                    fileType="image"
                  />
                  
                </Otherwise>
              </Choose>

              <TextEditor.ToolbarToggle />
            </TextEditor.Actions>
          </TextEditor.Container>

          <If condition={errors.answer}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
          </If>

          <If condition={errors.attachments}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.attachments.message}</p>
          </If>
        </Otherwise>
      </Choose>

      <div className='flex flex-row items-center justify-between mt-5'>
        <LinkButton
          type='button'
          disabled={isFirstBlock || isSaving}
          onClick={handleBack}
        >
          Back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              type='submit'
              id='submit-button'
              className='self-end'
              disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed || isSaving}
              label={(submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED') ? 'Submitted' : (isSaving ? 'Saving...' : 'Submit')}
            />

            <If condition={isSubmissionClosed}>
              <Tooltip anchorSelect='#submit-button' delayShow={200}>
                Submissions are now closed for this lesson
              </Tooltip>
            </If>
          </When>

          <Otherwise>
            <Button
              type='submit'
              className='self-end'
              disabled={isSaving}
              label={isSaving ? 'Saving...' : 'Next'}
            />
          </Otherwise>
        </Choose>
      </div>
    </form>
  )
}

export default FreeResponseBlock
