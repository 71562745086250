import SecureMarkdown from '@components/SecureMarkdown'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import Tooltip from '@components/Tooltip'

import { useUpdateBlockSubmission } from './hooks'

const TextBlock = ({
  content,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  isSubmissionClosed,
  openSubmitModal,
  submissionStatus,
  submissionId,
  blockId
}) => {
  const { updateBlockSubmission } = useUpdateBlockSubmission({ educatorProjectSubmissionId: submissionId })

  const next = () => {
    if (submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed) {
      moveToNextBlock()
    } else {
      updateBlockSubmission({ blockId, status: 'COMPLETED' })
      moveToNextBlock()
    }
  }

  const submitProject = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    openSubmitModal()
  }

  return (
    <>
      <SecureMarkdown content={content} />

      <div className='flex flex-row items-center justify-between mt-3'>
        <LinkButton
          disabled={isFirstBlock}
          onClick={moveToPreviousBlock}
        >
          Back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              id='submit-button'
              className='self-end'
              disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed}
              label={(submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED') ? 'Submitted' : 'Submit'}
              onClick={submitProject}
            />

            <If condition={isSubmissionClosed}>
              <Tooltip anchorSelect='#submit-button' delayShow={200}>
                Submissions are closed for this pathway
              </Tooltip>
            </If>
          </When>

          <Otherwise>
            <Button
              className='self-end'
              label='Next'
              onClick={next}
            />
          </Otherwise>
        </Choose>
      </div>
    </>
  )
}

export default TextBlock
