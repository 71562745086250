import Image from '@components/Image'

const Card = ({ imageUrl, title, subtitle }) => {
  return (
    <div className='bg-mirage-50 shadow-[0_2px_1px_0_#EBEBF3] rounded-2xl text-mirage-950 h-full overflow-hidden'>
      <div className='h-48 bg-linear-to-r from-[#57C3FF] to-[#8C52FF]'>
        <If condition={imageUrl}>
          <Image src={imageUrl} className='w-full object-cover h-48' />
        </If>
      </div>
      <div className='p-6'>
        <div className='text-lg mb-3 line-clamp-1'>
          {title}
        </div>
        <div className='opacity-70 line-clamp-3'>
          {subtitle}
        </div>
      </div>
    </div>
  )
}

export default Card
