import { twMerge } from 'tailwind-merge'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { Choose, When } from 'babel-plugin-jsx-control-statements'

import { useBreakpoint } from '@contexts/breakpoints'
import Image from '@components/Image'
import PDFDisplay from '../DisplayAttachment/PdfDisplay'

const RemoveButton = ({ remove, isMobile }) => {
  if (!remove) return null;
  
  return (
    <button
      type='button'
      aria-label='Remove attachment'
      onClick={remove}
      className={twMerge(
        isMobile ? 'block' : 'hidden group-hover:block group-focus:block',
        'absolute top-[-10px] right-[-10px] p-1 bg-red-500 rounded-full'
      )}
    >
      <XMarkIcon className='h-4 w-4 text-white' />
    </button>
  );
};

const Attachment = ({ id, url, filename, remove }) => {
  const isMobile = useBreakpoint('md')
  const isPDF = filename?.toLowerCase().endsWith('.pdf')

  return (
    <div 
      tabIndex='0' 
      className={twMerge(
        'group relative rounded-lg',
        isPDF && 'w-full flex justify-start' // Force line break but keep content left-aligned
      )}
    >
      <Choose>
        <When condition={isPDF}>
          <div className="inline-block relative">
            <PDFDisplay url={url} filename={filename} />
            <RemoveButton remove={remove} isMobile={isMobile} />
          </div>
        </When>
        <When condition={!isPDF}>
          <Image
            placeholder={<div className='w-[150px] h-[100px] bg-gray-200 animate-pulse rounded-lg' />}
            key={id}
            className='object-cover w-[150px] h-[100px] rounded-lg'
            src={url}
            alt={filename}
          />
          
          <RemoveButton remove={remove} isMobile={isMobile} />
        </When>
      </Choose>
    </div>
  )
}

export default Attachment
