import { useState, useEffect } from 'react'
import { gql } from 'graphql-request'
import { CloudArrowDownIcon, EyeIcon, EyeSlashIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import { formatDuration } from '@helpers/format'
import Button from '@components/Button'
import Card from '@components/Card'
import Spinner from '@components/Spinner'
import { BarChart, ScatterChart } from '@components/Charts'
import Table from '@components/Table'

import ActiveSessionNoInsights from './ActiveSessionNoInsights'
import ClosedSessionNoInsights from './ClosedSessionNoInsights'
import CalculatingInsights from './CalculatingInsights'

import { capitalize, orderBy, round } from 'lodash'

const CLASSROOM_INSIGHTS_QUERY = gql`
  query classroomInsights($classroomId: ID!, $insightableId: ID!) {
    classroomInsights(classroomId: $classroomId, insightableId: $insightableId) {
      profile {
        firstName
        lastName
      }
      totalTimeSeconds
      activeTimeSeconds
      questionsAsked
      questionsAnswered
      answerQuality
      group
      misconceptionsConcepts
      misconceptionsDetails
      misconceptionsSyllabusConnection
      misconceptionsRootCauses
      misconceptionsWorthReteaching
    }
  }
`

const Insights = ({ assignmentId, assignmentClosed, assignmentClosedAt, classroomId }) => {
  const [isCalculating, setIsCalculating] = useState(false)
  const [expandedItems, setExpandedItems] = useState(new Set())
  const [showAllMisconceptions, setShowAllMisconceptions] = useState(false)
  const [showAllRootCauses, setShowAllRootCauses] = useState(false)
  const [showAllTopics, setShowAllTopics] = useState(false)

  const handleBulkExpand = (allData) => {
    if (expandedItems.size === allData.length) {
      setExpandedItems(new Set())
    } else {
      setExpandedItems(new Set(allData.map((_, index) => index)))
    }
  }

  const handleItemExpand = (index) => {
    const newExpandedItems = new Set(expandedItems)
    if (newExpandedItems.has(index)) {
      newExpandedItems.delete(index)
    } else {
      newExpandedItems.add(index)
    }
    setExpandedItems(newExpandedItems)
  }

  const { isLoading, refetch, data: { classroomInsights: rawData = [] } = {} } = useQuery({
    queryKey: ['classroomInsights', classroomId, assignmentId],
    gqlQuery: CLASSROOM_INSIGHTS_QUERY,
    variables: { classroomId, insightableId: assignmentId },
    enabled: !!classroomId && !!assignmentId
  })

  useEffect(() => {
    // If calculating, refetch the data every 30 seconds for 5 minutes.
    // If data is available, the user will see the insights.
    // Otherwise, the user will see the "No insights available" message after 5 minutes.
    const recentlyClosed = () => assignmentClosed && assignmentClosedAt && new Date(assignmentClosedAt) > new Date(Date.now() - 5 * 60 * 1000)

    if (!recentlyClosed()) {
      setIsCalculating(false)
      return
    }

    setIsCalculating(true)

    const interval = setInterval(() => {
      if (recentlyClosed()) {
        refetch()
      } else {
        refetch()
        setIsCalculating(false)
        clearInterval(interval)
      }
    }, 30000)
  }, [assignmentClosed, assignmentClosedAt, setIsCalculating, refetch])

  const scatterData = rawData.reduce((acc, student) => {
    const key = student.answerQuality
    const value = {
      y: student.questionsAsked,
      x: round(student.activeTimeSeconds / 60, 2),
      tooltipData: {
        Name: `${student.profile.firstName} ${student.profile.lastName}`,
        Questions: student.questionsAsked,
        Quality: capitalize(student.answerQuality),
        Time: formatDuration(student.activeTimeSeconds)
      }
    }

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(value)
    return acc
  }, {})

  const flattenData = (data) => {
    // First create the flattened data
    const flattenedData = data.map(item => {
      // Base student data
      const baseData = {
        'Student Name': `${item.profile?.lastName || ''}, ${item.profile?.firstName || ''}`,
        'Total Time (mins)': round(item.totalTimeSeconds / 60, 2) || 0,
        'Active Time (mins)': round(item.activeTimeSeconds / 60, 2) || 0,
        'Questions Asked': item.questionsAsked || 0,
        'Questions Answered': item.questionsAnswered || 0,
        'Answer Quality': capitalize(item.answerQuality || ''),
        Group: item.group || ''
      }

      // Add misconception data
      if (item.misconceptionsConcepts?.length) {
        item.misconceptionsConcepts.forEach((concept, index) => {
          baseData[`Misconception ${index + 1}`] = concept || ''
          baseData[`Misconception ${index + 1} Detail`] = item.misconceptionsDetails?.[index] || ''
          baseData[`Misconception ${index + 1} Root Cause`] = item.misconceptionsRootCauses?.[index] || ''
        })
      }

      return baseData
    })

    // Sort the data by surname, then firstname
    return flattenedData.sort((a, b) => {
      const [aLastName = '', aFirstName = ''] = a['Student Name'].split(', ')
      const [bLastName = '', bFirstName = ''] = b['Student Name'].split(', ')

      // Compare surnames first
      const surnameComparison = aLastName.localeCompare(bLastName)

      // If surnames are equal, compare firstnames
      if (surnameComparison === 0) {
        return aFirstName.localeCompare(bFirstName)
      }

      return surnameComparison
    })
  }

  const calculateAverage = (data, key) => {
    const total = data.reduce((acc, student) => acc + (student[key] || 0), 0)
    return total / data.length
  }

  const classAverages = {
    title: 'Class Averages',
    data: [
      { name: 'Total Time', value: formatDuration(calculateAverage(rawData, 'totalTimeSeconds')) },
      { name: 'Active Engagement', value: formatDuration(calculateAverage(rawData, 'activeTimeSeconds')) },
      { name: 'Questions Asked', value: calculateAverage(rawData, 'questionsAsked').toFixed(0) },
      { name: 'Questions Answered', value: calculateAverage(rawData, 'questionsAnswered').toFixed(0) }
    ]
  }

  const rootCauses = {
    title: 'Root Causes',
    type: 'table',
    data: rawData.reduce((acc, student) => {
      if (!student.misconceptionsConcepts || !student.misconceptionsRootCauses) return acc

      student.misconceptionsConcepts.forEach((misconception, index) => {
        const rootCauses = student.misconceptionsRootCauses[index]?.split(',').map(cause => cause.trim()) || []

        const existingMisconception = acc.find(item => item.misconception === misconception)
        if (existingMisconception) {
          rootCauses.forEach(cause => {
            if (!existingMisconception.rootCauses.includes(cause)) {
              existingMisconception.rootCauses.push(cause)
            }
          })
        } else {
          acc.push({
            misconception,
            rootCauses: [...new Set(rootCauses)]
          })
        }
      })
      return acc
    }, [])
  }

  const worthReteaching = {
    title: 'Topics Worth Reteaching',
    type: 'table',
    data: orderBy(
      rawData.reduce((acc, student) => {
        if (!student.misconceptionsConcepts || !student.misconceptionsWorthReteaching) return acc

        student.misconceptionsConcepts.forEach((misconception, index) => {
          if (student.misconceptionsWorthReteaching[index] === 'Yes') {
            const existingTopic = acc.find(item => item.Topic === misconception)
            if (existingTopic) {
              existingTopic.frequency += 1
            } else {
              acc.push({
                Topic: misconception,
                frequency: 1
              })
            }
          }
        })
        return acc
      }, []),
      ['frequency'],
      ['desc']
    )
  }

  const activeEngagement = {
    title: 'Active Engagement Time',
    type: 'barChart',
    options: {
      labels: {
        x: 'Student',
        y: 'Active Time (mins)'
      }
    },
    data: orderBy(
      rawData.map((student) => ({
        value: round(student.activeTimeSeconds / 60, 2),
        tooltipData: { Name: `${student.profile.firstName} ${student.profile.lastName}`, Time: formatDuration(student.activeTimeSeconds) }
      })),
      ['name'],
      ['asc']
    )
  }

  const studentEngagement = {
    title: 'Student Engagement',
    type: 'scatterChart',
    options: {
      labels: {
        x: 'Active Time',
        y: 'Questions Asked'
      },
      xAxis: {
        unit: 'min'
      },
      fill: {
        null: '#64748b',
        poor: '#ef4444',
        satisfactory: '#eab308',
        good: '#22c55e'
      }
    },
    data: scatterData
  }

  const misconceptionsExamples = {
    title: 'Common Misconceptions',
    type: 'expandableTable',
    data: orderBy(
      rawData.reduce((acc, student) => {
        if (!student.misconceptionsConcepts) return acc

        student.misconceptionsConcepts.forEach((misconception, index) => {
          const existingMisconception = acc.find(item => item.name === misconception)
          if (existingMisconception) {
            existingMisconception.frequency += 1
            if (student.misconceptionsDetails[index]) {
              existingMisconception.details.push(student.misconceptionsDetails[index])
            }
          } else {
            acc.push({
              name: misconception,
              frequency: 1,
              details: student.misconceptionsDetails[index] ? [student.misconceptionsDetails[index]] : []
            })
          }
        })
        return acc
      }, []),
      ['frequency'],
      ['desc']
    )
  }

  const handleDataExport = () => {
    try {
      const data = flattenData(rawData)
      // Create CSV content manually with forced comma delimiters
      const headers = Object.keys(data[0])
      const csvContent = [
        headers.join(','),
        ...data.map(row =>
          headers.map(header =>
            // Wrap values in quotes and escape any existing quotes
            `"${String(row[header] || '').replace(/"/g, '""')}"`
          ).join(',')
        )
      ].join('\n')

      // Create and trigger download
      const blob = new Blob([csvContent], { type: 'text/csvcharset=utf-8' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'mindjoy-export.csv'
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (error) {
      console.error('CSV Export error:', error)
    }
  }

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-32' />
        </When>

        <When condition={rawData.length === 0}>
          <Choose>
            <When condition={isCalculating}>
              <CalculatingInsights />
            </When>

            <When condition={assignmentClosed}>
              <ClosedSessionNoInsights />
            </When>

            <Otherwise>
              <ActiveSessionNoInsights />
            </Otherwise>
          </Choose>
        </When>

        <Otherwise>
          <If condition={assignmentClosed && rawData?.length > 0}>
            <div className='flex justify-end items-center pt-3'>
              <Button
                onClick={() => handleDataExport()}
                theme='secondary'
                variant='outlined'
                className='flex items-center'
                label={<span className='flex gap-2 items-center'>CSV<CloudArrowDownIcon className='h-6 w-6' /></span>}
              />
            </div>
          </If>

          <div className='my-6 space-y-10'>
            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{classAverages.title}</h3>
              <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
                {classAverages.data.map((item) => (
                  <div key={item.name} className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow-sm sm:p-6'>
                    <dt className='truncate text-sm font-medium text-gray-500'>{item.name}</dt>
                    <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{item.value}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{misconceptionsExamples.title}</h3>
              <div className='mt-5'>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Header>Topic</Table.Header>
                      <Table.Header>Frequency</Table.Header>
                      <Table.Header>
                        <button
                          onClick={() => handleBulkExpand(misconceptionsExamples.data)}
                          className='text-gray-500 w-8 h-8 flex items-center justify-center focus:outline-hidden'
                        >
                          {expandedItems.size === misconceptionsExamples.data.length
                            ? (
                              <EyeSlashIcon className='h-5 w-5' />
                            )
                            : (
                              <EyeIcon className='h-5 w-5' />
                            )}
                        </button>
                      </Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    <If condition={misconceptionsExamples.data.length < 1}>
                      <Table.Row>
                        <Table.Cell colSpan='3'>No misconceptions found 😀</Table.Cell>
                      </Table.Row>
                    </If>

                    <For each='item' index='index' of={showAllMisconceptions ? misconceptionsExamples.data : misconceptionsExamples.data.slice(0, 3)}>
                      <>
                        <Table.Row className='bg-white hover:bg-gray-50'>
                          <Table.Cell className='font-medium text-gray-900'>
                            {item.name}
                          </Table.Cell>
                          <Table.Cell>{item.frequency}</Table.Cell>
                          <Table.Cell className='text-right'>
                            <button
                              onClick={() => handleItemExpand(index)}
                              className='text-gray-500 w-8 h-8 flex items-center justify-center focus:outline-hidden'
                            >
                              {expandedItems.has(index)
                                ? (
                                  <ChevronUpIcon className='h-5 w-5' />
                                )
                                : (
                                  <ChevronDownIcon className='h-5 w-5' />
                                )}
                            </button>
                          </Table.Cell>
                        </Table.Row>
                        <If condition={expandedItems.has(index) && item.details.length > 0}>
                          <Table.Row>
                            <Table.Cell colSpan='3' className='bg-white p-0'>
                              <div className='w-full divide-y divide-gray-200'>
                                <For each='detail' index='idx' of={item.details}>
                                  <div key={idx} className='px-8 py-3 first:pt-3 last:pb-3'>
                                    <p className='text-gray-600 whitespace-normal'>
                                      {detail.replace(/(T|t)he student/g, (match) =>
                                        match.charAt(0) === 'T' ? 'Student' : 'student'
                                      )}
                                    </p>
                                  </div>
                                </For>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        </If>
                      </>
                    </For>
                  </Table.Body>
                </Table>

                {misconceptionsExamples.data.length > 3 && (
                  <div className='mt-4 text-center'>
                    <button
                      onClick={() => setShowAllMisconceptions(!showAllMisconceptions)}
                      className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    >
                      {showAllMisconceptions ? 'Show Less' : `Show ${misconceptionsExamples.data.length - 3} More`}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{rootCauses.title}</h3>
              <div className='mt-5'>
                <Table>
                  <Table.Body>
                    <For each='group' of={showAllRootCauses ? rootCauses.data : rootCauses.data.slice(0, 3)}>
                      <Table.Row className='bg-gray-50'>
                        <Table.Cell colSpan='2' className='font-medium'>
                          {group.misconception}
                        </Table.Cell>
                      </Table.Row>
                      <For each='cause' of={[...new Set(group.rootCauses)]}>
                        <Table.Row>
                          <Table.Cell className='pl-8'>{cause}</Table.Cell>
                        </Table.Row>
                      </For>
                    </For>
                  </Table.Body>
                </Table>

                {rootCauses.data.length > 3 && (
                  <div className='mt-4 text-center'>
                    <button
                      onClick={() => setShowAllRootCauses(!showAllRootCauses)}
                      className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    >
                      {showAllRootCauses ? 'Show Less' : `Show ${rootCauses.data.length - 3} More`}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{worthReteaching.title}</h3>
              <div className='mt-5'>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Header>Topic</Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    <If condition={worthReteaching.data.length < 1}>
                      <Table.Row>
                        <Table.Cell>No topics found 😀</Table.Cell>
                      </Table.Row>
                    </If>

                    <For each='row' of={showAllTopics ? worthReteaching.data : worthReteaching.data.slice(0, 3)}>
                      <Table.Row key={row.Topic}>
                        <Table.Cell>{row.Topic}</Table.Cell>
                      </Table.Row>
                    </For>
                  </Table.Body>
                </Table>

                {worthReteaching.data.length > 3 && (
                  <div className='mt-4 text-center'>
                    <button
                      onClick={() => setShowAllTopics(!showAllTopics)}
                      className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    >
                      {showAllTopics ? 'Show Less' : `Show ${worthReteaching.data.length - 3} More`}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{activeEngagement.title}</h3>

              <Card className='grid space-y-3 items-center p-6'>
                <BarChart
                  data={activeEngagement.data}
                  options={activeEngagement.options}
                />
              </Card>
            </div>

            <div className='grid space-y-3'>
              <h3 className='text-lg font-semibold text-gray-900'>{studentEngagement.title}</h3>

              <Card className='grid space-y-3 items-center p-6'>
                <ScatterChart
                  data={studentEngagement.data}
                  options={studentEngagement.options}
                />
              </Card>
            </div>
          </div>
        </Otherwise>
      </Choose>
    </>
  )
}

export default Insights
