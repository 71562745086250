import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { gql } from 'graphql-request'
import Modal from '@components/Modal'
import Button from '@components/Button'
import TextInput from "@components/TextInput"
import { useMutation } from '@tanstack/react-query'
import { request } from '@helpers/graphql'
import { useFlashMessage } from '@components/FlashMessage'

const EXTEND_EDUCATOR_TRIALS = gql`
  mutation AdminExtendEducatorTrials($adminExtendEducatorTrialsInput: AdminExtendEducatorTrialsInput!) {
    adminExtendEducatorTrials(input: $adminExtendEducatorTrialsInput) {
      success
      errors
    }
  }
`

const ExtendTrialsModal = ({ isOpen, closeModal, organizationId }) => {
    const { setFlashMessage } = useFlashMessage()
    const { register, handleSubmit, setError, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            daysToExtend: 30
        }
    })

    const { mutate: extendTrials } = useMutation({
        mutationFn: (variables) => request(EXTEND_EDUCATOR_TRIALS, variables),
        onSuccess: (response) => {
            if (response?.adminExtendEducatorTrials?.success) {
                setFlashMessage('Trials successfully extended', 'success')
                closeModal()
            } else {
                const errorMessage = response?.adminExtendEducatorTrials?.errors?.[0] || 'Failed to extend trials'
                setError('general', { message: errorMessage })
            }
        },
        onError: (error) => {
            console.error('Error extending trials:', error)
            setError('general', { message: error.message || 'An unexpected error occurred' })
        }
    })

    const onSubmit = (data) => {
        extendTrials({
            adminExtendEducatorTrialsInput: {
                organizationId,
                daysToExtend: parseInt(data.daysToExtend)
            }
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <div className="">
                <h2 className="text-xl font-bold mb-2">Extend Educator Trials</h2>
                <p className="text-gray-600 mb-6">
                    Please note this is going to extend all the educator trials and sync them to the same schedule.
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <TextInput
                            type="number"
                            min={1}
                            max={365}
                            {...register('daysToExtend', {
                                required: 'Number of days is required',
                                min: { value: 1, message: 'Minimum 1 day' },
                                max: { value: 365, message: 'Maximum 365 days' }
                            })}
                            label="Number of days to extend"
                            className="w-full"
                            error="false"
                        />
                        <If condition={errors.daysToExtend}>
                            <p className="mt-1 text-sm font-semibold text-red-500" role="alert">
                                {errors.daysToExtend.message}
                            </p>
                        </If>
                    </div>

                    <If condition={errors.general}>
                        <p className="text-sm mb-4 text-red-500 font-semibold" role="alert">
                            {errors.general.message}
                        </p>
                    </If>

                    <div className="flex justify-end gap-3">
                        <Button
                            className='w-fit ml-auto mt-5'
                            type='submit'
                            label={isSubmitting ? 'Extneding...' : 'Extend trials'}
                            disabled={isSubmitting}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ExtendTrialsModal 