import ProfileCard from "./ProfileCard"

const ProfileInterstitial = ({
  nextStep,
  previousStep,
  title,
  content,
  buttonText,
  footer,
  isBusy=false,
  learningTitle,
  firstName,
  pictureStyle,
  learningGoal,
  learningFocus,
  learningPreference,
  learningMotivation,
  learningValues
}) => {
  return (
    <div className='flex flex-col md:flex-row gap-4 md:gap-32 md:items-center p-4 pt-24 md:pt-0'>

      <div className='w-sm'>
        <If condition={title}>
          <h1 className='text-mirage-950 text-4xl font-semibold my-3'>
            {title}
          </h1>
        </If>

        <If condition={content}>
          <div className='text-mirage-900 text-lg font-light mt-3 mb-6'>
            {content}
          </div>
        </If>

        <div className='flex gap-5 items-center'>
          <If condition={previousStep}>
            <button onClick={previousStep} tabIndex={1} className='bg-mirage-50 text-mirage-950 text-md rounded-lg py-3 px-5'>
              Back
            </button>
          </If>
          <button onClick={nextStep} disabled={isBusy} tabIndex={0} className='bg-mirage-950 text-white text-md rounded-lg py-3 px-5 shadow-[0_2.5px_32px_0_rgba(0,0,64,0.1)] disabled:opacity-20'>
            {buttonText || 'Continue'}
          </button>
          <If condition={footer}>
            {footer}
          </If>
        </div>
      </div>

      <ProfileCard
        firstName={firstName}
        pictureStyle={pictureStyle}
        learningTitle={learningTitle}
        learningGoal={learningGoal}
        learningFocus={learningFocus}
        learningPreference={learningPreference}
        learningMotivation={learningMotivation}
        learningValues={learningValues}
      />
    </div>
  )
}

export default ProfileInterstitial
