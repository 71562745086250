import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import {
  BoldIcon,
  ItalicIcon,
  CodeBracketIcon,
  NumberedListIcon,
  ListBulletIcon,
  TableCellsIcon,
  PhotoIcon,
  H1Icon,
  H2Icon,
  H3Icon
} from '@heroicons/react/24/outline'

import Tooltip from '@components/Tooltip'
import CircleSpinner from '@components/CircleSpinner'

import { useToolbar } from './Container'
import keyboardReturnIcon from './keyboard-return.svg'
import keyboardShiftIcon from './keyboard-shift.svg'

const ToolButton = ({ id, label, icon, disabled, className, onClick }) => (
  <>
    <button
      id={id}
      type='button'
      onClick={onClick}
      disabled={disabled}
      className={twMerge('px-2 py-1 rounded-md hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400', className)}
      aria-label={label}
    >
      {icon}
    </button>

    <Tooltip anchorSelect={`#${id}`} delayShow={500}>
      {label}
    </Tooltip>
  </>
)

const ImageUploadButton = ({ id, disabled, editor, uploadImage }) => {
  const fileInputRef = useRef()

  const handleImageUpload = async event => {
    const file = event.target.files[0]

    // On success, the image URL is returned and set in the editor.
    const imageUrl = await uploadImage(file)

    if (imageUrl) {
      editor.chain().focus().setImage({ src: imageUrl }).run()
    }
  }

  return (
    <>
      <button
        id={id}
        type='button'
        onClick={() => fileInputRef.current.click()}
        disabled={disabled}
        className='px-2 py-1 rounded-md hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400'
        aria-label='Image'
      >
        <PhotoIcon className='size-4' />
      </button>

      <Tooltip anchorSelect={`#${id}`} delayShow={500}>
        Image
      </Tooltip>

      <input
        ref={fileInputRef}
        id='image-upload-button'
        type='file'
        // Accepts image files, but also allows plain text.
        // This allows Android 14 devices to select from the file picker and use the camera.
        // Remove text/plain as an option once this Android 14 bug is fixed: https://issuetracker.google.com/issues/317289301
        accept='.jpg,.jpeg,.png,.heic,.heif,.webp,text/plain'
        className='hidden'
        disabled={disabled}
        onChange={handleImageUpload}
      />
    </>
  )
}

const Divider = () => <div className='border-l border-gray-300 h-6 m-1' />

const LatexText = ({ math }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      katex.render(math, containerRef.current, {
        throwOnError: false
      })
    }
  }, [math])

  return <span ref={containerRef} />
}

const ToolBar = ({ editor, uploadImage, newlineHintEnabled, isUploading }) => {
  const { isToolbarEnabled } = useToolbar()

  if (!editor) {
    return null
  }

  const isTableFocused = editor.isActive('table')

  return (
    <div className={twMerge('bg-gray-100 border-gray-300 rounded-t-md absolute left-0 right-0 top-0 z-10', isToolbarEnabled ? 'flex' : 'hidden')}>
      <div className='w-full flex flex-wrap p-1'>
        <ToolButton
          id='heading-1-button'
          label='Heading 1'
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H1Icon className='size-4' />}
        />

        <ToolButton
          id='heading-2-button'
          label='Heading 2'
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H2Icon className='size-4' />}
        />

        <ToolButton
          id='heading-3-button'
          label='Heading 3'
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run() || isTableFocused}
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<H3Icon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='bold-button'
          label='Bold'
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'bold' : ''}
          icon={<BoldIcon className='size-4' />}
        />

        <ToolButton
          id='italic-button'
          label='Italic'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'bold' : ''}
          icon={<ItalicIcon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='unordered-list-button'
          label='Bullet List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'bold' : ''}
          icon={<ListBulletIcon className='size-4' />}
        />

        <ToolButton
          id='numbered-list-button'
          label='Numbered List'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'bold' : ''}
          icon={<NumberedListIcon className='size-4' />}
        />

        <Divider />

        <ToolButton
          id='code-button'
          label='Code'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<CodeBracketIcon className='size-4' />}
        />

        <ToolButton
          id='table-button'
          label='Table'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          className={editor.isActive('codeBlock') ? 'bold' : ''}
          icon={<TableCellsIcon className='size-4' />}
        />

        <If condition={!!uploadImage}>
          <ImageUploadButton
            id="image-upload-button"
            editor={editor}
            disabled={isTableFocused || isUploading}
            uploadImage={uploadImage}
          />
        </If>

        <ToolButton
          id='math-button'
          label='Math'
          disabled={isTableFocused}
          onClick={() => editor.chain().focus().toggleMath(String.raw`\placeholder{}`).run()} // eslint-disable-line
          className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'bold' : ''}
          icon={<LatexText math='x^2' />}
        />

        <If condition={newlineHintEnabled}>
          <p className='flex items-center ml-auto text-xs pr-2 text-gray-500'>
            Use&nbsp;
            <img className='size-4 border border-gray-500 rounded-sm' alt='Shift' src={keyboardShiftIcon} />
            &nbsp;+&nbsp;
            <img className='size-4 border border-gray-500 rounded-sm' alt='Return' src={keyboardReturnIcon} />
            &nbsp;for new line
          </p>
        </If>

        <If condition={isUploading}>
          <CircleSpinner className='ml-auto py-1 pr-2' />
        </If>
      </div>
    </div>
  )
}

export default ToolBar
