import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import {
  ArrowLeftEndOnRectangleIcon,
  BuildingOffice2Icon,
  WrenchScrewdriverIcon,
  ChatBubbleLeftEllipsisIcon,
  ShieldCheckIcon
} from '@heroicons/react/24/outline'

import { client } from '@helpers/api'
import { useCurrentUser } from '@contexts/currentUser'

import NavBar from './Navbar'

const apiUrl = import.meta.env.VITE_API_URL

const AdminNav = ({ children }) => {
  const navigate = useNavigate()
  const { clearUser } = useCurrentUser()
  const queryClient = useQueryClient()
  const { mutate: signOut } = useMutation(['signOut'], async () => client.delete('/users/sign_out'),
    {
      onSuccess: () => {
        clearUser()
        queryClient.clear()
        navigate('/sign_in')
      }
    }
  )

  const navigation = [
    { name: 'Organizations', href: '/admin/organizations', icon: BuildingOffice2Icon },
    { name: 'Tutors', href: '/admin/tutors', icon: ChatBubbleLeftEllipsisIcon },
    { name: 'Security settings', href: '/admin/security', icon: ShieldCheckIcon },
    { name: 'Admin tools', href: `${apiUrl}/admin`, icon: WrenchScrewdriverIcon, external: true }
  ]

  const settings = [
    { name: 'Sign out', icon: ArrowLeftEndOnRectangleIcon, onClick: signOut }
  ]

  return (
    <NavBar
      navigation={navigation}
      settings={settings}
    >
      {children}
    </NavBar>
  )
}

export default AdminNav
