import { createContext, useState, useContext } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useLocalStorage } from '@hooks/localStorage'
import { client } from '@helpers/api'

const UserContext = createContext()

const CurrentUserProvider = ({ children }) => {
  const [user, setUser] = useState({ loading: true, signedIn: false })
  const [selectedOrganizationId, setSelectedOrganizationId] = useLocalStorage('selectedOrganizationId', null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const setUserForSession = data => {
    setUser({ ...data, loading: false, signedIn: true })

    const memberships = data?.memberships
    const invalidOrganizationId = selectedOrganizationId && !memberships?.some(membership => membership?.organization?.id === selectedOrganizationId)

    // If selectedOrganizationId is present but invalid, then rather use the first valid organization ID.
    // If not present, then use the first membership's organization ID.
    if (memberships?.length > 0 && (!selectedOrganizationId || invalidOrganizationId)) {
      setSelectedOrganizationId(memberships[0].organization.id)
    }
  }

  const clearUser = () => {
    setUser({ loading: false, signedIn: false })
    setSelectedOrganizationId(null)
  }

  const { refetch } = useQuery({
    queryKey: ['currentUser'],
    queryFn: () => client.get('/me'),
    onSuccess: data => {
      setUserForSession({ ...data.data, loading: false, signedIn: true })
    },
    onError: data => {
      if (data.response?.status === 401) {
        setUser({ loading: false, signedIn: false })
      }
    },
  })

  const currentMembership = user?.memberships?.find(membership => membership.organization.id === selectedOrganizationId)
  const switchOrganization = organizationId => {
    setSelectedOrganizationId(organizationId)

    navigate('/')
    queryClient.clear()
  }

  return (
    <UserContext.Provider value={{ user, setUser: setUserForSession, clearUser, currentMembership, selectedOrganizationId, switchOrganization, refetchUser: refetch }}>
      {children}
    </UserContext.Provider>
  )
}

const useCurrentUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }

  return context
}

export {
  CurrentUserProvider,
  useCurrentUser
}
