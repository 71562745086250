import { Routes, Route, Navigate } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'
import EducatorProjectSubmission from '@pages/EducatorProjectSubmission'

import Home from './pages/Home'
import CoursesList from './pages/CoursesList'
import CourseDetails from './pages/CourseDetails'
import LessonInsights from './pages/LessonInsights'
import TutorInsights from './pages/TutorInsights/TutorInsights'
import TutorAssignmentList from './pages/TutorAssignmentList'
import TutorAssignmentDetails from './pages/TutorAssignmentDetails'
import Settings from './pages/Settings'
import Archived from './pages/Archived'
import Onboarding from './pages/Onboarding'

const Student = () => {
  const { user, currentMembership } = useCurrentUser()

  if (currentMembership?.role !== 'student') {
    return <Navigate to='/' />
  }

  // If onboarding is not completed and user is not currently onboarding
  if (!user?.student_onboarding_completed && location.pathname !== '/students/onboarding') {
    return <Navigate to='/students/onboarding' />
  }

  return (
    <Routes>
      <Route element={<Archived />}>
        <Route index element={<Home />} />
        <Route path='/projects' element={<Navigate to='/students/courses' />} />
        <Route path='/courses/:id' element={<CourseDetails />} />
        <Route path='/courses' element={<CoursesList />} />
        <Route path='/course_insights/:id' element={<CourseDetails pageHeading='Pathway Insights' projectPath='project_insights' />} />
        <Route path='/course_insights' element={<CoursesList pageHeading='Pathway Insights' />} />
        <Route path='/courses/:id/lessons/:id/insights' element={<LessonInsights />} />
        <Route path='/courses/:id/lessons/:id/*' element={<EducatorProjectSubmission />} />
        <Route path='/tutors' element={<TutorAssignmentList pageHeading='Tutors' />} />
        <Route path='/tutors/assignments/:id' element={<TutorAssignmentDetails />} />
        <Route path='/tutor_insights' element={<TutorAssignmentList pageHeading='Insights' closed />} />
        <Route path='/tutor_insights/assignments/:id' element={<TutorInsights />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/onboarding' element={<Onboarding />} />
      </Route>
    </Routes>
  )
}

export default Student
