import { useState, useRef, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { FormProvider, useForm } from 'react-hook-form'
import { LightBulbIcon } from '@heroicons/react/24/outline'

import { useCurrentUser } from '@contexts/currentUser'
import BreadCrumbs from '@components/Breadcrumbs'
import Tab from '@components/Tab'
import { useBreakpoint } from '@contexts/breakpoints'
import { useOnboardingChecklist } from '../../students/hooks/onboardingChecklist'
import { formatShortDuration, formatDuration } from '@helpers/format'

import { useEducatorProjectSubmission } from './hooks'
import ChatPlayground from './ChatPlayground'
import Skeleton from './Skeleton'
import SubmitModal from './SubmitModal'
import ProjectBlocks from './ProjectBlocks'
import Button from '@components/Button'

const EducatorProjectSubmission = () => {
  const contentPanelRef = useRef(null)
  const isMobile = useBreakpoint('sm')
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const [timeRemainingSeconds, setTimeRemainingSeconds] = useState(0)
  const { id: educatorProjectId } = useParams()
  const { project, submission, isLoading } = useEducatorProjectSubmission({ educatorProjectId })
  const { currentMembership: { organization: { features } } } = useCurrentUser()
  const isCourseInsightsEnabled = features.includes('course_insights')

  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })

  // Calculate remaining time based on dueDate and server's currentTime
  useEffect(() => {
    if (!project?.series?.assignment?.dueDate || !project?.series?.assignment?.currentTime) return

    const serverTime = new Date(project.series.assignment.currentTime)
    const dueDate = new Date(project.series.assignment.dueDate)
    const initialTimeRemaining = Math.floor((dueDate - serverTime) / 1000)

    // Initialize the time remaining from server time
    setTimeRemainingSeconds(Math.max(0, initialTimeRemaining))

    // If already past due date according to server time, just return
    if (initialTimeRemaining <= 0) {
      return
    }

    const startTime = Date.now()

    // Update time every second
    const timer = setInterval(() => {
      // Calculate elapsed time since component mounted
      const elapsedSinceMount = Math.floor((Date.now() - startTime) / 1000)

      // Calculate remaining time by subtracting elapsed time from initial remaining time
      const remaining = initialTimeRemaining - elapsedSinceMount
      setTimeRemainingSeconds(Math.max(0, remaining))

      // Clear interval if time is up
      if (remaining <= 0) {
        clearInterval(timer)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [project?.series?.assignment?.dueDate, project?.series?.assignment?.currentTime])

  const currentBlock = project?.blocks[currentBlockIndex]
  const currentBlockSubmission = submission?.blockSubmissions?.find(blockSubmission => blockSubmission.blockId === currentBlock?.blockId)
  const projectName = project?.name || 'Untitled lesson'
  const seriesName = project?.series?.name || 'Untitled pathway'
  const assignment = project?.series?.assignment
  const isAllBlocksCompleted = submission?.blockSubmissions?.every(submission => submission.status === 'COMPLETED') && submission?.blockSubmissions?.length === project?.blocks?.length

  const { markOnboardingItemCompleted } = useOnboardingChecklist()
  useEffect(() => markOnboardingItemCompleted('startedSeries'))

  if (isLoading || !project || !submission) return <Skeleton />

  const moveToNextBlock = () => {
    const nextIndex = currentBlockIndex + 1
    setCurrentBlockIndex(nextIndex)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const moveToPreviousBlock = () => {
    setCurrentBlockIndex(currentBlockIndex - 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <BreadCrumbs className='overflow-x-auto flex items-center w-full'>
        <BreadCrumbs.Link label='Pathways' to='/students/courses' />
        <BreadCrumbs.Link label={seriesName} to={`/students/courses/${project.series.id}`} />
        <BreadCrumbs.Text label={projectName} />

        <If condition={!(submission.status === 'COMPLETED' || submission.status === 'RETURNED') && project?.series?.assignment?.dueDate}>
          <div className='mx-auto text-center py-1 px-2 bg-blue-50 text-blue-600 rounded-md'>

            <Choose>
              <When condition={timeRemainingSeconds <= 0}>
                Time's up, submit your work
              </When>

              <When condition={timeRemainingSeconds < 120}>
                <span className='font-semibold'>Time Remaining:</span>&nbsp;{formatDuration(timeRemainingSeconds)}
              </When>

              <Otherwise>
                <span className='font-semibold'>Time Remaining:</span>&nbsp;{formatShortDuration(timeRemainingSeconds)}
              </Otherwise>
            </Choose>
          </div>
        </If>

        <div className='flex items-center gap-3 ml-auto'>
          <If condition={!(submission.status === 'COMPLETED' || submission.status === 'RETURNED')}>
            <If condition={(project.series.assignment.dueDate && timeRemainingSeconds <= 0) || isAllBlocksCompleted}>
              <Button
                id='submit-button'
                onClick={() => setIsSubmitModalOpen(true)}
                label='Submit Lesson'
                className='p-1.5'
              />
            </If>
          </If>
          <If condition={(submission.status === 'COMPLETED' || submission.status === 'RETURNED') && isCourseInsightsEnabled}>
            <Link
              className='flex shrink-0 text-sm text-blue-500 hover:bg-blue-100 rounded-md p-1'
              to={`/students/courses/${project.series.id}/lessons/${project.id}/insights`}
            >
              <LightBulbIcon className='size-5 inline-block mr-1' />
              Insights
            </Link>
          </If>

          <If condition={submission.status === 'RETURNED' && submission?.score && project?.totalPossibleScore}>
            <p className='flex text-sm text-gray-500 shrink-0 bg-gray-100 rounded-md p-1 border border-gray-300'>
              {submission?.score} / {project?.totalPossibleScore}
            </p>
          </If>
        </div>
      </BreadCrumbs>

      <Choose>
        <When condition={isMobile}>
          <Tab.Group className='h-full'>

            <>
              <If condition={project?.chatEnabled}>
                <Tab.List className='sticky top-0'>
                  <Tab>
                    Lesson
                  </Tab>

                  <Tab>
                    Chat
                  </Tab>
                </Tab.List>
              </If>
            </>

            <Tab.Panels className='h-[calc(100%-51px)]'>
              <Tab.Panel className='h-full'>
                <div ref={contentPanelRef} className='py-3 px-5 flex flex-col'>
                  <ProjectBlocks
                    blocks={project.blocks}
                    blockSubmissions={submission.blockSubmissions}
                    submissionId={submission.id}
                    assignmentId={assignment.id}
                    submissionStatus={submission.status}
                    currentBlock={currentBlock}
                    currentBlockSubmission={currentBlockSubmission}
                    currentBlockIndex={currentBlockIndex}
                    moveToNextBlock={moveToNextBlock}
                    moveToPreviousBlock={moveToPreviousBlock}
                    isSubmissionClosed={project?.series?.assignment?.closed}
                    setIsSubmitModalOpen={setIsSubmitModalOpen}
                    isTimeUp={project?.series?.assignment?.dueDate && timeRemainingSeconds <= 0}
                  />
                </div>
              </Tab.Panel>

              <Tab.Panel className='h-full w-full bg-white'>
                <If condition={project?.chatEnabled}>
                  <FormProvider {...methods}>
                    <ChatPlayground
                      submissionId={submission.id}
                      isSubmissionClosed={assignment?.closed}
                      proFeaturesEnabled={assignment?.proFeaturesEnabled}
                      submissionStatus={submission.status}
                    />
                  </FormProvider>
                </If>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </When>

        <Otherwise>
          <PanelGroup className='flex grow bg-white' style={{ overflow: 'auto' }} direction='horizontal' autoSaveId='studentProjectPlayground'>
            <Panel
              style={{ overflow: 'auto' }}
              className='flex flex-col pl-5 pr-3 bg-gray-50'
              defaultSize={50}
              minSize={30}
            >
              <div className='py-3' ref={contentPanelRef}>
                <ProjectBlocks
                  blocks={project.blocks}
                  blockSubmissions={submission.blockSubmissions}
                  submissionId={submission.id}
                  assignmentId={assignment.id}
                  submissionStatus={submission.status}
                  currentBlock={currentBlock}
                  currentBlockSubmission={currentBlockSubmission}
                  currentBlockIndex={currentBlockIndex}
                  moveToNextBlock={moveToNextBlock}
                  moveToPreviousBlock={moveToPreviousBlock}
                  isSubmissionClosed={project?.series?.assignment?.closed}
                  setIsSubmitModalOpen={setIsSubmitModalOpen}
                  isTimeUp={project?.series?.assignment?.dueDate && timeRemainingSeconds <= 0}
                />
              </div>
            </Panel>

            <If condition={project?.chatEnabled}>
              <PanelResizeHandle className='flex items-center justify-center h-full'>
                <div className='bg-gray-50 px-1 h-full' />
                <div className='w-[1px] h-full bg-gray-200' />
                <div className='bg-white px-1 h-full' />
              </PanelResizeHandle>

              <Panel
                className='relative overflow-auto'
                defaultSize={50}
                minSize={30}
              >
                <FormProvider {...methods}>
                  <ChatPlayground
                    submissionId={submission.id}
                    isSubmissionClosed={assignment?.closed}
                    proFeaturesEnabled={assignment?.proFeaturesEnabled}
                    submissionStatus={submission.status}
                  />
                </FormProvider>

              </Panel>
            </If>
          </PanelGroup>
        </Otherwise>
      </Choose>

      <SubmitModal
        seriesId={project?.series?.id}
        educatorProjectId={educatorProjectId}
        closeModal={() => setIsSubmitModalOpen(false)}
        isOpen={isSubmitModalOpen}
      />
    </>
  )
}

export default EducatorProjectSubmission
