import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import { client } from '@helpers/api'
import { useFlashMessage } from '@components/FlashMessage'
import OverflowMenu from '@components/OverflowMenu'

const UPDATE_MEMBERSHIP_ROLE_MUTATION = gql`
  mutation updateMembershipRole($input: UpdateRoleInput!) {
    updateMembershipRole(input: $input) {
      membership {
        role
      }
      errors {
        message
      }
    }
  }
`

const EducatorListActions = ({ id, userId, email, fullName, role, refetch, organizationId }) => {
  const educatorNameOrEmail = fullName.trim() === '' ? email : fullName
  const { setFlashMessage } = useFlashMessage()

  const { mutate: updateEducatorRole } = useMutation({
    mutationFn: async (variables) => request(UPDATE_MEMBERSHIP_ROLE_MUTATION, variables, { 'X-Organization-Id': organizationId }),
    onSuccess: () => {
      refetch()
    }
  })

  const handleRoleChange = (id, newRole) => {
    updateEducatorRole({
      input: {
        profileId: id,
        role: newRole
      }
    })
  }

  return (
    <OverflowMenu orientation='horizontal'>
      <Choose>
        <When condition={role === 'ADMIN'}>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(id, 'EDUCATOR')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an educator
            </button>
          </OverflowMenu.Item>
        </When>

        <Otherwise>
          <OverflowMenu.Item>
            <button
              onClick={() => handleRoleChange(id, 'ADMIN')}
              className='text-md w-full text-left'
            >
              Make {educatorNameOrEmail} an admin
            </button>
          </OverflowMenu.Item>
        </Otherwise>
      </Choose>
    </OverflowMenu>
  )
}

export default EducatorListActions
