import { Link } from 'react-router-dom'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'

import Card from '@components/Card'

const SignUp = () => {
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <Card className='sm:w-[600px] p-5 mt-10'>
        <h1 className='text-2xl font-bold mb-5'>Let's setup your account</h1>

        <div className='flex gap-5 sm:flex-row flex-col'>
          <Link
            to='/join_short_code'
            className='w-full flex flex-col items-center justify-between font-semibold border border-grey-200 bg-white rounded-lg p-5 cursor-pointer shadow-sm hover:bg-gray-100'
          >
            <img src='/searching.svg' className='w-[100px]' />
            <span className='flex mt-5 text-xl'>
              I'm a student
              <ArrowLongRightIcon className='size-7 ml-2' />
            </span>
          </Link>

          <Link
            to='/educator_sign_up'
            className='w-full flex flex-col items-center justify-between font-semibold border border-grey-200 bg-white rounded-lg p-5 cursor-pointer shadow-sm hover:bg-gray-100'
          >
            <img src='/brainstorm-board.svg' className='w-[200px]' />
            <span className='flex mt-5 text-xl'>
              I'm an educator
              <ArrowLongRightIcon className='size-7 ml-2' />
            </span>
          </Link>
        </div>
      </Card>

      <p className='text-center mt-5'>Already have an account? <Link className='text-blue-500' to='/sign_in'>Sign in</Link></p>
    </div>
  )
}

export default SignUp
