import ProfileCardSection from './ProfileCardSection'
import ProfileCardProgressStat from './ProfileCardProgressStat'
import ProfileCardRainbowShadow from './ProfileCardRainbowShadow'

const learningGoalText = {
  academic_excellence: 'do well academically',
  learn_for_fun: 'learn new things for fun',
  grow_career_skills: 'grow my career skills',
  fill_knowledge_gap: 'fill a knowledge gap',
  prepare_for_text: 'prepare for a test or exam'
}

const learningFocusText = {
  learn_specific_skills: 'learning specific skills',
  follow_curiosity: 'following my curiosity',
  build_problem_solving_skills: 'building my problem-solving skills',
  master_basics: 'mastering the basics'
}

const learningPreferencePercents = {
  visual: {
    visual: 80,
    textual: 20,
    auditory: 25
  },
  textual: {
    visual: 25,
    textual: 80,
    auditory: 20
  },
  auditory: {
    visual: 25,
    textual: 20,
    auditory: 80
  },
  mixed: {
    visual: 60,
    textual: 65,
    auditory: 60
  }
}

const ProfileCard = ({ firstName, pictureStyle, learningTitle, learningGoal, learningFocus, learningPreference, learningMotivation, learningValues }) => {
  return (
    <div className='relative overflow-visible'>
      
      <div className='w-full md:w-md md:max-w-md relative z-30'>

        <div className='flex flex-col divide-y-2 divide-mirage-200 divide-dashed text-mirage-950 rounded-2xl shadow-[0_2px_1px_0_#EBEBF3] bg-white'>

          <ProfileCardSection>
            <div className='flex flex-row gap-4 items-center'>
              
              <Choose>
                <When condition={pictureStyle}>
                <div
                  className="w-14 h-14 rounded-full relative"
                  style={{
                    background: `linear-gradient(to bottom right, ${pictureStyle.color1}, ${pictureStyle.color2})`
                  }}
                >
                  <div className="absolute inset-0 flex items-center justify-center text-xl mt-1">
                    {pictureStyle.emoji}
                  </div>
                </div>
                </When>
                <Otherwise>
                  <div className='w-14 h-14 rounded-full bg-mirage-100'></div>
                </Otherwise>
              </Choose>

              <div>
                <div className='text-2xl font-semibold leading-tight'>
                  {firstName}
                </div>
                <Choose>
                  <When condition={learningTitle}>
                    <div className='font-dmMono uppercase text-sm'>
                      {learningTitle}
                    </div>
                  </When>
                  <Otherwise>
                    <div className='w-32 h-4 bg-mirage-100'></div>
                  </Otherwise>
                </Choose>
              </div>
            </div>
          </ProfileCardSection>

          <ProfileCardSection title='Game plan'>
            {"I'm aiming to "}
            <strong className='font-semibold'>{learningGoalText[learningGoal]}</strong>
            {' by '}
            <strong className='font-semibold'>{learningFocusText[learningFocus]}</strong>.
          </ProfileCardSection>

          <ProfileCardSection title='How I like to learn'>
            <ProfileCardProgressStat
              title='Seeing &amp; Drawing'
              percent={learningPreference ? learningPreferencePercents[learningPreference].visual : 0}
              className='bg-blue-500' />
            <ProfileCardProgressStat
              title='Reading &amp; Writing'
              percent={learningPreference ? learningPreferencePercents[learningPreference].textual : 0}
              className='bg-purple-500' />
            <ProfileCardProgressStat
              title='Speaking &amp; Listening'
              percent={learningPreference ? learningPreferencePercents[learningPreference].auditory : 0}
              className='bg-orange-500' />
          </ProfileCardSection>

          <ProfileCardSection title='Learning motivation'>
            <Choose>
              <When condition={learningMotivation}>
                {learningMotivation}
              </When>
              <Otherwise>
                <div className='w-full h-4 bg-mirage-100 mb-1'></div>
                <div className='w-full h-4 bg-mirage-100 mb-1'></div>
                <div className='w-full h-4 bg-mirage-100 mb-1'></div>
                <div className='w-3/4 h-4 bg-mirage-100 mb-1'></div>
              </Otherwise>
            </Choose>
          </ProfileCardSection>

          <ProfileCardSection title='Values'>
            <Choose>
              <When condition={learningValues}>
                <div className='flex flex-row flex-wrap gap-2'>
                  <For index='index' each='value' of={learningValues}>
                    <div key={index} className='bg-mirage-50 p-1 px-3 rounded-sm'>{value}</div>
                  </For>
                </div>
              </When>
              <Otherwise>
                <div className='flex flex-row flex-wrap gap-1'>
                  <div className='w-[83px] rounded-sm h-7 bg-mirage-100'></div>
                  <div className='w-[100px] rounded-sm h-7 bg-mirage-100'></div>
                  <div className='w-[92px] rounded-sm h-7 bg-mirage-100'></div>
                  <div className='w-[93px] rounded-sm h-7 bg-mirage-100'></div>
                  <div className='w-[105px] rounded-sm h-7 bg-mirage-100'></div>
                </div>
              </Otherwise>
            </Choose>
          </ProfileCardSection>

        </div>
      </div>

      <ProfileCardRainbowShadow />
    </div>
  )
}

export default ProfileCard
