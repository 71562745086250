import { Fragment } from 'react'
import { Listbox as HeadlessListbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

const Listbox = ({
  value,
  onChange,
  multiple,
  className,
  name,
  placeholder = 'Select options',
  label = 'Selected options',
  children
}) => (
  <HeadlessListbox
    name={name}
    onChange={onChange}
    value={value}
    multiple={multiple}
    className={twMerge('relative', className)}
  >
    <div className='relative mt-1'>
      <HeadlessListbox.Button className='relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 focus:outline-hidden focus:ring-2 focus:ring-blue-600 sm:text-base sm:leading-6'>
        <span className='truncate flex flex-row'>
          <Choose>
            <When condition={value && multiple}>
              {label}

              <span className='flex justify-center ml-auto items-center p-1 w-6 h-6 text-sm rounded-full text-blue-800 bg-blue-100'>{value.length}</span>
            </When>

            <When condition={value}>
              {label}
            </When>

            <Otherwise>
              {placeholder}
            </Otherwise>
          </Choose>
        </span>
        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
          <ChevronUpDownIcon
            className='h-5 w-5 text-gray-500'
            aria-hidden='true'
          />
        </span>
      </HeadlessListbox.Button>
      <Transition
        as={Fragment}
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <HeadlessListbox.Options className='z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base border border-gray-200 focus:outline-hidden shadow-lg'>
          {children}
        </HeadlessListbox.Options>
      </Transition>
    </div>
  </HeadlessListbox>
)

export default Listbox
