import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { twMerge } from 'tailwind-merge'
import {
  Bars2Icon,
  DocumentPlusIcon,
  PlusIcon
} from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import SecureMarkdown from '@components/SecureMarkdown'
import LinkButton from '@components/LinkButton'
import Card from '@components/Card'
import OverflowMenu from '@components/OverflowMenu'
import CourseCard from '@components/CourseCard'

import AddLessonModal from './AddLessonModal'
import DeleteProjectModal from './DeleteProjectModal'

const UDPATE_PROJECT_MUTATION = gql`
  mutation updateEducatorProject($input: UpdateEducatorProjectInput!) {
    updateEducatorProject(input: $input) {
      educatorProject {
        id
        position
      }
    }
  }
`

const projectTheme = isDragging => {
  if (isDragging) {
    return 'bg-blue-100 shadow-lg'
  }

  return 'bg-white'
}

const ProjectItem = ({ project, index, openDeleteModal }) => (
  <Draggable draggableId={project.id} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className={twMerge('flex flex-row w-full p-3 sm:rounded-lg shadow-sm', projectTheme(snapshot.isDragging))}
      >
        <div
          {...provided.dragHandleProps}
          className='p-2 bg-gray-100 border-gray-200 border rounded-md mr-5'
        >
          <Bars2Icon className='h-4 w-4' />
        </div>

        <Link to={`/educators/projects/${project.id}`} className='w-full text-left'>
          <h3 className='text-lg font-semibold hover:text-blue-500'>{project.name}</h3>
        </Link>

        <OverflowMenu>
          <OverflowMenu.Item>
            <Link className='text-base block' to={`/educators/projects/${project.id}`}>Edit</Link>
          </OverflowMenu.Item>
          <OverflowMenu.Item>
            <button
              onClick={openDeleteModal}
              className='text-base w-full text-left text-red-500'
            >
              Delete
            </button>
          </OverflowMenu.Item>
        </OverflowMenu>
      </div>
    )}
  </Draggable>
)

const Overview = ({
  id,
  name,
  description,
  notes,
  imageUrl,
  educatorProjects,
  classrooms,
  refetch
}) => {
  const [isAddLessonModalOpen, setIsAddLessonModalOpen] = useState(false)
  const [projectIdToDelete, setProjectIdToDelete] = useState(null)
  const [sortedProjects, setSortedProjects] = useState([])

  const { mutate: updateProject } = useMutation({ gqlMutation: UDPATE_PROJECT_MUTATION })

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updatedProjects = Array.from(sortedProjects)
    const [removed] = updatedProjects.splice(result.source.index, 1)
    updatedProjects.splice(result.destination.index, 0, removed)

    setSortedProjects(updatedProjects)
    updateProject({ input: { id: removed.id, position: result.destination.index } })
  }

  useEffect(() => {
    setSortedProjects(educatorProjects)
  }, [educatorProjects])

  return (
    <>
      <div className='sm:mx-5 my-5'>
        <div className='flex flex-row gap-5 flex-wrap lg:flex-nowrap'>
          <div className='basis-full lg:basis-1/3 shrink-0'>
            <CourseCard
              name={name}
              description={description}
              imageUrl={imageUrl}
            />

            <If condition={notes}>
              <Card className='mt-5 p-5'>
                <h3 className='font-semibold text-xl'>Notes</h3>
                <SecureMarkdown className='text-sm' content={notes} />
              </Card>
            </If>
          </div>

          <div className='grow-0 basis-full'>
            <h3 className='text-xl font-semibold mb-3 sm:ml-0 ml-3'>Lessons</h3>

            <Choose>
              <When condition={sortedProjects.length > 0}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='project'>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <For index='index' each='project' of={sortedProjects}>
                          <div key={project.id} className='flex flex-row mb-3'>
                            <ProjectItem
                              dragHandleProps={provided.dragHandleProps}
                              key={project.id}
                              project={project}
                              index={index}
                              openDeleteModal={() => setProjectIdToDelete(project.id)}
                            />
                          </div>
                        </For>

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <LinkButton onClick={() => setIsAddLessonModalOpen(true)} className='flex items-center self-end leading-none'>
                  <PlusIcon className='h-6 w-6 mr-2' />
                  Add lesson
                </LinkButton>
              </When>

              <Otherwise>
                <button
                  type='button'
                  onClick={() => setIsAddLessonModalOpen(true)}
                  className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  <DocumentPlusIcon className='mx-auto h-12 w-12 text-gray-400' />
                  <span className='mt-2 block text text-gray-900'>Add a new lesson</span>
                </button>
              </Otherwise>
            </Choose>
          </div>
        </div>
      </div>

      <DeleteProjectModal
        projectId={projectIdToDelete}
        isOpen={!!projectIdToDelete}
        closeModal={() => setProjectIdToDelete(null)}
        refetch={refetch}
        assignedToClassrooms={classrooms?.length > 0}
        name={sortedProjects.find(project => project.id === projectIdToDelete)?.name}
      />

      <AddLessonModal
        id={id}
        isOpen={isAddLessonModalOpen}
        onClose={() => setIsAddLessonModalOpen(false)}
      />
    </>
  )
}

export default Overview
