import { useForm, Controller } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useState, useEffect } from 'react'

import { client } from '@helpers/api'
import { useMutation as useGqlMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import Toggle from '@components/Toggle'

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      profile {
        allowSupportAccess
      }
      errors {
        message
      }
    }
  }
`

const SecuritySettings = ({ allowSupportAccess, refetch }) => {
  // Success state management
  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [supportAccessSuccess, setSupportAccessSuccess] = useState(false)

  // Password change form
  const { 
    register: passwordRegister, 
    handleSubmit: handlePasswordSubmit, 
    setError: setPasswordError, 
    getValues, 
    formState: { errors: passwordErrors } 
  } = useForm({ mode: 'onTouched' })
  
  const { mutate: updatePassword, isLoading: isPasswordUpdating } = useMutation({
    mutationFn: async variables => await client.patch('/users', variables),
    onSuccess: () => {
      setPasswordSuccess(true)
    },
    onError: error => {
      const errors = error?.response?.data?.errors

      if (errors.current_password) {
        setPasswordError('current_password', { message: 'Current password is incorrect' })
      }
    }
  })

  // Support access form
  const { control: supportAccessControl, handleSubmit: handleSupportAccessSubmit } = useForm({ 
    mode: 'onTouched',
    defaultValues: {
      allowSupportAccess
    }
  })
  
  const { mutate: updateProfile, isLoading: isSupportAccessUpdating } = useGqlMutation({
    gqlMutation: UPDATE_PROFILE_MUTATION,
    onSuccess: () => {
      setSupportAccessSuccess(true)
      refetch()
    }
  })

  // Clear success states after 3 seconds
  useEffect(() => {
    if (passwordSuccess) {
      const timer = setTimeout(() => setPasswordSuccess(false), 3000)
      return () => clearTimeout(timer)
    }
  }, [passwordSuccess])
  
  useEffect(() => {
    if (supportAccessSuccess) {
      const timer = setTimeout(() => setSupportAccessSuccess(false), 3000)
      return () => clearTimeout(timer)
    }
  }, [supportAccessSuccess])

  return (
    <div className="space-y-6">
      <Card className='p-5'>
        <h2 className='text-2xl font-bold font-heading'>Security settings</h2>

        <form 
          className='flex flex-col space-y-4 mt-5'
          onSubmit={handleSupportAccessSubmit(data => updateProfile({ input: data }))}
        >
          <div>
            <Controller
              name="allowSupportAccess"
              control={supportAccessControl}
              render={({ field }) => (
                <Toggle
                  {...field}
                  label='Allow Mindjoy support to access my account'
                  description='This will help us troubleshoot issues.'
                />
              )}
            />
          </div>

          <div className='flex flex-row justify-end items-center'>
            <If condition={supportAccessSuccess}>
              <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
            </If>

            <Button
              className='w-fit'
              type='submit'
              disabled={!!isSupportAccessUpdating}
              label={isSupportAccessUpdating ? 'Saving...' : 'Save'}
            />
          </div>
        </form>
      </Card>

      <Card className='p-5'>
        <h2 className='text-2xl font-bold font-heading'>Change password</h2>

        <form className='flex flex-col space-y-4 mt-5' onSubmit={handlePasswordSubmit(data => updatePassword({ user: data }))}>
          <div>
            <TextInput
              id='current-password'
              label='Current password'
              type='password'
              required
              {...passwordRegister('current_password', { required: 'Current password is required to make account changes' })}
            />
            <If condition={passwordErrors.current_password}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{passwordErrors.current_password.message}</p>
            </If>
          </div>

          <div>
            <TextInput
              id='password'
              label='New password'
              type='password'
              required
              {...passwordRegister('password', { required: 'New password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
            />
            <If condition={passwordErrors.password}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{passwordErrors.password.message}</p>
            </If>
          </div>

          <div>
            <TextInput
              id='password-confirmation'
              label='New password confirmation'
              type='password'
              required
              {...passwordRegister('password_confirmation', { required: 'Password confirmation is required', validate: value => value === getValues('password') || 'Passwords do not match' })}
            />
            <If condition={passwordErrors.password_confirmation}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{passwordErrors.password_confirmation.message}</p>
            </If>
          </div>

          <div className='flex flex-row justify-end items-center'>
            <If condition={passwordSuccess}>
              <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
            </If>

            <Button
              className='w-fit'
              type='submit'
              disabled={!!isPasswordUpdating}
              label={isPasswordUpdating ? 'Saving...' : 'Save'}
            />
          </div>
        </form>
      </Card>
    </div>
  )
}

export default SecuritySettings
