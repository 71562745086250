const ProfileCardRainbowShadow = () => {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[284px] h-[284px] pointer-events-none z-1">
      <div className="absolute top-[50px] left-[-85px] w-[284px] h-[284px] bg-[#1D8AF7] rounded-full blur-[130px] animate-diamond-right" />
      <div className="absolute top-[-90px] right-[-80px] w-[220px] h-[220px] bg-[#8C52FF] rounded-full blur-[130px] animate-diamond-left" style={{ animationDuration: '4s' }} />
    </div>
  )
}

export default ProfileCardRainbowShadow
