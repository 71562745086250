import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

const optionTheme = theme => {
  switch (theme) {
    case 'secondary':
      return 'ui-checked:bg-purple-100 ui-checked:border-purple-500 hover:ui-checked:bg-purple-100 hover:ui-checked:border-purple-500'
    case 'success':
      return 'ui-checked:bg-green-100 ui-checked:border-green-500 hover:ui-checked:bg-green-100 hover:ui-checked:border-green-500'
    case 'warning':
      return 'ui-checked:bg-orange-100 ui-checked:border-orange-500 hover:ui-checked:bg-orange-100 hover:ui-checked:border-orange-500'
    case 'error':
      return 'ui-checked:bg-red-100 ui-checked:border-red-500 hover:ui-checked:bg-red-100 hover:ui-checked:border-red-500'
    default:
      return 'ui-checked:bg-blue-100 ui-checked:border-blue-500 hover:ui-checked:bg-blue-100 hover:ui-checked:border-blue-500'
  }
}

const Option = ({ children, theme, label, description, className, ...props }) => (
  <HeadlessRadioGroup.Option
    {...props}
    className={twMerge('grow basis-0 rounded-lg', className)}
  >
    <div className={twMerge('border border-grey-200 bg-white rounded-lg p-3 cursor-pointer ui-disabled:cursor-not-allowed shadow-sm hover:bg-gray-100', optionTheme(theme))}>
      <h4 className='font-semibold inline-block py-1'>{label}</h4>

      <p className='text-sm'>
        {description}
      </p>
    </div>
  </HeadlessRadioGroup.Option>
)

export default Option
