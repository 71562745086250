import { RadioGroup } from '@headlessui/react'
import SelectFieldOption from './SelectFieldOption'

const SelectField = ({
  stepIndex,
  maxIndex,
  value,
  setValue,
  nextStep,
  previousStep,
  title,
  subtitle,
  type = 'column',
  options,
  isBusy,
}) => {
  return (
    <div className="max-w-md mx-auto pb-5">
      <h2 className="text-mirage-950 mb-4">{stepIndex} / {maxIndex}</h2>
      <h1 className="text-mirage-950 text-3xl font-semibold my-3">{title}</h1>
      <p className="text-mirage-800 text-lg font-normal">{subtitle}</p>

      <RadioGroup value={value} onChange={setValue}>
        <RadioGroup.Label className="sr-only">{title}</RadioGroup.Label>

        <If condition={type === 'column'}>
          <div className="my-6 flex flex-col gap-3">
            <For each="option" of={options} index="index">
              <SelectFieldOption key={index} option={option}>
                <div className="flex gap-4">
                  <div className="text-lg">{option.emoji}</div>
                  <div className="text-lg">{option.title}</div>
                </div>
              </SelectFieldOption>
            </For>
          </div>
        </If>

        <If condition={type === 'grid'}>
          <div className="my-6 grid grid-cols-2 gap-3">
            <For each="option" of={options} index="index">
              <SelectFieldOption key={index} option={option}>
                <div className="flex flex-col">
                  <div className="text-sm mb-2">{option.emoji}</div>
                  <div className="text-lg">{option.title}</div>
                  <div className="text-mirage-950/60">{option.subtitle}</div>
                </div>
              </SelectFieldOption>
            </For>
          </div>
        </If>
      </RadioGroup>

      <div className="flex gap-3 items-center">
        <button
          onClick={previousStep}
          className="bg-mirage-50 text-mirage-950 text-md rounded-lg py-3 px-5"
          tabIndex={1}
        >
          Back
        </button>
        <button
          onClick={nextStep}
          disabled={!value || isBusy}
          tabIndex={0}
          className="bg-mirage-950 text-white text-md rounded-lg py-3 px-5 shadow-[0_2.5px_32px_0_rgba(0,0,64,0.1)] transition-opacity duration-500 ease-in-out disabled:opacity-20"
        >
          {isBusy ? 'Just a sec...' : 'Continue'}
        </button>
      </div>
    </div>
  )
}

export default SelectField
