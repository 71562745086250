import { twMerge } from 'tailwind-merge'
import { BookOpenIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { stripMarkdown, formatShortTimestamp } from '@helpers/format'
import Image from '@components/Image'

const CardWrapper = ({ className, currentTime, startDate, to, children }) => {
  return (
    <Choose>
      <When condition={!startDate || currentTime >= startDate}>
        <Link className={twMerge('h-full', className)} to={to}>
          {children}
        </Link>
      </When>

      <Otherwise>
        <div className={twMerge('h-full cursor-not-allowed', className)}>{children}</div>
      </Otherwise>
    </Choose>
  )
}

const CourseCard = ({ className, courseId, name, description, imageUrl, startDate, dueDate, currentTime }) => {
  const formattedDescription = stripMarkdown(description)

  return (
    <CardWrapper className={className} currentTime={currentTime} startDate={startDate} to={`/students/courses/${courseId}`}>
      <div className='bg-mirage-50 shadow-[0_2px_1px_0_#EBEBF3] rounded-2xl text-mirage-950 h-full overflow-hidden'>
        <div className='h-48 bg-linear-to-r from-[#57C3FF] to-[#8C52FF]'>
          <Choose>
            <When condition={imageUrl}>
              <Image src={imageUrl} className='w-full object-cover h-48' />
            </When>
            <Otherwise>
              <div className='flex justify-center items-center h-full'>
                <BookOpenIcon className='w-12 h-12 text-white' />
              </div>
            </Otherwise>
          </Choose>
        </div>

        <div className='p-6'>
          <div className='text-lg mb-3 line-clamp-1'>
            {name}
          </div>

          <div className='opacity-70 line-clamp-3'>
            {formattedDescription}
          </div>

          <If condition={startDate && dueDate}>
            <Choose>
              <When condition={startDate > currentTime}>
                <div className='mt-3 opacity-70'>
                  Available at {formatShortTimestamp(startDate)}
                </div>
              </When>

              <When condition={currentTime >= startDate && dueDate > currentTime}>
                <div className='mt-3 text-green-600'>
                  Available until {formatShortTimestamp(dueDate)}
                </div>
              </When>

              <Otherwise>
                <div className='mt-3 opacity-70'>
                  Submissions closed
                </div>
              </Otherwise>
            </Choose>
          </If>
        </div>
      </div>
    </CardWrapper>
  )
}

export default CourseCard