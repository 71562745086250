const PhoneInput = ({ label = 'Phone number', required, children, className }) => (
  <fieldset className={className}>
    <If condition={label}>
      <legend className="font-semibold">
        {label}

        <If condition={required}>
          <span>&nbsp;*</span>
        </If>
      </legend>
    </If>

    <div className="-space-y-px rounded-md shadow-xs">
      {children}
    </div>
  </fieldset>
)

export default PhoneInput
