import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { gql } from 'graphql-request'
import { CheckIcon, InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline'

import { useMutation } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import TextArea from '@components/TextArea'
import SingleFileUpload from '@components/SingleFileInput'
import Spinner from '@components/Spinner'
import Image from '@components/Image'
import Notification from '@components/Notification'

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      profile {
        firstName
        lastName
        biography
        profileImage {
          url
        }
      }
      errors {
        message
      }
    }
  }
`

const CREATE_PROFILE_IMAGE_MUTATION = gql`
  mutation createEducatorProfileImage($input: CreateEducatorProfileImageInput!) {
    createEducatorProfileImage(input: $input) {
      profileImage {
        filename
      }
      errors {
        message
      }
    }
  }
`

const DELETE_PROFILE_IMAGE_MUTATION = gql`
  mutation deleteEducatorProfileImage($input: DeleteEducatorProfileImageInput!) {
    deleteEducatorProfileImage(input: $input) {
      success
      errors {
        message
      }
    }
  }
`

const Profile = ({ firstName, lastName, biography, profileImageUrl, refetch }) => {
  const [showImageSizeError, setshowImageSizeError] = useState(false)
  const { register, handleSubmit } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName,
      lastName,
      biography
    }
  })
  const { mutate: updateEducatorProfile, isLoading: isUpdating, isSuccess } = useMutation({
    gqlMutation: UPDATE_EDUCATOR_PROFILE_MUTATION,
    onSuccess: () => refetch()
  })

  const { mutate: createImage, isLoading: isCreating } = useMutation({
    gqlMutation: CREATE_PROFILE_IMAGE_MUTATION,
    onSuccess: () => refetch()
  })

  const { mutate: deleteImage, isLoading: isDeleting } = useMutation({
    gqlMutation: DELETE_PROFILE_IMAGE_MUTATION,
    variables: { input: {} },
    onSuccess: () => refetch()
  })

  const submit = data => updateEducatorProfile({ input: data })

  return (
    <Card className='p-5'>
      <h2 className='text-2xl font-bold font-heading'>Profile</h2>

      <Notification className='border-0 font-normal text-gray-900 flex items-cente leading-none mt-3'>
        <InformationCircleIcon className='h-6 w-6 mr-2' />
        <span>Your profile will be visible when sharing tutors</span>
      </Notification>

      <div className='flex gap-5 mt-5'>
        <div className='flex flex-col p-0 mb-3 h-fit overflow-crop sm:w-[400px]'>
          <Choose>
            <When condition={isCreating}>
              <Spinner className='py-16 self-center' />
            </When>

            <When condition={profileImageUrl}>
              <div className='relative group'>
                <Image
                  className='object-cover w-full h-[200px] md:rounded-lg rounded-none'
                  src={profileImageUrl}
                  placeholder={<div className='md:rounded-lg rounded-none h-[200px] w-full bg-gray-200 animate-pulse border shadow-sm' />}
                />

                <Button
                  onClick={() => deleteImage()}
                  className='absolute group-hover:block hidden bottom-0 right-0 m-3'
                  theme='error'
                  aria-label='Delete image'
                  label={
                    <Choose>
                      <When condition={isDeleting}>
                        <Spinner className='**:bg-white' />
                      </When>

                      <Otherwise>
                        <TrashIcon className='h-5 w-5' />
                      </Otherwise>
                    </Choose>
                  }
                />
              </div>
            </When>

            <Otherwise>
              <div className='bg-gray-200 rounded-lg flex flex-col items-center justify-center py-16 px-5'>
                <SingleFileUpload
                  label='Upload an image'
                  className='mt-3 text-center'
                  tabIndex={0}
                  theme='primary'
                  accept='.png,.jpg,.jpeg'
                  onChange={ev => {
                    const file = ev.target.files[0]

                    if (file && file.size > 10000000) { // 10mb limit
                      setshowImageSizeError(true)
                      return
                    }

                    createImage({ input: { file } })
                  }}
                />
                <small className='mt-3 leading-tight'>
                  Supported file formats: .png, .jpg and .gif.
                  Files must be smaller than 10mb.
                </small>

                <If condition={showImageSizeError}>
                  <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>File size must be less than 10mb</p>
                </If>
              </div>
            </Otherwise>
          </Choose>
        </div>

        <form className='w-full flex flex-col space-y-4' onSubmit={handleSubmit(submit)}>
          <div className='flex flex-row justify-between'>
            <TextInput
              className='w-full mr-5'
              id='first-name'
              label='First name'
              {...register('firstName')}
            />

            <div className='w-full'>
              <TextInput
                id='last-name'
                label='Last name'
                {...register('lastName')}
              />
            </div>
          </div>

          <TextArea
            id='biography'
            label='Bio'
            placeholder='Tell us a bit about yourself'
            className='h-24'
            {...register('biography')}
          />

          <div className='flex flex-row justify-end items-center'>
            <If condition={isSuccess}>
              <CheckIcon className='h-6 w-6 text-green-700 mr-3' />
            </If>

            <Button
              className='w-fit'
              type='submit'
              disabled={!!isUpdating}
              label={isUpdating ? 'Saving...' : 'Save'}
            />
          </div>
        </form>
      </div>
    </Card>
  )
}

export default Profile
