const Page = ({ page, pageNumber, setPage }) => (
  <button onClick={() => setPage(pageNumber)}>
    <Choose>
      <When condition={pageNumber === page}>
        <div className="rounded-full flex items-center justify-center p-4 w-5 h-5 border shadow-sm bg-blue-500 text-white">
          {pageNumber}
        </div>
      </When>
      <Otherwise>
        <div className="rounded-full flex items-center justify-center p-4 w-5 h-5 border-0 bg-gray-50 text-gray-900">
          {pageNumber}
        </div>
      </Otherwise>
    </Choose>
  </button>
)

export default Page
