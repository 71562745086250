import { twMerge } from 'tailwind-merge'

const Spinner = ({ className }) => (
  <div className={twMerge("**:bg-black", className)}>
    <span className="h-2 w-2 rounded-xl ml-1 inline-block animate-[big-bounce_1s_ease-in-out_infinite]" />
    <span className="h-2 w-2 rounded-xl ml-1 inline-block animate-[big-bounce_1s_0.1s_ease-in-out_infinite]" />
    <span className="h-2 w-2 rounded-xl ml-1 inline-block animate-[big-bounce_1s_0.2s_ease-in-out_infinite]" />
  </div>
)

export default Spinner
