import { useState, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { gql } from 'graphql-request'
import { ChatBubbleLeftRightIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { truncate, debounce } from 'lodash'

import { useQuery } from '@hooks/graphql'
import { stripMarkdown } from '@helpers/format'
import Button from '@components/Button'
import Combobox from '@components/Combobox'
import Modal from '@components/Modal'
import LinkButton from '@components/LinkButton'
import Image from '@components/Image'

import { useEducatorProject, useUpdateEducatorProject } from '../hooks'

const formatPrompt = prompt => truncate(stripMarkdown(prompt), { length: 100 })

const TUTORS_QUERY = gql`
  query tutors($audience: AudienceEnum!, $search: String, $tags: [String!], $page: Int) {
    tutors(audience: $audience, search: $search, tags: $tags, page: $page, perPage: 10) {
      nodes {
        id
        name
        description
        summary
        visibility
        image {
          url
        }
      }
      pagesCount
      nodesCount
    }
  }
`

const TutorSelectionModal = ({ id, closeModal, isOpen, tutor }) => {
  const { refetch } = useEducatorProject({ id })
  const { updateProject, status } = useUpdateEducatorProject({
    id,
    onSuccess: () => {
      refetch()
      closeModal()
    }
  })

  const [searchTerm, setSearchTerm] = useState(null)

  const { data: { tutors: { nodes: tutors = [] } = {} } = {}, isFetching: isSearching } = useQuery({
    queryKey: ['tutors', 'ME', searchTerm],
    gqlQuery: TUTORS_QUERY,
    variables: { audience: 'ME', page: 1, search: searchTerm },
    staleTime: 0,
    cacheTime: 0
  })

  const debouncedSetSearchTerm = useCallback(
    debounce((text) => setSearchTerm(text), 300),
    []
  )

  const { control, watch, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: { tutor }
  })

  const submit = ({ tutor }) => {
    updateProject({ tutorId: tutor?.id || null })
  }

  const selectedTutor = watch('tutor')

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className='flex flex-col h-full'>
        <h3 className='font-heading text-2xl font-bold mb-3'>Select a Tutor</h3>

        <form
          onSubmit={handleSubmit(submit)}
          className='flex flex-col md:px-0 px-5'
        >
          <div className='mb-3'>
            <Controller
              name='tutor'
              control={control}
              render={({ field }) => (
                <Combobox
                  id='tutor'
                  className='w-full'
                  {...field}
                  placeholder='Type to search your tutors'
                  displayValue={tutor => tutor?.name}
                  onSearch={debouncedSetSearchTerm}
                  nullable
                >
                  <If condition={tutors.length < 1 && !isSearching}>
                    <p className='ml-3 my-3 text-gray-500'>No tutors found</p>
                  </If>

                  <For each='tutor' of={tutors}>
                    <Combobox.Option
                      key={tutor.id}
                      value={tutor}
                      label={
                        <div className='flex items-center'>
                          <Choose>
                            <When condition={tutor.image?.url}>
                              <Image
                                src={tutor.image?.url}
                                alt={tutor.name}
                                className='size-10 rounded-md mr-3 object-cover shrink-0'
                                placeholder={<div className='size-10 mr-3 rounded-md bg-gray-200 animate-pulse shrink-0' />}
                              />
                            </When>

                            <Otherwise>
                              <div className='flex items-center justify-center size-10 rounded-md mr-3 bg-gray-100 shrink-0'>
                                <ChatBubbleLeftRightIcon className='size-5 text-gray-400' />
                              </div>
                            </Otherwise>
                          </Choose>

                          <div className='leading-tight truncate'>
                            <p className='leading-none'>{tutor.name}</p>
                            <small className='text-gray-500'>
                              <Choose>
                                <When condition={tutor.summary}>
                                  {tutor.summary}
                                </When>

                                <Otherwise>
                                  {formatPrompt(tutor.description)}
                                </Otherwise>
                              </Choose>
                            </small>
                          </div>
                        </div>
                      }
                    />
                  </For>
                </Combobox>
              )}
            />

            <If condition={errors.tutor}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.tutor.message}</p>
            </If>
          </div>

          <Choose>
            <When condition={!selectedTutor}>
              <p className='flex'>
                Or create a&nbsp;
                <Link
                  className='flex items-center text-blue-500'
                  target='_blank'
                  to='/educators/tutors/new'
                >
                  new tutor
                  <ArrowTopRightOnSquareIcon className='size-4 ml-1' />
                </Link>
              </p>
            </When>

            <Otherwise>
              <div className='flex'>
                <Choose>
                  <When condition={selectedTutor.image?.url}>
                    <Image
                      src={selectedTutor.image?.url}
                      alt={selectedTutor.name}
                      className='size-[100px] rounded-md mr-3 object-cover shrink-0'
                      placeholder={<div className='size-[100px] shrink-0 mr-3 rounded-md bg-gray-200 animate-pulse' />}
                    />
                  </When>

                  <Otherwise>
                    <div className='flex items-center justify-center size-[100px] rounded-md mr-3 bg-blue-100 shrink-0'>
                      <ChatBubbleLeftRightIcon className='size-10 text-blue-400' />
                    </div>
                  </Otherwise>
                </Choose>

                <div className='leading-tight'>
                  <h3 className='text-lg font-semibold leading-none'>{selectedTutor.name}</h3>
                  <p className='text-gray-500'>
                    <Choose>
                      <When condition={selectedTutor.summary}>
                        {selectedTutor.summary}
                      </When>

                      <Otherwise>
                        {formatPrompt(selectedTutor.description)}
                      </Otherwise>
                    </Choose>
                  </p>
                </div>
              </div>
            </Otherwise>
          </Choose>

          <div className='flex flex-row justify-between items-center mt-3'>
            <LinkButton
              className='text-gray-500'
              onClick={closeModal}
            >
              Cancel
            </LinkButton>

            <Button
              disabled={status === 'loading'}
              label={status === 'loading' ? 'Saving...' : 'Save'}
              type='submit'
              className='w-fit'
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default TutorSelectionModal
