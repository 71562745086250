import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'

const modalSize = size => {
  switch (size) {
    case 'sm':
      return 'max-w-md'
    case 'md':
      return 'max-w-2xl'
    case 'lg':
      return 'max-w-4xl'
    case 'xl':
      return 'max-w-6xl'
    default:
      return 'max-w-lg'
  }
}

const Modal = ({ children, isOpen, onClose, size, className, title, footer }) => {
  if (!isOpen) return null

  const modalContentRef = useRef(null)

  return (
    createPortal(
      <>
        <div aria-hidden="true" className="fixed top-0 left-0 right-0 bottom-0 bg-black/50 flex flex-row justify-center items-center z-40" />

        {/* Scrollble panel */}
        <div onClick={onClose} className="fixed inset-0 overflow-y-auto z-40">
          {/* Container to centre modal */}
          <div className="flex min-h-full items-center justify-center">
            {/* Modal */}
            <div
              onClick={e => e.stopPropagation()}
              ref={modalContentRef}
              className={twMerge("relative rounded-lg bg-white text-left shadow-xl my-8 w-full p-6", modalSize(size))}>
              <button onClick={onClose} className="top-0 right-0 absolute mt-5 mr-5">
                <img className="w-5" src="/close.svg" alt="Close" />
              </button>

              {title && (
                <div className="px-6 pt-6 pb-0">
                  <h2 className="text-xl font-semibold">{title}</h2>
                </div>
              )}

              <div className={className}>
                {children}
              </div>

              {footer && (
                <div className="px-6 py-4 border-t">
                  {footer}
                </div>
              )}
            </div>
          </div>
        </div>
      </>,
      document.getElementById('modal')
    )
  )
}

export default Modal
