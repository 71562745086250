import { useState, useEffect } from 'react'
import { gql } from 'graphql-request'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@hooks/graphql'
import { useBackground } from '@hooks/useBackground'
import { useCurrentUser } from '@contexts/currentUser'
import { useChannel } from '@contexts/actionCable'
import { useFlashMessage } from '@components/FlashMessage'

import { ClockIcon } from '@heroicons/react/24/outline'
import Spinner from '../../../components/Spinner'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import FlashMessage from '@components/FlashMessage'

import Interstitial from './Interstitial'
import ProfileInterstitial from './ProfileInterstitial'
import SelectField from './SelectField'
import TextField from './TextField'
import ProgressBar from './ProgressBar'
import options from './options'

const ONBOARDING_QUERY = gql`
  query onboarding {
    me {
    firstName
      newProfile {
        studentOnboardingCompleted
      }
    }
  }
`

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation completeStudentOnboarding($input: CompleteStudentOnboardingInput!) {
    completeStudentOnboarding(input: $input) {
      profile {
        studentOnboardingCompleted
      }
    }
  }
`

const Onboarding = () => {
  useBackground('white')
  const { setUser, user } = useCurrentUser()
  const navigate = useNavigate()
  const { setFlashMessage } = useFlashMessage()
  const { subscribe, unsubscribe } = useChannel()

  const [step, setStep] = useState(null)
  const nextStep = () => setStep(step + 1)
  const previousStep = () => setStep(step - 1)

  const [learningGoal, setLearningGoal] = useState(null)
  const [learningFocus, setLearningFocus] = useState(null)
  const [learningPreference, setLearningPreference] = useState(null)
  const [proudAchievement, setProudAchievement] = useState(null)
  const [learningMotivator, setLearningMotivator] = useState(null)
  const [learningTitle, setLearningTitle] = useState(null)
  const [learningMotivation, setLearningMotivation] = useState(null)
  const [learningValues, setLearningValues] = useState(null)
  const [pictureStyle, setPictureStyle] = useState(null)

  const {
    data: {
      me: { firstName, newProfile: profile = {} } = {}
    } = {},
    isLoading
  } = useQuery({
    queryKey: ['onboardingData'],
    gqlQuery: ONBOARDING_QUERY,
    onSuccess: data => {
      // Onboarding already completed
      // TODO(workspaces_migration): change this to "profile" once BE has been updated
      if (data.me.newProfile.studentOnboardingCompleted) {
        navigate('/students')
      } else {
        setStep(1)
      }
    }
  })

  const fieldCount = 5
  const stepCount = 9

  const { mutate: completeOnboarding, isLoading: isCompleting } = useMutation({
    gqlMutation: COMPLETE_ONBOARDING_MUTATION,
    variables: { input: { learningGoal, learningFocus, learningPreference, proudAchievement, learningMotivator } },
    onError: (error) => {
      if (error.message.includes('Content moderation')) {
        setFlashMessage("Oops, it looks like your response doesn't meet our content guidelines. Please revise and try again!", 'error')
        setStep(6) // text input step
      } else {
        setFlashMessage('Oops, something went wrong! Try again?', 'error')
        setStep(1)
      }
    }
  })

  useEffect(() => {
    // Only subscribe if the user has a valid ID
    if (user?.id) {
      subscribe({ channel: 'StudentOnboardingChannel' }, { received: (data) => {
        if (data.type == 'profile_ready') {
          // update local data to show on the card
          const { learning_title, learning_motivation, learning_values, picture_style } = data
          setLearningTitle(learning_title)
          setLearningMotivation(learning_motivation)
          setLearningValues(learning_values)
          setPictureStyle(picture_style)

          setUser({ ...user, student_onboarding_completed: true })
          setStep(stepCount) // navigate to last step
        }

        if (data.type == 'error') {
          setFlashMessage('Oops, something went wrong! Try again?', 'error')
          setStep(1)
        }
      }})

      return () => unsubscribe()
    }
  }, [user?.id])

  return (
    <div className='bg-white md:h-screen p-5 pt-24 md:p-2 h-min-screen w-full flex flex-col items-center justify-center overflow-hidden'>
      
      <div className='absolute top-0 left-0 w-full p-5'>
        <img className='h-12 w-auto' src='/mindjoy-logo.svg' alt='Mindjoy logo' />
      </div>
      
      <FlashMessage />

      <SwitchTransition mode="out-in">
        <CSSTransition
          key={step}
          timeout={50}
          classNames={{
            enter: 'opacity-0',
            enterActive: 'opacity-100 transition-opacity duration-500 ease-out',
            exit: 'opacity-100',
            exitActive: 'opacity-0 transition-opacity duration-500 ease-out',
          }}
          unmountOnExit
        >
          <div>
            <Choose>
              <When condition={isLoading}>
                <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
              </When>

              <When condition={step === 1}>
                <Interstitial
                  image={<img src='/profile-cards.svg' alt='Mindforge Cards' className='w-[550px]' />}
                  title={`Hi ${firstName}, welcome to Mindjoy!`}
                  content="We're excited to have you on board! Let's kick things off by learning a little about you and your interests. (This is just to help us personalize your experience)"
                  buttonText='Get Started'
                  nextStep={nextStep}
                  footer={<div className='text-mirage-950 flex gap-2'><ClockIcon className='w-5 h-5' /><span>1 minute</span></div>}
                />
              </When>

              <When condition={step === 2}>
                <SelectField
                  stepIndex={1}
                  maxIndex={fieldCount}
                  value={learningGoal}
                  setValue={setLearningGoal}
                  nextStep={nextStep}
                  previousStep={previousStep}
                  title="You're in charge of your own learning!"
                  subtitle="Let's pick a goal to help you focus your learning experience. Which goal fits you best?"
                  options={options.learningGoal}
                />
              </When>

              <When condition={step === 3}>
                <SelectField
                  stepIndex={2}
                  maxIndex={fieldCount}
                  value={learningFocus}
                  setValue={setLearningFocus}
                  nextStep={nextStep}
                  previousStep={previousStep}
                  title="How would you like to level up?"
                  subtitle="Let us know what areas you'd like to grow in. Your input helps us guide your learning journey."
                  options={options.learningFocus}
                />
              </When>

              <When condition={step === 4}>
                <SelectField
                  stepIndex={3}
                  maxIndex={fieldCount}
                  value={learningPreference}
                  setValue={setLearningPreference}
                  nextStep={nextStep}
                  previousStep={previousStep}
                  title='How do you learn best?'
                  subtitle='Different people like to learn in different ways! Pick one.'
                  type='grid'
                  options={options.learningPreference}
                />
              </When>

              <When condition={step == 5}>
                <ProfileInterstitial
                  nextStep={nextStep}
                  previousStep={previousStep}
                  title="Let's build your Mindforge card"
                  content="Don't worry if we got something wrong - you can edit your profile at any time."
                  firstName={firstName}
                  learningTitle={learningTitle}
                  learningGoal={learningGoal}
                  learningFocus={learningFocus}
                  learningPreference={learningPreference}
                  learningMotivation={learningMotivation}
                  learningValues={learningValues}
                />
              </When>

              <When condition={step == 6}>
                <TextField
                  stepIndex={4}
                  maxIndex={fieldCount}
                  value={proudAchievement}
                  setValue={setProudAchievement}
                  nextStep={nextStep}
                  previousStep={previousStep}
                  title="What's something you've done or made, that you're proud of?"
                  subtitle='This will help us customize your Mindforge Card.'
                  placeholder='I helped my local library get a 3D printer'
                />
              </When>

              <When condition={step === 7}>
                <SelectField
                  stepIndex={5}
                  maxIndex={fieldCount}
                  value={learningMotivator}
                  setValue={setLearningMotivator}
                  nextStep={() => {
                    completeOnboarding()
                    nextStep()
                  }}
                  isBusy={isCompleting}
                  previousStep={previousStep}
                  title='What best motivates you?'
                  subtitle='Your motivation drives your progress. Select what resonates with you most.'
                  type='grid'
                  options={options.learningMotivator}
                />
              </When>

              <When condition={step === 8}>
                <Interstitial
                  image={<img src='/brainstorm-board.svg' alt='Brainstorm board' className='w-48 mb-4' />}
                  title="Hang tight!"
                  content={<p>
                    We're preparing a workspace JUST for you!
                    <Spinner className='items-center justify-center mt-8' />
                  </p>}
                />
              </When>

              <When condition={step === 9}>
                <ProfileInterstitial
                  nextStep={() => navigate('/students')}
                  title='This is where the fun begins!'
                  content="We've created your Mindforge Card to help you focus your learning..."
                  buttonText="Let's get learning"
                  firstName={firstName}
                  learningTitle={learningTitle}
                  learningGoal={learningGoal}
                  learningFocus={learningFocus}
                  learningPreference={learningPreference}
                  learningMotivation={learningMotivation}
                  learningValues={learningValues}
                  pictureStyle={pictureStyle}
                />
              </When>
            </Choose>
          </div>
        </CSSTransition>
      </SwitchTransition>

      <ProgressBar width={`${step*100/stepCount}%`} />
    </div>
  )
}

export default Onboarding
