import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { gql } from 'graphql-request'
import { ArrowPathIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { useMutation } from '@hooks/graphql'

import Button from '@components/Button'
import CircleSpinner from '@components/CircleSpinner'
import Image from '@components/Image'
import Modal from '@components/Modal'
import SecureMarkdown from '@components/SecureMarkdown'
import TextEditor from '@components/TextEditor'
import DisplayAttachment from '@components/DisplayAttachment/DisplayAttachment'

const PREVIEW_FREE_RESPONSE_AUTOMARKING_MUTATION = gql`
  mutation PreviewFreeResponseAutoMarking($input: PreviewFreeResponseAutoMarkingInput!) {
    previewFreeResponseAutoMarking(input: $input) {
      marks
      feedback
      reasoning

      errors {
        message
      }
    }
  }
`

const UPLOAD_ATTACHMENTS_MUTATION = gql`
  mutation uploadEducatorProjectAttachments($input: UploadEducatorProjectAttachmentsInput!) {
    uploadEducatorProjectAttachments(input: $input) {
      attachments {
        id
        url
        filename
      }
      errors {
        message
      }
    }
  }
`

const MAX_ATTACHMENTS = 5
const MAX_PDF_ATTACHMENTS = 3

const PreviewFreeResponseQuestionModal = ({ blockId, getValues, closeModal, isOpen }) => {
  const { question, markAllocation, markScheme } = getValues()
  const [didMarkingFail, setDidMarkingFail] = useState(false)

  const {
    mutateAsync: previewFreeResponseAutoMarking,
    isLoading: isMarking,
    isSuccess: isMarkingComplete,
    data: { previewFreeResponseAutoMarking: { marks, feedback, reasoning, errors: markingErrors = [] } = {} } = {},
    reset: resetMarking
  } = useMutation({
    gqlMutation: PREVIEW_FREE_RESPONSE_AUTOMARKING_MUTATION,
    onError: (error) => {
      setError('submission', { message: 'Marking failed for an unknown reason. Please try again or contact support.' })
      setDidMarkingFail(true)
    },
    onSuccess: (data) => {
      if (data?.previewFreeResponseAutoMarking?.errors?.length > 0) {
        const errorMessage = data.previewFreeResponseAutoMarking.errors[0].message
        setError('submission', { message: errorMessage || 'An error occurred with marking. Please try again or contact support.' })
        setDidMarkingFail(true)
      } else {
        clearErrors('submission')
      }
    }
  })

  const { mutateAsync: uploadAttachments, isLoading: isUploading } = useMutation({
    gqlMutation: UPLOAD_ATTACHMENTS_MUTATION
  })

  const { handleSubmit, formState: { errors }, control, reset: resetForm, setError, clearErrors, watch } = useForm({
    mode: 'onTouched'
  })

  const { fields, append, remove: removeAttachments } = useFieldArray({
    control,
    name: 'attachments'
  })

  const submittedAnswer = watch('answer')
  const attachments = watch('attachments')
  
  // Clear submission error when answer changes
  const handleAnswerChange = (value) => {
    clearErrors('submission')
    return value
  }

  const submit = ({ answer, attachments = [] }) => {
    clearErrors('submission')
    setDidMarkingFail(false)
    const attachmentIds = attachments.map(attachment => attachment.id)
    previewFreeResponseAutoMarking({ input: { id: blockId, answer, attachmentIds, question, markAllocation, markScheme } })
  }

  const handleFormReset = () => {
    resetMarking()
    resetForm()
    removeAttachments()
    clearErrors('submission')
    setDidMarkingFail(false)
  }

  // Modified to clear submission error
  const handleRemoveAttachment = (id) => {
    removeAttachments(id)
    clearErrors('submission')
  }

  const handleFilesAttached = async event => {
    clearErrors() // This clears all errors including submission

    const files = Array.from(event.target.files)

    // Check that files are either images or PDFs
    // File pickers can allow selecting other file types on some devices.
    if (files.some(file => !file.type.startsWith('image/') && file.type !== 'application/pdf')) {
      setError('attachments', { message: 'Files must be images or PDFs.' })
      return
    }

    // File size limit
    if (files.some(file => file.size > 10000000)) { // 10mb limit
      setError('attachments', { message: 'Files must be less than 10MB in size.' })
      return
    }

    // Count PDFs in the current upload
    const pdfCount = files.filter(file => file.type === 'application/pdf').length
    if (pdfCount > MAX_PDF_ATTACHMENTS) {
      setError('attachments', { message: `You can only upload up to ${MAX_PDF_ATTACHMENTS} PDF files at a time.` })
      return
    }
    
    // Check total attachment limit (5)
    if (fields.length + files.length > MAX_ATTACHMENTS) {
      setError('attachments', { message: `You can only have a maximum of ${MAX_ATTACHMENTS} attachments in total.` })
      return
    }

    const { uploadEducatorProjectAttachments: { attachments = [], errors = [] } } = await uploadAttachments({ input: { attachments: files } })

    if (errors.length > 0) {
      setError('attachments', { message: errors[0].message })
    } else {
      append(attachments)
      clearErrors('submission') // Clear submission error on successful upload
    }
  }

  const handleCloseModal = () => {
    handleFormReset()
    closeModal()
  }

  return (
    <Modal
      size='md'
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <form
        onSubmit={handleSubmit(submit)}
        className='w-full flex flex-col px-3 py-5 gap-5'
      >
        <SecureMarkdown content={question} />

        <Choose>
          <When condition={isMarkingComplete && !errors.submission && !didMarkingFail}>
            <div className='bg-gray-200 p-3 rounded-lg'>
              <SecureMarkdown content={submittedAnswer} />

              <If condition={attachments.length > 0}>
                <div className='flex flex-wrap gap-3'>
                  <For each='attachment' of={attachments}>
                    <DisplayAttachment
                      key={attachment.id}
                      id={attachment.id}
                      filename={attachment.filename}
                      url={attachment.url}
                      outline={false}
                    />
                  </For>
                </div>
              </If>
            </div>

            <div>
              <h3 className='text-lg font-semibold'>Mark</h3>
              <div className='w-fit rounded-lg leading-tight'>
                <span className='text-lg font-semibold'>{marks} / {markAllocation}</span>
              </div>
            </div>

            <div className='flex flex-col bg-purple-100 p-3 rounded-lg leading-tight'>
              <div className='flex items-center mb-1'>
                <SparklesIcon className='size-5 block mr-2 shrink-0' />
                <h3 className='text-lg font-semibold'>AI reasoning</h3>
              </div>

              <SecureMarkdown content={reasoning} />
            </div>

            <div>
              <h3 className='text-lg font-semibold'>Student feedback</h3>
              <div className='flex flex-col rounded-lg leading-tight'>
                <SecureMarkdown content={feedback} />
              </div>
            </div>

            <Button
              className='ml-auto'
              type='button'
              variant='outlined'
              theme='light'
              onClick={() => handleFormReset()}
              label={<span className='flex gap-2'><ArrowPathIcon className='h-5 w-5' /> Reset</span>}
            />
          </When>

          <Otherwise>
            <TextEditor.Container>
              <If condition={fields.length > 0}>
                <TextEditor.AttachmentList>
                  <For each='attachment' of={fields}>
                    <TextEditor.Attachment
                      key={attachment.id}
                      id={attachment.id}
                      filename={attachment.filename}
                      url={attachment.url}
                      remove={() => handleRemoveAttachment(attachment.id)}
                    />
                  </For>
                </TextEditor.AttachmentList>
              </If>

              <Controller
                name='answer'
                rules={{
                  validate: value => (value || fields.length > 0) ? null : 'Answer or attachment is required'
                }}
                control={control}
                render={({ field }) => (
                  <TextEditor
                    className='min-h-[200px]'
                    {...field}
                    onChange={(value) => {
                      clearErrors('submission')
                      field.onChange(value)
                    }}
                  />
                )}
              />

              <TextEditor.Actions>
                <Choose>
                  <When condition={isUploading}>
                    <CircleSpinner className='p-1 mr-2' />
                  </When>

                  <Otherwise>
                    <TextEditor.FileInput
                      onChange={handleFilesAttached}
                      accept='image/*,application/pdf'
                      fileType='document'
                    />
                    <TextEditor.FileInput
                      onChange={handleFilesAttached}
                      accept='image/*,application/pdf'
                      fileType='image'
                    />
                  </Otherwise>
                </Choose>

                <TextEditor.ToolbarToggle />
              </TextEditor.Actions>
            </TextEditor.Container>

            <If condition={errors.answer}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
            </If>

            <If condition={errors.attachments}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.attachments.message}</p>
            </If>

            <If condition={errors.submission}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.submission.message}</p>
            </If>

            <Choose>
              <When condition={isMarking}>
                <span className='flex ml-auto items-center gap-2 leading-none'><CircleSpinner className='h-5 w-5' /> Marking</span>
              </When>

              <Otherwise>
                <Button
                  className='ml-auto'
                  type='submit'
                  disabled={isMarking}
                  label='Submit'
                />
              </Otherwise>
            </Choose>
          </Otherwise>
        </Choose>
      </form>
    </Modal>
  )
}

export default PreviewFreeResponseQuestionModal
