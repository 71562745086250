import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'

import Image from '@components/Image'
import Card from '@components/Card'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

const TutorCard = ({ title, description, imageUrl, profileImageUrl, callToAction, url }) => {
  return (
    <Card className={twMerge('flex flex-col p-0 grow h-full rounded-md')}>
      <Image
        className='object-cover w-full h-[200px] rounded-none'
        src={imageUrl}
        placeholder={<div className='md:rounded-lg rounded-none h-[200px] w-full bg-gray-200 animate-pulse border shadow-sm' />}
      />

      <div className='w-full flex gap-3 pt-5 px-3'>
        <If condition={profileImageUrl}>
          <Image
            className='object-cover w-12 h-12 rounded-full mb-3 md:mb-0'
            src={profileImageUrl}
            placeholder={<div className='w-12 h-12 shrink-0 bg-gray-200 animate-pulse rounded-full mb-3 md:mb-0' />}
          />
        </If>

        <div className='w-full'>
          <h4 className='text-lg font-semibold leading-none text-ellipsis'>{title}</h4>

          <If condition={description}>
            <p className='mt-1 text-gray-500 leading-none overflow-hidden text-ellipsis'>
              {description}
            </p>
          </If>
        </div>
      </div>

      <Link
        className='ml-auto mt-auto flex items-center w-fit px-3 py-1 m-3 rounded-full bg-purple-600 text-white hover:cursor-pointer'
        to={url}
      >
        {callToAction}
        <ChevronRightIcon className='w-4 h-4 ml-1' />
      </Link>
    </Card>
  )
}

export default TutorCard
