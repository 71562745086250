import ProgressBar from './ProgressBar'
import TextBlock from './TextBlock'
import ReflectionBlock from './ReflectionBlock'
import QuestionBlock from './QuestionBlock'
import FreeResponseBlock from './FreeResponseBlock'
import VideoBlock from './VideoBlock'
import ImageBlock from './ImageBlock'

const ProjectBlocks = ({
  blocks,
  blockSubmissions,
  submissionId,
  assignmentId,
  submissionStatus,
  currentBlockIndex,
  currentBlock,
  currentBlockSubmission,
  moveToNextBlock,
  moveToPreviousBlock,
  isSubmissionClosed,
  setIsSubmitModalOpen,
  isTimeUp,
}) => (
  <>
    <ProgressBar
      currentBlockIndex={currentBlockIndex}
      blocks={blocks}
      blockSubmissions={blockSubmissions}
    />

    <Choose>
      <When condition={currentBlock?.__typename === 'Text'}>
        <TextBlock
          blockId={currentBlock.blockId}
          submissionId={submissionId}
          moveToNextBlock={moveToNextBlock}
          moveToPreviousBlock={moveToPreviousBlock}
          submissionStatus={submissionStatus}
          isFirstBlock={currentBlockIndex === 0}
          isLastBlock={currentBlockIndex === blocks.length - 1}
          isSubmissionClosed={isSubmissionClosed}
          openSubmitModal={() => setIsSubmitModalOpen(true)}
          content={currentBlock.content}
        />
      </When>

      <When condition={currentBlock?.__typename === 'Reflection'}>
        <ReflectionBlock
          key={currentBlock.blockId}
          submissionId={submissionId}
          assignmentId={assignmentId}
          moveToNextBlock={moveToNextBlock}
          moveToPreviousBlock={moveToPreviousBlock}
          submissionStatus={submissionStatus}
          isFirstBlock={currentBlockIndex === 0}
          isLastBlock={currentBlockIndex === blocks.length - 1}
          isSubmissionClosed={isSubmissionClosed}
          openSubmitModal={() => setIsSubmitModalOpen(true)}
          question={currentBlock.question}
          id={currentBlock.id}
          blockId={currentBlock.blockId}
          answer={currentBlockSubmission?.content?.answer}
          isTimeUp={isTimeUp}
        />
      </When>

      <When condition={currentBlock?.__typename === 'Question'}>
        <QuestionBlock
          key={currentBlock.blockId}
          submissionId={submissionId}
          assignmentId={assignmentId}
          moveToNextBlock={moveToNextBlock}
          moveToPreviousBlock={moveToPreviousBlock}
          submissionStatus={submissionStatus}
          isFirstBlock={currentBlockIndex === 0}
          isLastBlock={currentBlockIndex === blocks.length - 1}
          isSubmissionClosed={isSubmissionClosed}
          openSubmitModal={() => setIsSubmitModalOpen(true)}
          blockId={currentBlock.blockId}
          question={currentBlock.label}
          options={currentBlock.options}
          answerId={currentBlockSubmission?.content?.answer?.id}
          marks={currentBlockSubmission?.content?.marks}
          markAllocation={currentBlock?.markAllocation}
          isTimeUp={isTimeUp}
        />
      </When>

      <When condition={currentBlock?.__typename === 'FreeResponse'}>
        <FreeResponseBlock
          key={currentBlock.blockId}
          submissionId={submissionId}
          assignmentId={assignmentId}
          moveToNextBlock={moveToNextBlock}
          moveToPreviousBlock={moveToPreviousBlock}
          submissionStatus={submissionStatus}
          isFirstBlock={currentBlockIndex === 0}
          isLastBlock={currentBlockIndex === blocks.length - 1}
          isSubmissionClosed={isSubmissionClosed}
          openSubmitModal={() => setIsSubmitModalOpen(true)}
          question={currentBlock.question}
          id={currentBlock.id}
          blockId={currentBlock.blockId}
          answer={currentBlockSubmission?.content?.answer}
          marks={currentBlockSubmission?.content?.marks}
          markAllocation={currentBlock?.markAllocation}
          feedback={currentBlockSubmission?.content?.feedback}
          automarkedResult={currentBlockSubmission?.content?.automarkedResult}
          attachments={currentBlockSubmission?.content?.attachments}
          isTimeUp={isTimeUp}
        />
      </When>

      <When condition={currentBlock?.__typename === 'Video'}>
        <VideoBlock
          key={currentBlock.blockId}
          submissionId={submissionId}
          moveToNextBlock={moveToNextBlock}
          moveToPreviousBlock={moveToPreviousBlock}
          submissionStatus={submissionStatus}
          isFirstBlock={currentBlockIndex === 0}
          isLastBlock={currentBlockIndex === blocks.length - 1}
          isSubmissionClosed={isSubmissionClosed}
          openSubmitModal={() => setIsSubmitModalOpen(true)}
          blockId={currentBlock.blockId}
          url={currentBlock.url}
        />
      </When>

      <When condition={currentBlock?.__typename === 'Image'}>
        <ImageBlock
          key={currentBlock.blockId}
          submissionId={submissionId}
          moveToNextBlock={moveToNextBlock}
          moveToPreviousBlock={moveToPreviousBlock}
          submissionStatus={submissionStatus}
          isFirstBlock={currentBlockIndex === 0}
          isLastBlock={currentBlockIndex === blocks.length - 1}
          isSubmissionClosed={isSubmissionClosed}
          openSubmitModal={() => setIsSubmitModalOpen(true)}
          blockId={currentBlock.blockId}
          url={currentBlock?.file?.url}
          caption={currentBlock.caption}
        />
      </When>
    </Choose>
  </>
)

export default ProjectBlocks
