import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { gql } from 'graphql-request'
import { useNavigate } from 'react-router-dom'

import { useAnalytics } from '@contexts/analytics'
import { useMutation, useQuery } from '@hooks/graphql'
import { useCurrentUser } from '@contexts/currentUser'
import Button from '@components/Button'
import Card from '@components/Card'
import TextInput from '@components/TextInput'
import Combobox from '@components/Combobox'
import Select from '@components/Select'
import DismissiblePill from '@components/DismissiblePill'
import PhoneNumber, { countries } from '@components/PhoneNumber'
import Label from '@components/Label'

import timezones from './timezones.json'

const UPDATE_EDUCATOR_PROFILE_MUTATION = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      profile {
        firstName
        lastName
        organizationRole
        phoneCountryCode
        phoneNumber
        locationCountryCode
        subjects {
          id
          name
        }
      }
      errors {
        message
      }
    }
  }
`

const SUBJECTS_QUERY = gql`
  query subjects {
    subjects {
      id
      name
    }
  }
`

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation completeEducatorOnboarding {
    completeEducatorOnboarding(input: {}) {
      profile {
        educatorOnboardingCompleted
      }
    }
  }
`

const EducatorDetails = ({
  nextStep,
  firstName,
  lastName,
  organizationRole,
  subjects,
  locationCountryCode,
  phoneCountryCode,
  phoneNumber,
  marketingSource
}) => {
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const { setUser, user } = useCurrentUser()

  const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName,
      lastName,
      organizationRole,
      subjects,
      locationCountryCode,
      phoneCountryCode,
      phoneNumber,
      marketingSource
    }
  })

  const { mutate: completeOnboarding, isLoading: isCompleting } = useMutation({
    gqlMutation: COMPLETE_ONBOARDING_MUTATION,
    onSuccess: () => {
      setUser({ ...user, educator_onboarding_completed: true })

      const tutorId = localStorage.getItem('signUpTutorId')

      if (tutorId) {
        localStorage.removeItem('signUpTutorId')
        navigate(`/educators/tutors/${tutorId}`)
      } else {
        navigate('/educators/tutors?audience=community')
      }
    }
  })

  const { mutate: updateEducatorProfile, isLoading: isUpdating } = useMutation({
    gqlMutation: UPDATE_EDUCATOR_PROFILE_MUTATION,
    onSuccess: () => {
      track('Educator Onboarding Step Completed', { step: 'educator' })
      completeOnboarding()
    }
  })

  const [searchTerm, setSearchTerm] = useState('')

  const { data: { subjects: allSubjects = [] } = {} } = useQuery({
    queryKey: ['subjects'],
    gqlQuery: SUBJECTS_QUERY,
    staleTime: 86400000 // refresh once a day
  })

  const filteredSubjects = allSubjects.filter(subject => {
    const subjectName = subject.name.toLowerCase()
    return subjectName.includes(searchTerm.toLowerCase())
  })

  const submit = data => {
    const { subjects, ...rest } = data
    const subjectIds = subjects.map(subject => subject.id)

    updateEducatorProfile({ input: { ...rest, subjectIds } })
  }

  const selectedSubjects = watch('subjects')

  const handleDismissSubject = (subjectId) => {
    const newSubjects = selectedSubjects.filter(subject => subject.id !== subjectId)
    setValue('subjects', newSubjects)
  }

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (!timeZone) return

    const countryCode = timezones[timeZone]

    if (countryCode) {
      setValue('phoneCountryCode', countryCode)
      setValue('locationCountryCode', countryCode)
    }
  }, [])

  return (
    <div className='w-full flex justify-center'>
      <Card className='flex flex-col sm:w-[400px] p-5 my-10 overflow-visible'>
        <img src='/bag.svg' alt='Backpack' className='w-1/4 self-center mt-5' />
        <h1 className='text-2xl font-bold self-center mt-3'>Your details</h1>

        <p className='leading-tight my-3'>Tell us a little bit about yourself.</p>

        <form className='flex flex-col space-y-4 mt-3' onSubmit={handleSubmit(submit)}>
          <If condition={errors.general}>
            <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
              {errors.general.message}
            </p>
          </If>

          <div className='flex gap-3'>
            <div className='w-full'>
              <TextInput
                id='first-name'
                label='First name'
                required
                {...register('firstName', { required: 'First name is required' })}
              />
              <If condition={errors.firstName}>
                <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.firstName.message}</p>
              </If>
            </div>

            <div className='w-full'>
              <TextInput
                id='last-name'
                label='Last name'
                required
                {...register('lastName', { required: 'Last name is required' })}
              />
              <If condition={errors.lastName}>
                <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.lastName.message}</p>
              </If>
            </div>
          </div>

          <div className='w-full'>
            <PhoneNumber
              label='Phone number'
              required
            >
              <PhoneNumber.CountryCodeSelect
                {...register('phoneCountryCode', { required: 'Country code is required' })}
              />

              <PhoneNumber.PhoneNumberInput
                countryCode={watch('phoneCountryCode')}
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                  minLength: { value: 4, message: 'Phone number is too short' },
                  maxLength: { value: 10, message: 'Phone number is too long' },
                  pattern: { value: /^[0-9]*$/, message: 'Phone number must be a number' },
                  onChange: ev => {
                    const cleanedValue = ev.target.value.replace(/^0+|[^0-9]/g, '')
                    setValue('phoneNumber', cleanedValue)
                  }
                })}
              />
            </PhoneNumber>

            <If condition={errors.phoneNumber}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.phoneNumber.message}</p>
            </If>

            <If condition={errors.countryCode}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.countryCode.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              id='country'
              label='Country'
              required
              className='w-full'
              {...register('locationCountryCode', { required: 'Country is required' })}
            >
              <Select.Option value=''>Select country</Select.Option>
              <For each='country' of={countries}>
                <Select.Option key={country.code} value={country.code}>{country.name}</Select.Option>
              </For>
            </Select>
            <If condition={errors.role}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.role.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              id='role'
              label='Your role'
              required
              className='w-full'
              {...register('organizationRole', { required: 'Role is required' })}
            >
              <Select.Option value=''>Select role</Select.Option>
              <Select.Option value='teacher'>Teacher</Select.Option>
              <Select.Option value='lecturer'>Lecturer</Select.Option>
              <Select.Option value='professor'>Professor</Select.Option>
              <Select.Option value='school_leader'>School leader</Select.Option>
              <Select.Option value='head_of_department'>Head of department</Select.Option>
              <Select.Option value='dean'>Dean</Select.Option>
              <Select.Option value='edtech_specialist'>EdTech specialist</Select.Option>
              <Select.Option value='district_leader'>District leader</Select.Option>
              <Select.Option value='parent'>Parent</Select.Option>
              <Select.Option value='other'>Other</Select.Option>
            </Select>
            <If condition={errors.role}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.role.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Label htmlFor='subjects' title='Subjects you teach' required />

            <div className='flex flex-wrap'>
              <For each='subject' of={selectedSubjects}>
                <DismissiblePill
                  theme='light'
                  key={subject.id}
                  label={subject.name}
                  onDismiss={() => handleDismissSubject(subject.id)}
                  className='m-0 mr-1 mb-2'
                />
              </For>
            </div>

            <Controller
              id='subjects'
              name='subjects'
              control={control}
              rules={{ required: 'Select at least one subject' }}
              render={({ field }) => (
                <Combobox
                  {...field}
                  placeholder='Type to search subjects'
                  multiple
                  onSearch={text => setSearchTerm(text)}
                  by={(a, b) => a.id === b.id}
                >
                  <For each='subject' of={filteredSubjects}>
                    <Combobox.Option key={subject.id} value={subject} label={subject.name} />
                  </For>
                </Combobox>
              )}
            />
            <If condition={errors.subjects}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.subjects.message}</p>
            </If>
          </div>

          <div className='w-full'>
            <Select
              className='w-full'
              id='marketing-source'
              label='How did you hear about us?'
              required
              {...register('marketingSource', { required: 'This question is required' })}
            >
              <Select.Option value=''>Select answer</Select.Option>
              <Select.Option value='WORD_OF_MOUTH'>From a colleague who uses Mindjoy</Select.Option>
              <Select.Option value='BLOG'>Read an article on the Mindjoy blog</Select.Option>
              <Select.Option value='EMAIL'>Received an email from Mindjoy</Select.Option>
              <Select.Option value='EDUCATIONAL_INSTITUTION'>My educational institution</Select.Option>
              <Select.Option value='PODCAST'>From listening to a podcast</Select.Option>
              <Select.Option value='WORKSHOP'>Attended a Mindjoy workshop</Select.Option>
              <Select.Option value='TEAM'>Spoke to someone on the Mindjoy team</Select.Option>
              <Select.Option value='GOOGLE'>Google search</Select.Option>
              <Select.Option value='LINKEDIN'>LinkedIn</Select.Option>
              <Select.Option value='TWITTER'>X (formerly Twitter)</Select.Option>
              <Select.Option value='FACEBOOK'>Facebook</Select.Option>
              <Select.Option value='INSTAGRAM'>Instagram</Select.Option>
              <Select.Option value='TIKTOK'>TikTok</Select.Option>
              <Select.Option value='YOUTUBE'>YouTube</Select.Option>
            </Select>

            <If condition={errors.marketingSource}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.marketingSource.message}</p>
            </If>
          </div>

          <Button
            className='w-fit ml-auto'
            type='submit'
            label={(isUpdating || isCompleting) ? 'Saving...' : 'Next'}
            disabled={isUpdating || isCompleting}
          />
        </form>
      </Card>
    </div>
  )
}

export default EducatorDetails
