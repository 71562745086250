import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { DocumentIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'
const PDFDisplay = ({ url, filename, className, canDownload = true, outline = true }) => {
    return (
        <div className={twMerge(
            'w-auto max-w-[400px] h-[auto] rounded-lg flex items-center p-2 relative',
            outline ? 'border border-gray-300 bg-transparent' : 'bg-white',
            className
        )}>
            <div className='h-[42px] w-[42px] bg-red-400 rounded-lg flex items-center justify-center mr-3'>
                <DocumentIcon className='h-6 w-6 text-white' />
            </div>
            <div className='flex-1 min-w-0'>
                <p className='text-m text-gray-800 font-medium truncate'>{filename}</p>
                <p className='text-sm text-gray-500 uppercase'>PDF</p>
            </div>
            <If condition={canDownload}>
                <a
                    href={url}
                    target='_blank'
                    download={filename}
                    className='ml-4 p-2 hover:bg-gray-100 rounded-lg'
                >
                    <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
                </a>
            </If>
        </div>
    )
}

export default PDFDisplay