import { gql } from 'graphql-request'
import { useState } from 'react'
import { useMutation } from '@hooks/graphql'
import { useFlashMessage } from '@components/FlashMessage'

import Modal from '@components/Modal'
import Button from '@components/Button'

const MFA_DISABLE_MUTATION = gql`
  mutation mfaDisable($input: DisableInput!) {
    mfaDisable(input: $input) {
      errors {
        message
      }
    }
  }
`

const DisableMFAModal = ({ onClose, onSuccess }) => {
  const [error, setError] = useState('')
  const { setFlashMessage } = useFlashMessage()

  const { mutate: disableMFA, isLoading } = useMutation({
    gqlMutation: MFA_DISABLE_MUTATION,
    onSuccess: () => {
      setFlashMessage('Multi-Factor Authentication has been disabled', 'error')
      onSuccess()
      onClose()
    },
    onError: (error) => {
      console.error('Error disabling MFA:', error)
      setError('An error occurred while disabling MFA. Please try again.')
    }
  })

  const handleDisableMFA = () => {
    disableMFA({ input: {} })
  }

  return (
    <Modal
      title="Disable Multi-Factor Authentication"
      isOpen={true}
      onClose={onClose}
      className="p-6"
    >
      <div className="space-y-6">
        <div>
          <h3 className="font-semibold mb-2">Are you sure you want to disable MFA?</h3>
          <p className="mb-4">
            Disabling multi-factor authentication will reduce the security of your account. 
            Without MFA, your account will only be protected by your password.
          </p>
          <p className="mb-4">
            If someone gains access to your password, they will be able to access your account without 
            the additional security layer that MFA provides.
          </p>
          <p className="font-semibold text-red-600">
            We strongly recommend keeping MFA enabled for optimal account security.
          </p>
        </div>

        <If condition={!!error}>
          <p className="text-red-500 mt-2">{error}</p>
        </If>
        
        <div className="mt-6 flex justify-end space-x-3">
          <Button
            variant="outlined"
            onClick={onClose}
            label="Cancel"
          />
          <Button
            theme="error"
            onClick={handleDisableMFA}
            label={isLoading ? "Disabling..." : "Disable MFA"}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DisableMFAModal 