import BreadCrumbs from '@components/Breadcrumbs'

const Skeleton = () => (
  <>
    <BreadCrumbs>
      <BreadCrumbs.Link label='My courses' to='/students/courses' />
      <BreadCrumbs.Link label={<div className='w-20 h-4 bg-gray-200 rounded-sm animate-pulse' />} />
      <BreadCrumbs.Text label={<div className='w-20 h-4 bg-gray-200 rounded-sm animate-pulse' />} />
    </BreadCrumbs>

    {/* Mobile Tab Navigation Skeleton */}
    <div className='sm:hidden sticky top-0 bg-white border-b border-gray-200'>
      <div className='flex'>
        <div className='flex-1 py-3 px-4 text-center border-b-2 border-blue-500'>
          <div className='w-16 h-4 bg-gray-200 rounded-sm animate-pulse mx-auto' />
        </div>
        <div className='flex-1 py-3 px-4 text-center'>
          <div className='w-16 h-4 bg-gray-200 rounded-sm animate-pulse mx-auto' />
        </div>
      </div>
    </div>

    {/* Content Area */}
    <div className='h-[calc(100vh-120px)]'>
      {/* Desktop Layout */}
      <div className='hidden sm:flex h-full'>
        {/* Left Panel - Project Blocks */}
        <div className='w-1/2 bg-gray-50 p-5'>
          {/* Progress Bar Skeleton */}
          <div className='mb-6'>
            <div className='h-2 bg-gray-200 rounded-sm animate-pulse' />
          </div>

          {/* Content Block Skeleton */}
          <div className='space-y-4'>
            <div className='h-4 bg-gray-200 w-3/4 rounded-sm animate-pulse' />
            <div className='h-4 bg-gray-200 w-1/2 rounded-sm animate-pulse' />
            <div className='h-4 bg-gray-200 w-5/6 rounded-sm animate-pulse' />
            <div className='h-4 bg-gray-200 w-2/3 rounded-sm animate-pulse' />
          </div>

          {/* Navigation Buttons Skeleton */}
          <div className='flex justify-between mt-8'>
            <div className='w-24 h-8 bg-gray-200 rounded-sm animate-pulse' />
            <div className='w-24 h-8 bg-gray-200 rounded-sm animate-pulse' />
          </div>
        </div>

        {/* Resize Handle */}
        <div className='flex items-center justify-center h-full'>
          <div className='bg-gray-50 px-1 h-full' />
          <div className='w-[1px] h-full bg-gray-200' />
          <div className='bg-white px-1 h-full' />
        </div>

        {/* Right Panel - Chat */}
        <div className='w-1/2 bg-white p-5'>
          {/* Chat Messages Skeleton */}
          <div className='space-y-4'>
            <div className='flex justify-end'>
              <div className='w-2/3 h-20 bg-gray-200 rounded-lg animate-pulse' />
            </div>
            <div className='flex justify-start'>
              <div className='w-2/3 h-16 bg-gray-200 rounded-lg animate-pulse' />
            </div>
            <div className='flex justify-end'>
              <div className='w-2/3 h-24 bg-gray-200 rounded-lg animate-pulse' />
            </div>
          </div>

          {/* Chat Input Skeleton */}
          <div className='w-1/2 absolute bottom-0 right-0 p-4 border border-gray-200'>
            <div className='h-10 bg-gray-200 rounded-lg animate-pulse' />
          </div>
        </div>
      </div>

      {/* Mobile Layout - Initial view shows Project Blocks */}
      <div className='sm:hidden h-full p-5'>
        {/* Progress Bar Skeleton */}
        <div className='mb-6'>
          <div className='h-2 bg-gray-200 rounded-sm animate-pulse' />
        </div>

        {/* Content Block Skeleton */}
        <div className='space-y-4'>
          <div className='h-4 bg-gray-200 w-3/4 rounded-sm animate-pulse' />
          <div className='h-4 bg-gray-200 w-1/2 rounded-sm animate-pulse' />
          <div className='h-4 bg-gray-200 w-5/6 rounded-sm animate-pulse' />
          <div className='h-4 bg-gray-200 w-2/3 rounded-sm animate-pulse' />
        </div>

        {/* Navigation Buttons Skeleton */}
        <div className='flex justify-between mt-8'>
          <div className='w-24 h-8 bg-gray-200 rounded-sm animate-pulse' />
          <div className='w-24 h-8 bg-gray-200 rounded-sm animate-pulse' />
        </div>
      </div>
    </div>
  </>
)

export default Skeleton
