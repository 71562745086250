const Interstitial = ({ nextStep, image, title, content, buttonText, footer, isBusy=false }) => {
  return (
    <div className='max-w-md mx-auto text-center'>
      <If condition={image}>
        <div className='flex justify-center'>
          {image}
        </div>
      </If>

      <If condition={title}>
        <h1 className='text-mirage-950 text-4xl font-semibold my-5'>
          {title}
        </h1>
      </If>

      <If condition={content}>
        <div className='text-mirage-900 text-lg font-light my-6'>
          {content}
        </div>
      </If>

      <div className='flex gap-5 items-center justify-center'>
        <If condition={buttonText}>
          <button onClick={nextStep} disabled={isBusy} tabIndex={0} className='bg-mirage-950 text-white text-md rounded-lg py-3 px-5 shadow-[0_2.5px_32px_0_rgba(0,0,64,0.1)] disabled:opacity-20'>
            {buttonText}
          </button>
        </If>
        <If condition={footer}>
          {footer}
        </If>
      </div>

    </div>
  )
}

export default Interstitial
