// TODO should this be a hook?
import { useEffect } from 'react'

export const useBackground = (color) => {
  useEffect(() => {
    const originalHtmlColor = document.documentElement.style.backgroundColor
    document.documentElement.style.backgroundColor = color
    
    const mainEl = document.querySelector('main')
    let originalMainColor = ''
    if(mainEl) {
      originalMainColor = mainEl.style.backgroundColor
      mainEl.style.backgroundColor = color
    }
    return () => {
      document.documentElement.style.backgroundColor = originalHtmlColor
      if(mainEl) {
         mainEl.style.backgroundColor = originalMainColor
      }
    };
  }, [color]);
}
