import Modal from '@components/Modal'
import { formatTimestamp } from '@helpers/format'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { BellAlertIcon } from '@heroicons/react/24/outline'

const StudentChatListModal = ({ chatSummary, isOpen, closeModal, setSelectedChatId }) => {
  return (
    <Modal
      size='lg'
      title='Chat history'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h2 className='text-2xl font-bold mb-3'>{chatSummary.profile.fullName}'s chats</h2>

      <div className="flex flex-col h-full overflow-y-auto py-5 shrink-0">
        <ul className='w-full font-medium text-gray-900 bg-white border border-gray-200 rounded-lg'>
          <For each='chat' of={chatSummary.chats}>
            <li key={chat.id} className='flex items-center w-full border-b border-gray-200 rounded-t-lg cursor-pointer' onClick={() => setSelectedChatId(chat.id)}>
              <div className='p-3 h-full text-left'>
                {formatTimestamp(chat.createdAt)}

                <If condition={chat.flaggedByModeration}>
                  <span className='group ml-2 inline-flex items-center bg-red-100 text-red-900 p-1 rounded-full'>
                    <BellAlertIcon className='size-4 text-red-500' />
                  </span>
                </If>
              </div>

              <div className='text-right pr-3 ml-auto'>
                <ChevronRightIcon className='w-6 h-6' />
              </div>
            </li>
          </For>
        </ul>
      </div>
    </Modal>
  )
}

export default StudentChatListModal
