import { gql } from 'graphql-request'
import { useState } from 'react'
import { useQuery, useMutation } from '@hooks/graphql'

import PageHeading from '@components/PageHeading'
import Card from '@components/Card'
import Toggle from '@components/Toggle'
import EnableMFAModal from './EnableMFAModal'
import DisableMFAModal from './DisableMFAModal'

const ME_QUERY = gql`
  query me {
    me {
      otpRequiredForLogin
    }
  }
`

const SecuritySettings = () => {
  const [showEnableMFAModal, setShowEnableMFAModal] = useState(false)
  const [showDisableMFAModal, setShowDisableMFAModal] = useState(false)
  const [mfaEnabled, setMfaEnabled] = useState(false)
  
  const { data: { me } = {}, isLoading, refetch } = useQuery({
    gqlQuery: ME_QUERY,
    queryKey: ['me'],
    onSuccess: (data) => {
      if (data?.me) {
        setMfaEnabled(data.me.otpRequiredForLogin)
      }
    }
  })

  const handleMFAToggle = (isEnabled) => {
    if (isEnabled) {
      setShowEnableMFAModal(true)
    } else {
      setShowDisableMFAModal(true)
    }
  }

  const handleMFASetupSuccess = () => {
    refetch()
  }

  const handleMFADisableSuccess = () => {
    refetch()
  }

  return (
    <>
      <PageHeading title='Security Settings' />

      <div className='p-4'>
        <Card className='p-4'>
          <Toggle
            value={mfaEnabled}
            onChange={handleMFAToggle}
            label="Multi-factor Authentication"
            description="Adds an additional layer of security to your account by requiring a verification code."
          />
        </Card>
      </div>

      <If condition={showEnableMFAModal}>
        <EnableMFAModal
          onClose={() => setShowEnableMFAModal(false)}
          onSuccess={handleMFASetupSuccess}
        />
      </If>

      <If condition={showDisableMFAModal}>
        <DisableMFAModal
          onClose={() => setShowDisableMFAModal(false)}
          onSuccess={handleMFADisableSuccess}
        />
      </If>
    </>
  )
}

export default SecuritySettings
