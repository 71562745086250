import { useForm, useWatch } from 'react-hook-form'
import { gql } from 'graphql-request'
import { BoltIcon } from '@heroicons/react/24/solid'
import { LockClosedIcon, ChevronDownIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { useMutation, useQuery } from '@hooks/graphql'
import { useFlashMessage } from '@components/FlashMessage'
import Label from '@components/Label'
import LinkButton from '@components/LinkButton'
import Button from '@components/Button'
import Checkbox from '@components/Checkbox'
import Modal from '@components/Modal'

import { useSubscription } from '../../hooks/subscription'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'

const ASSIGNMENTS_QUERY = gql`
  query series($id: ID!) {
    node(id: $id) {
      ... on Series {
        assignments {
          id
          createdAt
          closed
          classroom {
            id
            name
          }
          startDate
          dueDate
        }
      }
    }
  }
`

const CLASSROOMS_QUERY = gql`
  query classrooms {
    classrooms {
      id
      name
    }
  }
`

const ASSIGN_PATHWAY_TO_CLASSROOM_MUTATION = gql`
  mutation assignPathwayToClassrooms($input: AssignPathwayToClassroomsInput!) {
    assignPathwayToClassrooms(input: $input) {
      series {
        id
        classrooms {
          id
          name
        }
      }
    }
  }
`

const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation updateAssignment($input: UpdateAssignmentInput!) {
    updateAssignment(input: $input) {
      assignment {
        id
      }
    }
  }
`

const OptionalSettings = ({ register, index, defaultValues = {} }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='bg-gray-50 rounded-md w-full'>
      <button
        type='button'
        onClick={() => setIsOpen(!isOpen)}
        className='w-full p-4 flex justify-between items-center'
      >
        <div className='flex items-center gap-2'>
          <Cog6ToothIcon className='h-4 w-4 text-gray-600 my-auto' />
          <h4 className='text-xs text-gray-600 tracking-wider my-auto'>Optional settings</h4>
        </div>
        <ChevronDownIcon
          className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      <div className={`px-4 pb-4 ${isOpen ? 'block' : 'hidden'}`}>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <Label title='Start Date' />
            <input
              type='datetime-local'
              defaultValue={defaultValues.startDate}
              {...register(`assignments.${index}.startDate`)}
              className='block w-full rounded-md border-gray-300 shadow-xs focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-gray-700'
            />
          </div>
          <div>
            <Label title='Due Date' />
            <input
              type='datetime-local'
              defaultValue={defaultValues.dueDate}
              {...register(`assignments.${index}.dueDate`)}
              className='block w-full rounded-md border-gray-300 shadow-xs focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-gray-700'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const AssignClassroomsModal = ({
  closeModal,
  isOpen,
  id,
  name
}) => {
  const { setFlashMessage } = useFlashMessage()
  const { hasProFeatures } = useSubscription()

  const { data: { classrooms: educatorClassrooms = [] } = {} } = useQuery({
    queryKey: ['assignSeriesClassrooms'],
    gqlQuery: CLASSROOMS_QUERY
  })

  const { data: { node: { assignments = [] } = {} } = {}, refetch: refetchAssignments } = useQuery({
    queryKey: ['seriesAssignments', id],
    gqlQuery: ASSIGNMENTS_QUERY,
    variables: { id }
  })

  const { mutate: updateAssignment } = useMutation({
    gqlMutation: UPDATE_ASSIGNMENT_MUTATION,
    onSuccess: () => refetchAssignments()
  })

  const { mutate: assignClassrooms, isLoading: isAssigning } = useMutation({
    gqlMutation: ASSIGN_PATHWAY_TO_CLASSROOM_MUTATION,
    variables: { input: { seriesId: id } },
    onSuccess: () => {
      setFlashMessage('Pathway assigned to classroom', 'success')
      closeModal()
      refetchAssignments()
      reset()
    }
  })

  const {
    control,
    handleSubmit,
    register,
    setValue,
    unregister,
    formState: { isDirty },
    reset
  } = useForm()

  useEffect(() => {
    if (assignments && assignments.length > 0) {
      reset({
        assignments: assignments.map(assignment => ({
          classroomId: assignment.classroom.id,
          startDate: formatDateTimeForInput(assignment.startDate),
          dueDate: formatDateTimeForInput(assignment.dueDate)
        }))
      })
    }
  }, [assignments, reset])

  const handleCheckboxChange = (index, classroomId, checked) => {
    if (checked) {
      setValue(`assignments.${index}.classroomId`, classroomId, { shouldDirty: true })
    } else {
      unregister(`assignments.${index}`, { shouldDirty: true })
    }
  }

  const selectedAssignments = useWatch({
    control,
    name: 'assignments',
    defaultValue: []
  })

  const formatDateTimeForInput = (dateString) => {
    if (!dateString) return ''
    try {
      const date = new Date(dateString)
      // Get YYYY-MM-DD
      const datepart = date.toISOString().split('T')[0]
      // Get hh:mm
      const timepart = date.toTimeString().split(' ')[0].substring(0, 5)
      return `${datepart}T${timepart}`
    } catch (e) {
      console.log('Error formatting date:', dateString, e)
      return ''
    }
  }

  const ensureISODate = (dateString) => {
    if (!dateString) return null
    try {
      const date = new Date(dateString)
      if (isNaN(date.getTime())) return null // Invalid date check
      return date.toISOString()
    } catch (e) {
      console.log('Error converting to ISO date:', dateString, e)
      return null
    }
  }

  const validateAndFormatDates = (formData) => {
    const { assignments } = formData

    const input = {
      seriesId: id,
      assignments: assignments.map(({ classroomId, startDate, dueDate }) => ({
        classroomId,
        ...(startDate ? { startDate: ensureISODate(startDate) } : {}),
        ...(dueDate ? { dueDate: ensureISODate(dueDate) } : {})
      }))
    }

    return input
  }

  const sortClassrooms = (classrooms, assignments) => {
    const { withAssignments, withoutAssignments } = classrooms.reduce((acc, classroom) => {
      const hasAssignment = assignments.some(assignment => assignment.classroom.id === classroom.id)

      if (hasAssignment) {
        acc.withAssignments.push(classroom)
      } else {
        acc.withoutAssignments.push(classroom)
      }

      return acc
    }, { withAssignments: [], withoutAssignments: [] })

    return [...withAssignments, ...withoutAssignments]
  }

  const sortedClassrooms = sortClassrooms(educatorClassrooms, assignments)

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
    >

      <h3 className='font-heading text-2xl font-bold mb-3'>Assign</h3>
      <p className='mb-3'>You're about to assign <strong>{name}</strong>.</p>

      <form
        onSubmit={handleSubmit(data => {
          const input = validateAndFormatDates(data)
          assignClassrooms({ input })
        })}
        className='flex flex-col mt-3'
      >
        <ul className='w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg'>
          <For each='classroom' of={sortedClassrooms}>
            <li key={classroom.id} className='w-full border-b border-gray-200 rounded-t-lg'>
              <With assignment={assignments.find(assignment => assignment.classroom.id === classroom.id)}>
                <Choose>
                  <When condition={assignment}>
                    <div className='p-3'>
                      <div className='flex items-center'>
                        <Choose>
                          <When condition={assignment.closed}>
                            <LockClosedIcon className='size-5 text-red-600' />
                          </When>
                          <Otherwise>
                            <BoltIcon className='size-5 text-green-600' />
                          </Otherwise>
                        </Choose>

                        <Link
                          to={`/educators/classrooms/${classroom.id}/courses/${id}`}
                          className='ml-5 text-sm font-medium text-gray-900 hover:underline hover:text-blue-600'
                        >
                          {classroom.name}
                        </Link>

                        <Choose>
                          <When condition={assignment.closed}>
                            <LinkButton
                              type='button'
                              onClick={() => updateAssignment({ input: { assignmentId: assignment.id, closed: false } })}
                              className='ml-auto'
                            >
                              <span className='flex items-center'>Reopen</span>
                            </LinkButton>
                          </When>
                          <Otherwise>
                            <LinkButton
                              type='button'
                              onClick={() => updateAssignment({ input: { assignmentId: assignment.id, closed: true } })}
                              className='text-red-500 ml-auto hover:text-red-600'
                            >
                              <span className='flex items-center'>Close</span>
                            </LinkButton>
                          </Otherwise>
                        </Choose>
                      </div>

                      <div className='mt-3'>
                        <OptionalSettings
                          register={register}
                          index={assignments.findIndex(a => a.classroom.id === classroom.id)}
                          classroomId={classroom.id}
                          defaultValues={assignment}
                        />
                      </div>
                    </div>
                  </When>

                  <Otherwise>
                    <div className='p-3'>
                      <div className='flex items-center'>
                        <Checkbox
                          onChange={(e) => handleCheckboxChange(index, classroom.id, e.target.checked)}
                          value={classroom.id}
                          id={classroom.id}
                          label={classroom.name}
                          className='flex items-center'
                          labelClassName='w-full text-sm font-medium text-gray-900'
                        />
                      </div>

                      <If condition={selectedAssignments.some(a => a.classroomId === classroom.id)}>
                        <div className='mt-3'>
                          <OptionalSettings
                            register={register}
                            index={index}
                            classroomId={classroom.id}
                          />
                        </div>
                      </If>
                    </div>
                  </Otherwise>
                </Choose>
              </With>
            </li>
          </For>
        </ul>

        <Button
          id='assign-button'
          disabled={isAssigning || !isDirty || !hasProFeatures}
          label={isAssigning ? 'Saving...' : 'Save'}
          type='submit'
          className='w-fit mt-5 self-end'
        />

        <If condition={!hasProFeatures}>
          <ProFeatureTooltip anchorSelect='#assign-button' />
        </If>
      </form>
    </Modal>
  )
}

export default AssignClassroomsModal
