import Card from '@components/Card'

const EmptyNotice = () => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>There's nothing to do, yet!</h4>
    <p>
      You don't have any tutors or pathways assigned to you.
    </p>
  </Card>
)

export default EmptyNotice
