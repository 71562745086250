import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'
import ReactPlayer from 'react-player'
import { useState, useEffect } from 'react'

import { useMutation } from '@hooks/graphql'
import TextInput from '@components/TextInput'
import Button from '@components/Button'
import Notification from '@components/Notification'

const UPDATE_VIDEO_BLOCK_MUTATION = gql`
  mutation updateVideoBlock($input: UpdateVideoBlockInput!) {
    updateVideoBlock(input: $input) {
      video {
        url
      }
      errors {
        message
      }
    }
  }
`

const formatUrl = (url) => {
  if (!url) return null

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }

  return `https://${url}`
}

const VideoForm = ({ id, url, refetch }) => {
  const [videoErrored, setVideoErrored] = useState(false)

  const { register, handleSubmit, watch, formState: { errors } } = useForm({ defaultValues: { url } })
  const currentUrl = watch('url')
  const formattedUrl = formatUrl(currentUrl)


  // Reset error state when URL changes
  useEffect(() => {
    setVideoErrored(false)
  }, [currentUrl])

  const { mutate: updateVideoBlock, isLoading } = useMutation({
    gqlMutation: UPDATE_VIDEO_BLOCK_MUTATION,
    onSuccess: () => refetch()
  })

  const onSubmit = ({ url }) => {
    const formattedUrl = formatUrl(url)

    updateVideoBlock({ input: { id, url: formattedUrl } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col px-5 py-5'>
      <TextInput
        label='Video URL'
        placeholder='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
        {...register('url', { required: 'Video URL is required' })}
      />
      <If condition={errors.url}>
        <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.url.message}</p>
      </If>

      <small className='mt-2 mb-4'>You can add videos from Youtube, Vimeo, Facebook and Twitch.</small>

      <Choose>
        <When condition={videoErrored}>
          <Notification className='w-fit mb-4' theme='error'>
            Oops! Something went wrong while loading your video. Please check if the URL is valid.
          </Notification>
        </When>
        <When condition={formattedUrl}>
          <div className='mb-4 flex justify-center'>
            <div className='w-full max-w-3xl aspect-video'>
              <ReactPlayer
                width='100%'
                height='100%'
                controls
                url={formattedUrl}
                onError={() => setVideoErrored(true)}
                className='bg-gray-200'
                key={formattedUrl}
              />
            </div>
          </div>
        </When>
      </Choose>

      <Button
        className='ml-auto'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default VideoForm
