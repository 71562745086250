import { useState } from 'react'
import {
  ArrowLeftIcon,
  EyeIcon,
  PencilSquareIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline'
import { Routes, Route, Link, useParams, useLocation } from 'react-router-dom'

import ButtonLink from '@components/ButtonLink'
import BreadCrumbs from '@components/Breadcrumbs'

import { useEducatorProject } from './hooks'
import Content from './Content'
import Preview from './Preview'
import LessonNameModal from './LessonNameModal'

const EducatorProjectDetails = () => {
  const { id } = useParams()
  const { project, status, refetch } = useEducatorProject({ id })
  const location = useLocation()
  const [isLessonNameModalOpen, setIsLessonNameModalOpen] = useState(false)

  if (status === 'loading') return

  const projectName = project?.name || 'Untitled project'
  const seriesName = project?.series?.name || 'Untitled pathway'

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='Pathways' to='/educators/courses' />
        <BreadCrumbs.Link label={seriesName} to={`/educators/courses/${project.series.id}`} />
        <BreadCrumbs.Text label={projectName} />

        <button onClick={() => setIsLessonNameModalOpen(true)} className='rounded-full hover:bg-gray-200 p-1 ml-1'>
          <PencilSquareIcon className='size-5' />
        </button>
      </BreadCrumbs>

      <div className='h-[calc(100%-45px-51px)]'>
        <div className='flex flex-row items-center justify-between border-b-gray-200 border-b bg-white h-[50px]'>
          <Link
            to={`/educators/courses/${project.series.id}`}
            className='md:flex hidden items-center px-5 text-blue-500'
          >
            <ArrowLeftIcon className='h-5 w-5 mr-2' />
            Back to pathway
          </Link>

          <Choose>
            <When condition={location.pathname.endsWith('/preview')}>
              <ButtonLink
                to='.'
                className='py-1 sm:mr-5 mr-1 my-1.5 px-3 ml-5'
                theme='secondary'
                variant='outlined'
                id='content-button'
                label={<span className='flex gap-2 items-center'>Content<BookOpenIcon className='h-6 w-6' /></span>}
              />
            </When>

            <Otherwise>
              <ButtonLink
                to='preview'
                className='py-1 sm:mr-5 mr-1 my-1.5 px-3 ml-5'
                variant='outlined'
                label={<span className='flex gap-2 items-center'>Preview<EyeIcon className='h-6 w-6' /></span>}
              />
            </Otherwise>
          </Choose>
        </div>

        <div className='flex flex-row h-full overflow-scroll'>
          <Routes>
            <Route
              index
              element={
                <Content
                  id={id}
                  name={project.name}
                  blocks={project.blocks}
                  tutor={project.tutor}
                  refetch={refetch}
                  prompt={project.prompt}
                  socraticModeEnabled={project.socraticModeEnabled}
                  stemModeEnabled={project.stemModeEnabled}
                />
              }
            />
            <Route
              path='/preview'
              element={<Preview id={id} />}
            />
          </Routes>
        </div>
      </div>

      <LessonNameModal
        isOpen={isLessonNameModalOpen}
        closeModal={() => setIsLessonNameModalOpen(false)}
        id={id}
        name={project.name}
      />
    </>
  )
}

export default EducatorProjectDetails
