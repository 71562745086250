import { useState } from 'react'
import ReactPlayer from 'react-player'

import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import Notification from '@components/Notification'
import Tooltip from '@components/Tooltip'

import { useUpdateBlockSubmission } from './hooks'

const VideoBlock = ({
  url,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  isSubmissionClosed,
  openSubmitModal,
  submissionStatus,
  submissionId,
  blockId
}) => {
  const [videoErrored, setVideoErrored] = useState(false)

  const { updateBlockSubmission } = useUpdateBlockSubmission({ educatorProjectSubmissionId: submissionId })

  const next = () => {
    if (submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed) {
      moveToNextBlock()
    } else {
      updateBlockSubmission({ blockId, status: 'COMPLETED' })
      moveToNextBlock()
    }
  }

  const submitProject = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    openSubmitModal()
  }

  return (
    <div className='mt-3'>
      <Choose>
        <When condition={videoErrored}>
          <Notification className='w-fit' theme='error'>
            Oops! Something went wrong while loading your video.
          </Notification>
        </When>

        <When condition={url}>
          <div className='flex justify-center'>
            <div className='w-full max-w-3xl aspect-video'>
              <ReactPlayer
                width='100%'
                height='100%'
                controls
                url={url}
                onError={() => setVideoErrored(true)}
                className='bg-gray-200'
              />
            </div>
          </div>
        </When>
      </Choose>

      <div className='flex flex-row items-center justify-between mt-3'>
        <LinkButton
          disabled={isFirstBlock}
          onClick={moveToPreviousBlock}
        >
          Back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              id='submit-button'
              className='self-end'
              disabled={submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED' || isSubmissionClosed}
              label={(submissionStatus === 'COMPLETED' || submissionStatus === 'RETURNED') ? 'Submitted' : 'Submit'}
              onClick={submitProject}
            />

            <If condition={isSubmissionClosed}>
              <Tooltip anchorSelect='#submit-button' delayShow={200}>
                Submissions are closed for this pathway
              </Tooltip>
            </If>
          </When>

          <Otherwise>
            <Button
              className='self-end'
              label='Next'
              onClick={next}
            />
          </Otherwise>
        </Choose>
      </div>
    </div>
  )
}

export default VideoBlock
