import { gql } from 'graphql-request'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { useMutation } from '@hooks/graphql'

import OnboardingChecklistItem from './OnboardingChecklistItem'
import { useOnboardingChecklist } from '../hooks/onboardingChecklist'

const COMPLETE_ONBOARDING_CHECKLIST_MUTATION = gql`
  mutation completeStudentOnboardingChecklist {
    completeStudentOnboardingChecklist(input: {}) {
      profile {
        studentOnboardingChecklistCompleted
      }
    }
  }
`

const OnboardingChecklist = () => {
  const {
    isLoading,
    onboardingChecklistCompleted,
    onboardingChecklist,
    dismissOnboardingChecklist
  } = useOnboardingChecklist()

  const { mutate: completeOnboardingChecklist } = useMutation({
    gqlMutation: COMPLETE_ONBOARDING_CHECKLIST_MUTATION,
    onSuccess: () => {
      dismissOnboardingChecklist()
    }
  })

  const checklistItems = [
    {
      key: 'chatWithTutor',
      label: 'Chat with a tutor',
      completed: onboardingChecklist.chatWithTutor
    },
    {
      key: 'startedSeries',
      label: 'Start a pathway',
      completed: onboardingChecklist.startedSeries
    },
    {
      key: 'submittedAssignment',
      label: 'Submit an assignment',
      completed: onboardingChecklist.submittedAssignment
    }
  ]

  const allCompleted = checklistItems.every((item) => item.completed)

  if (isLoading || onboardingChecklistCompleted) return null

  return (
    <>
      <div className='bg-gray-100 rounded-lg p-3 text-sm mb-3 animate-[appear_0.3s_ease-in]'>
        <div className='flex justify-between items-center mb-1'>
          <h3 className='font-semibold text-lg'>Get started</h3>
          <If condition={allCompleted}>
            <button
              className='rounded-full p-1 hover:bg-gray-300 items-center'
              onClick={() => completeOnboardingChecklist()}
            >
              <XMarkIcon className='w-5 h-5' />
            </button>
          </If>
        </div>
        <ul className='flex flex-col gap-y-1'>
          <For each='item' of={checklistItems}>
            <OnboardingChecklistItem
              key={item.key}
              label={item.label}
              completed={item.completed}
            />
          </For>
        </ul>

        <If condition={allCompleted}>
          <p className='text-sm text-gray-900 mt-3'>
            You're all set! We're excited to see what you create 🎉
          </p>
        </If>
      </div>
    </>
  )
}

export default OnboardingChecklist
