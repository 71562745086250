import { twMerge } from 'tailwind-merge'
import { BookOpenIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { stripMarkdown, formatShortTimestamp } from '@helpers/format'
import Image from '@components/Image'

const CardWrapper = ({ className, currentTime, startDate, to, children }) => {
  return (
    <Choose>
      <When condition={!startDate || currentTime >= startDate}>
        <Link className={twMerge('p-0 grow h-fit', className)} to={to}>
          {children}
        </Link>
      </When>

      <Otherwise>
        <div className={twMerge('p-0 grow h-fit cursor-not-allowed', className)}>{children}</div>
      </Otherwise>
    </Choose>
  )
}

const CourseCard = ({ className, courseId, name, description, imageUrl, startDate, dueDate, currentTime }) => {
  const formattedDescription = stripMarkdown(description)

  return (
    <CardWrapper className={className} currentTime={currentTime} startDate={startDate} to={courseId}>
      <Choose>
        <When condition={imageUrl}>
          <Image
            className='object-cover w-full h-[200px] sm:rounded-lg rounded-none'
            src={imageUrl}
            placeholder={<div className='sm:rounded-lg rounded-none h-[200px] w-full bg-gray-200 animate-pulse border shadow-sm' />}
          />
        </When>

        <Otherwise>
          <div className='flex justify-center items-center md:rounded-lg rounded-none h-[200px] w-full bg-blue-100'>
            <BookOpenIcon className='w-12 h-12 text-blue-400' />
          </div>
        </Otherwise>
      </Choose>

      <div className='pt-3 sm:px-0 px-3'>
        <h2 className='text-lg font-semibold leading-none line-clamp-1'>{name}</h2>

        <If condition={formattedDescription}>
          <p className='mt-1 text-gray-500 leading-none line-clamp-2'>
            {formattedDescription}
          </p>
        </If>

        <If condition={startDate && dueDate}>
          <Choose>
            <When condition={startDate > currentTime}>
              <p className='mt-1 text-gray-500 leading-none line-clamp-2'>
                Available at {formatShortTimestamp(startDate)}
              </p>
            </When>

            <When condition={currentTime >= startDate && dueDate > currentTime}>
              <p className='mt-1 text-green-500 leading-none line-clamp-2'>
                Available until {formatShortTimestamp(dueDate)}
              </p>
            </When>

            <Otherwise>
              <p className='mt-1 text-gray-500 leading-none line-clamp-2'>
                Submissions closed
              </p>
            </Otherwise>
          </Choose>
        </If>
      </div>
    </CardWrapper>
  )
}

export default CourseCard
