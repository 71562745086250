import { twMerge } from 'tailwind-merge'

import Image from '@components/Image'
import Card from '@components/Card'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

const ContentCard = ({ title, imageUrl, callToAction, url }) => {
  return (
    <Card className={twMerge('p-0 grow h-fit rounded-md')}>
      <Image
        className='object-contain w-full h-[200px] rounded-none'
        src={imageUrl}
        placeholder={<div className='md:rounded-lg rounded-none h-[200px] w-full bg-gray-200 animate-pulse border shadow-sm' />}
      />

      <div className='w-full flex gap-3 pt-5 p-3'>
        <div className='w-full'>
          <h4 className='text-xl font-semibold leading-none overflow-hidden text-ellipsis'>{title}</h4>

          <Choose>
            <When condition={url}>
              <a
                target='_blank'
                className='ml-auto flex items-center w-fit px-3 py-1 mt-3 rounded-full bg-purple-600 text-white hover:cursor-pointer'
                href={url} rel='noreferrer'
              >
                {callToAction}
                <ChevronRightIcon className='w-4 h-4 ml-1' />
              </a>
            </When>

            <Otherwise>
              <span
                className='ml-auto flex items-center w-fit px-3 py-1 mt-3 rounded-full bg-gray-200 text-black'
              >
                Coming soon
              </span>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </Card>
  )
}

export default ContentCard
