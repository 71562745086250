import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useCurrentUser } from '@contexts/currentUser'
import { useAnalytics } from '@contexts/analytics'

import StudentNav from '@components/StudentNav'
import EducatorNav from '@components/EducatorNav'
import AdminNav from '@components/AdminNav'
import PublicNav from '@components/PublicNav'
import Main from '@components/Main'
import ImpersonationSidebar from '@components/ImpersonationSidebar'

const Layout = () => {
  const location = useLocation()
  const { page } = useAnalytics()

  const { user: { loading, signedIn, impersonating, ...user }, currentMembership } = useCurrentUser()

  useEffect(() => {
    page()
  }, [location])

  const isOnboarding = signedIn && currentMembership?.role === 'student' && location.pathname == '/students/onboarding'

  return (
    <div className='flex flex-row'>
      <Choose>
        <When condition={!signedIn}>
          <PublicNav>
            <Main>
              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </PublicNav>
        </When>

        <When condition={isOnboarding}>
          <If condition={!loading}>
            <Outlet />
          </If>
        </When>

        <When condition={signedIn && currentMembership?.role === 'student'}>
          <StudentNav>
            <Main>
              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </StudentNav>
        </When>

        <When condition={signedIn && (currentMembership?.role === 'educator' || currentMembership?.role === 'admin')}>
          <EducatorNav>
            <Main>
              <If condition={impersonating}>
                <div className='sticky top-0w-full h-[10px] min-h-[10px] bg-orange-500' />
              </If>
              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
            <If condition={impersonating}>
              <ImpersonationSidebar />
            </If>
          </EducatorNav>
        </When>

        <When condition={signedIn && user.role === 'admin'}>
          <AdminNav>
            <Main>
              <div className='sticky top-0w-full h-[10px] min-h-[10px] bg-green-500' />

              <If condition={!loading}>
                <Outlet />
              </If>
            </Main>
          </AdminNav>
        </When>
      </Choose>
    </div>
  )
}

export default Layout
