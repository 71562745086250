import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

const OverflowMenu = ({ orientation = 'vertical', children, className, ...props }) => {
  return (
    <Menu
      as='div'
      className={twMerge('relative inline-block text-left', className)}
      {...props}
    >
      <div>
        <Menu.Button className='flex items-center rounded-full  hover:text-gray-600 focus:outline-hidden focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100'>
          <span className='sr-only'>Open options</span>

          <Choose>
            <When condition={orientation === 'horizontal'}>
              <EllipsisHorizontalIcon className='h-6 w-6' aria-hidden='true' />
            </When>

            <Otherwise>
              <EllipsisVerticalIcon className='h-6 w-6' aria-hidden='true' />
            </Otherwise>
          </Choose>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-50 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-hidden'>
          <div className='flex-col py-1'>
            {children}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default OverflowMenu
