import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { gql } from 'graphql-request'
import { CheckIcon, CloudArrowDownIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline'

import { useQuery } from '@hooks/graphql'
import Button from '@components/Button'
import Card from '@components/Card'
import Select from '@components/Select'
import Table from '@components/Table'
import Spinner from '@components/Spinner'

import NoResults from './NoResults'
import LessonSubmissionList from './LessonSubmissionList'

const SERIES_SUBMISSION_QUERY = gql`
  query seriesSubmissions($classroomId: ID!, $seriesId: ID!) {
    seriesSubmissions(classroomId: $classroomId, seriesId: $seriesId) {
      studentProfile {
        id
        fullName
      }
      submissions {
        id
        status
        score
        educatorProject {
          id
        }
      }
      score
    }
  }
`

const SubmissionList = ({ id, classroomId, educatorProjects, totalPossibleScore }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedLessonId = searchParams.get('lessonId')

  const setSelectedLessonId = lessonId => {
    setSearchParams({ lessonId })
  }

  const lessonListRef = useRef()

  const { data: { seriesSubmissions = [] } = {}, isInitialLoading: isLoading } = useQuery({
    queryKey: ['classroomCourseSubmissions', id, classroomId],
    gqlQuery: SERIES_SUBMISSION_QUERY,
    variables: { classroomId, seriesId: id }
  })

  const getCSVRows = () => {
    // First, process and sort student submissions
    const processedSubmissions = seriesSubmissions.map(sub => {
      // Trim names and split into first and last names
      const fullName = sub.studentProfile.fullName.trim()
      let firstName = ''; let lastName = ''

      // Extract first and last name
      const nameParts = fullName.split(' ')
      if (nameParts.length >= 2) {
        lastName = nameParts.pop().trim()
        firstName = nameParts.join(' ').trim()
      } else {
        // If only one name, treat it as last name
        lastName = fullName
      }

      // Return processed student data
      return {
        ...sub,
        formattedName: `${lastName}, ${firstName}`,
        lastName,
        firstName
      }
    })

    // Sort by last name, then first name
    processedSubmissions.sort((a, b) => {
      const lastNameComparison = a.lastName.localeCompare(b.lastName)
      if (lastNameComparison !== 0) {
        return lastNameComparison
      }
      return a.firstName.localeCompare(b.firstName)
    })

    // Generate CSV rows from the sorted data
    return processedSubmissions.map(sub => {
      // Create row object
      const row = {
        Student: sub.formattedName,
        'Max Score': `${totalPossibleScore}`,
        'Total Score': `${sub.score}`
      }

      educatorProjects.forEach(project => {
        const submission = sub.submissions.find(
          s => s.educatorProject.id === project.id
        )

        // Add original score column
        row[`Lesson "${project.name}" Score`] =
          submission && (submission.status === 'COMPLETED' || submission.status === 'RETURNED')
            ? project.totalPossibleScore > 0
              ? `${submission.score}`
              : '✓'
            : ''
      })

      return row
    })
  }

  const downloadCSV = () => {
    try {
      // If a lesson is selected, get CSV rows from LessonSubmissionList via ref;
      // Otherwise, use our own getCSVRows for seriesSubmissions view.
      const csvRows = selectedLessonId
        ? lessonListRef.current?.getCSVRows() || []
        : getCSVRows()

      if (!csvRows.length) return

      const headers = Object.keys(csvRows[0])
      const csvContent = [
        headers.join(','),
        ...csvRows.map(row =>
          headers.map(header => `"${String(row[header] || '').replace(/"/g, '""')}"`).join(',')
        )
      ].join('\n')

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'submissions-export.csv'
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (error) {
      console.error('CSV Export error:', error)
    }
  }

  return (
    <div className='mx-5 my-3 h-full'>
      <Card className='flex flex-row items-center p-3 mb-5 gap-5'>
        <Select
          label='Lesson'
          value={selectedLessonId}
          onChange={e => setSelectedLessonId(e.target.value)}
        >
          <Select.Option key='' value=''>Select a lesson</Select.Option>
          <For each='lesson' of={educatorProjects}>
            <Select.Option key={lesson.id} value={lesson.id}>{lesson.name}</Select.Option>
          </For>
        </Select>
        {/* TODO Ethan: Add return all submissions logic */}
        {/* <If condition={!selectedLessonId}>
          <Button
            className='ml-auto py-1 self-end disabled:cursor-not-allowed disabled:opacity-40'
            variant='outlined'
            theme='secondary'
            label={
              <span className='flex items-center'>
                <ArrowUturnLeftIcon className='w-5 h-5 mr-2' />
                Return all submissions
              </span>
            }
          />
        </If> */}

        <If condition={seriesSubmissions.length > 0}>
          <div className='ml-auto'>
            <Button
              onClick={downloadCSV}
              theme='secondary'
              variant='outlined'
              className='flex items-center'
              label={<span className='flex gap-2 items-center'><span>CSV</span><CloudArrowDownIcon className='h-6 w-6' /></span>}
            />
          </div>
        </If>
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='flex items-center justify-center w-full mt-24' />
        </When>

        <When condition={seriesSubmissions.length < 1}>
          <NoResults />
        </When>

        <When condition={selectedLessonId}>
          <LessonSubmissionList
            ref={lessonListRef}
            key={`${classroomId}-${selectedLessonId}`}
            classroomId={classroomId}
            educatorProjectId={selectedLessonId}
          />
        </When>

        <Otherwise>
          {/**
           * Complex height calculation for table scrolling:
           * On desktop: height = full height - breadcrumbs - tabs - toolbar - padding
           * On mobile: height = full height - navbar - breadcrumbs - tabs - toolbar - padding
           **/}
          <div className='lg:max-h-[calc(100vh-84px-50px-45px-2.75rem)] max-h-[calc(100vh-57px-84px-50px-45px-2.75rem)] flex overflow-auto whitespace-nowrap w-full rounded-lg border border-gray-200 bg-white'>
            <Table className='table-auto w-full'>
              <Table.Head>
                <Table.Row>
                  <Table.Header className='text-base align-middle w-48 sticky left-0 top-0 z-20 bg-gray-50'>
                    Students
                    <div className='absolute top-0 right-0 bottom-0 w-px bg-gray-200 z-20' />
                  </Table.Header>
                  <Table.Header className='text-base align-middle w-32 sticky top-0 bg-gray-50'>Total</Table.Header>
                  <For each='project' of={educatorProjects}>
                    <Table.Header key={project.id} className='text-base align-middle text-center sticky top-0 bg-gray-50 w-32 z-10'>
                      {project.name}
                    </Table.Header>
                  </For>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='seriesSubmission' of={seriesSubmissions}>
                  <Table.Row key={seriesSubmission.studentProfile.id}>
                    <Table.Cell className='sticky left-0 bg-white w-48 z-10'>
                      <div className='relative flex items-center'>
                        {seriesSubmission.studentProfile.fullName}
                      </div>
                      <div className='absolute top-0 right-0 bottom-0 w-px bg-gray-200 z-20' />
                    </Table.Cell>
                    <Table.Cell className='w-32'>
                      <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                        {seriesSubmission.score} / {totalPossibleScore}
                      </div>
                    </Table.Cell>

                    <For each='project' of={educatorProjects}>
                      <With submission={seriesSubmission.submissions.find(s => s.educatorProject.id === project.id)}>
                        <Choose>
                          <When condition={submission?.status === 'COMPLETED' || submission?.status === 'RETURNED'}>
                            <Table.Cell className='w-32'>
                              <div className='w-full flex justify-center items-center'>
                                <button onClick={() => setSelectedLessonId(project.id)}>
                                  <div className='w-full flex justify-center'>
                                    <Choose>
                                      <When condition={project.totalPossibleScore > 0}>
                                        <With percentage={(submission.score / project.totalPossibleScore) * 100}>
                                          <div className={twMerge(
                                            'relative flex items-center justify-center rounded-md px-2 py-1',
                                            percentage === 100
                                              ? 'bg-green-100 border border-green-600'
                                              : percentage >= 50
                                                ? 'bg-yellow-100 border border-yellow-600'
                                                : 'bg-red-100 border border-red-600'
                                          )}
                                          >
                                            {submission.score}
                                            <span className='mx-1'>/</span>
                                            {project.totalPossibleScore}
                                          </div>
                                        </With>
                                      </When>
                                      <Otherwise>
                                        <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                                          <CheckIcon className='w-5 h-5 mx-auto' />
                                        </div>
                                      </Otherwise>
                                    </Choose>
                                  </div>
                                </button>
                              </div>
                            </Table.Cell>
                          </When>

                          <When condition={submission?.status === 'IN_PROGRESS'}>
                            <Table.Cell className='w-32'>
                              <div className='w-full flex justify-center'>
                                <button
                                  onClick={() => setSelectedLessonId(project.id)}
                                  title='Student in progress'
                                  className='hover:opacity-80'
                                >
                                  <div className='bg-gray-100 rounded-md px-2 py-1 w-fit border border-gray-300'>
                                    <EllipsisHorizontalIcon className='w-5 h-5 mx-auto' />
                                  </div>
                                </button>
                              </div>
                            </Table.Cell>
                          </When>

                          <Otherwise>
                            <Table.Cell />
                          </Otherwise>
                        </Choose>
                      </With>
                    </For>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
}

export default SubmissionList
