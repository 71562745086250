import { GlobeAltIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import CopyToClipboardInput from '@components/CopyToClipboardInput'
import Modal from '@components/Modal'
import Notification from '@components/Notification'

const ShareModal = ({
  closeModal,
  isOpen,
  tutorId,
  name,
  visibility
}) => {
  const tutorShareUrl = `${window.location.origin}/tutors/${tutorId}`
  const whatsappShareMessage = encodeURIComponent(`Check out my Mindjoy AI tutor: *${name}* \n\n${tutorShareUrl}`)
  const twitterShareMessage = encodeURIComponent(`Check out my @TeamMindjoy AI tutor: ${name}\n\n${tutorShareUrl}`)

  return (
    <Modal
      size='sm'
      isOpen={isOpen}
      onClose={closeModal}
    >
      <h3 className='font-heading text-2xl font-bold mb-3'>Share</h3>

      <If condition={visibility === 'PRIVATE'}>
        <Notification className='items-start mb-5'>
          <InformationCircleIcon className='size-5 mr-3 shrink-0 text-blue-900' />
          <span className='font-normal leading-tight'>
            {name} has visibility set to private.&nbsp;
            <Link
              onClick={closeModal}
              to={`/educators/tutors/${tutorId}/settings`}
              className='text-blue-900 underline'
            >
              Update visibility
            </Link>
            &nbsp;to enable sharing via public link.
          </span>
        </Notification>
      </If>

      <CopyToClipboardInput
        className='w-3/4'
        value={`${window.location.origin}/tutors/${tutorId}`}
        label='Public tutor link'
      />

      <div className='flex items-center my-3'>
        <div className='border-b-2 w-full' />
        <span className='p-3'>Or</span>
        <div className='border-b-2 w-full' />
      </div>

      <div className='flex flex-col gap-3'>
        <a
          target='_blank'
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${tutorShareUrl}`}
          className='w-full flex justify-center items-center rounded-md px-3.5 py-2.5 text-base shadow-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600' rel='noreferrer'
        >
          <img src='/linkedin-logo.png' className='w-8 mr-3' />
          Share on LinkedIn
        </a>

        <a
          target='_blank'
          href={`https://wa.me/?text=${whatsappShareMessage}`}
          className='w-full flex justify-center items-center rounded-md px-3.5 py-2.5 text-base shadow-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
          rel='noreferrer'
        >
          <img src='/whatsapp-logo.png' className='w-8 mr-3' />
          Share on WhatsApp
        </a>

        <a
          target='_blank'
          href={`https://www.facebook.com/sharer.php?u=${tutorShareUrl}`}
          className='w-full flex justify-center items-center rounded-md px-3.5 py-2.5 text-base shadow-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
          rel='noreferrer'
        >
          <img src='/facebook-logo.png' className='w-8 mr-3' />
          Share on Facebook
        </a>

        <a
          target='_blank'
          href={`https://twitter.com/intent/tweet?text=${twitterShareMessage}`}
          className='w-full flex justify-center items-center rounded-md px-3.5 py-2.5 text-base shadow-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-600'
          rel='noreferrer'
        >
          <img src='/x-logo.png' className='w-8 mr-3' />
          Share on X (formerly Twitter)
        </a>
      </div>
    </Modal>
  )
}

export default ShareModal
