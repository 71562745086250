import { UserIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@tanstack/react-query'
import { client } from '@helpers/api'
import { useCurrentUser } from '@contexts/currentUser'
import { useFlashMessage } from '@components/FlashMessage'
import Button from '@components/Button'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'

const ImpersonationSidebar = () => {
  const { user: { first_name: firstName, last_name: lastName,impersonating } = {}, currentMembership: { role } } = useCurrentUser()
  const { setFlashMessage } = useFlashMessage()
  
  const { mutate: stopImpersonation, isLoading } = useMutation({
    mutationFn: async () => await client.delete('/admin/impersonations'),
    onSuccess: () => window.location.reload(),
    onError: () => setFlashMessage('Failed to stop impersonation', 'error')
  })

  if (!impersonating) return null

  return (
    <div className="bg-orange-50 border-orange-500 border-l-2 p-4 w-[370px] text-orange-800">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className='text-lg flex items-top mb-4 border-b border-orange-700 pb-3'>
            <ExclamationTriangleIcon className="w-5 h-5 mr-2 text-orange-600 mt-1" />
            Admin impersonation mode
          </div>

          <div className='mb-2'>You are currently impersonating:</div>
          
          <div className="mb-3 p-3 bg-orange-200 rounded-lg">
            <div className='text-xl font-semibold'>
              {firstName} {lastName}
            </div>
            <div className='font-semibold'>
              {role}
            </div>
          </div>

          <p className="mb-3">
            Any actions you take will be performed as this user.
          </p>
        </div>

        <div>
          <Button
              label={isLoading ? 'Exiting...' : 'End Impersonation Session'}
              onClick={stopImpersonation}
              disabled={isLoading}
              className='p-2 w-full'
              theme='error'
          />
        </div>
      </div>
    </div>
  )
}

export default ImpersonationSidebar
