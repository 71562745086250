import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useForm, FormProvider } from 'react-hook-form'

import { request } from '@helpers/graphql'
import { useQuery, useMutation } from '@hooks/graphql'
import { useCurrentUser } from '@contexts/currentUser'
import { ClockIcon } from '@heroicons/react/24/outline'

import { useBreakpoint } from '@contexts/breakpoints'
import BreadCrumbs from '@components/Breadcrumbs'
import Button from '@components/Button'
import Modal from '@components/Modal'

import ChatList from './ChatList'
import ChatDetails from './ChatDetails'
import EmptyChat from './EmptyChat'
import Skeleton from './Skeleton'
import NotFound from './NotFound'
import { useOnboardingChecklist } from '../../hooks/onboardingChecklist'

const TUTOR_ASSIGNMENT_QUERY = gql`
  query tutorAssignment($id: ID!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        createdAt
        classroom {
          id
          name
        }
        proFeaturesEnabled
        tutor {
          id
          name
          summary
          createdAt
          image {
            url
          }
          userName
          organizationName
        }
      }
    }
  }
`

const CHATS_QUERY = gql`
  query chats($assignmentId: ID!, $page: Int!) {
    node(id: $assignmentId) {
      ... on TutorAssignment {
        currentUserChats(page: $page, perPage: 10) {
          nodes {
            id
            createdAt
          }
          pagesCount
          nodesCount
        }
      }
    }
  }
`

const CREATE_CHAT_MUTATION = gql`
  mutation createAssignmentChat($input: CreateAssignmentChatInput!) {
    createAssignmentChat(input: $input) {
      chat {
        id
        createdAt
      }
    }
  }
`

const TutorAssignmentDetails = () => {
  const { currentMembership: { organization: { id: organizationId } } } = useCurrentUser()

  const { id: tutorAssignmentId } = useParams()
  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })
  const [selectedChatId, setSelectedChatId] = useState(null)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)

  const { markOnboardingItemCompleted } = useOnboardingChecklist()

  const { isLoading, data = { node: {} } } = useQuery({
    queryKey: ['tutorAssignment', tutorAssignmentId],
    gqlQuery: TUTOR_ASSIGNMENT_QUERY,
    variables: { id: tutorAssignmentId }
  })

  const { tutor, proFeaturesEnabled } = data.node || {}

  const {
    data: chatsData,
    refetch: refetchChats,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: ['chats', tutorAssignmentId],
    queryFn: async ({ pageParam = 1 }) =>
      request(CHATS_QUERY, {
        assignmentId: tutorAssignmentId,
        page: pageParam,
        perPage: 10,
      }, { 'X-Organization-Id': organizationId }),
    onSuccess: (data) => {
      const recentChat = data.pages[0].node.currentUserChats.nodes[0]
      if(!selectedChatId) {
        setSelectedChatId(recentChat?.id)
      }
    },
    getNextPageParam: (lastPage, pages) => {
      const pagesCount = lastPage?.node?.currentUserChats?.pagesCount || 0
      return pages.length < pagesCount ? pages.length + 1 : false
    }
  })

  const {
    mutate: createChat,
    isLoading: isCreating
  } = useMutation({
    gqlMutation: CREATE_CHAT_MUTATION,
    variables: { input: { assignmentId: tutorAssignmentId } },
    onSuccess: data => {
      refetchChats()
      setSelectedChatId(data.createAssignmentChat.chat.id)
      markOnboardingItemCompleted('chatWithTutor')
    }
  })

  const isMobile = useBreakpoint('md')

  if (isLoading) return null

  return (
    <>
      <Choose>
        <When condition={isLoading}>
          <Skeleton />
        </When>

        <When condition={!tutor}>
          <NotFound navigateBackTo='/students/tutors' />
        </When>

        <Otherwise>
          <BreadCrumbs>
            <BreadCrumbs.Link label='Tutors' to='/students/tutors' />
            <BreadCrumbs.Text label={tutor.name} />
          </BreadCrumbs>

          <div className='relative flex flex-col sm:flex-row h-[calc(100%-45px)] bg-white'>

            <If condition={isMobile}>
              <Button
                label={<span className='flex items-center'><ClockIcon className='h-6 w-6 mr-2' />History</span>}
                variant='outlined'
                theme='light'
                className='shadow-lg absolute z-20 top-0 left-0 w-fit m-3'
                onClick={() => setIsHistoryModalOpen(true)}
              />
            </If>

            <div className='hidden md:flex flex-col md:w-1/4 w-full overflow-y-auto border-r md:bg-gray-50'>
              <div className='p-3 border-1'>
                <If condition={tutor.image?.url}>
                  <img className='object-cover rounded-xl w-full h-[200px] mb-3' src={tutor.image.url} />
                </If>

                <h2 className='text-xl font-semibold leading-tight'>{tutor.name}</h2>

                <If condition={tutor.summary}>
                  <p className='text-gray-600 mt-1 leading-tight'>{tutor.summary}</p>
                </If>
              </div>

              <If condition={!isMobile}>
                <ChatList
                  tutorId={tutor.id}
                  selectedChatId={selectedChatId}
                  setSelectedChatId={setSelectedChatId}
                  pages={chatsData?.pages || []}
                  createChat={createChat}
                  isCreating={isCreating}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  refetch={refetchChats}
                />
              </If>
            </div>

            <Choose>
              <When condition={!!selectedChatId}>
                <FormProvider {...methods}>
                  <ChatDetails
                    name={tutor.name}
                    tutorId={tutor.id}
                    chatId={selectedChatId}
                    proFeaturesEnabled={proFeaturesEnabled}
                  />
                </FormProvider>
              </When>

              <When condition={!isLoading && chatsData?.pages?.chats?.pageCount === 0}>
                <EmptyChat name={tutor.name} createChat={createChat} />
              </When>
            </Choose>
          </div>

          <If condition={isMobile}>
            <Modal
              title='Chat history'
              isOpen={isHistoryModalOpen}
              onClose={() => setIsHistoryModalOpen(false)}
            >
              <ChatList
                tutorId={tutor.id}
                selectedChatId={selectedChatId}
                setSelectedChatId={setSelectedChatId}
                pages={chatsData?.pages || []}
                createChat={createChat}
                isCreating={isCreating}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                refetch={refetchChats}
              />
            </Modal>
          </If>
        </Otherwise>
      </Choose>
    </>
  )
}

export default TutorAssignmentDetails
