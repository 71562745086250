import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { gql } from 'graphql-request'
import { useForm } from 'react-hook-form'

import Spinner from '@components/Spinner'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import Modal from '@components/Modal'
import PageHeading from '@components/PageHeading'
import Table from '@components/Table'
import Card from '@components/Card'
import Toggle from '@components/Toggle'

import { useQuery, useMutation } from '@hooks/graphql'
import { useSubscription } from '../../hooks/subscription'
import { useOnboardingChecklist } from '../../hooks/onboardingChecklist'
import ProFeatureTooltip from '../../components/ProFeatureTooltip'

import NoResults from './NoResults'

const CLASSROOMS_QUERY = gql`
  query classrooms($showArchived: Boolean) {
    classrooms(showArchived: $showArchived) {
      id
      name
      paginatedStudents{
        nodesCount
      }
    }
  }
`

const CREATE_CLASSROOM_MUTATION = gql`
  mutation createClassroom($createClassroomInput: CreateClassroomInput!) {
    createClassroom(input: $createClassroomInput) {
      classroom {
        id
        name
        educator {
          id
          fullName
        }
      }
    }
  }
`

const ClassroomList = () => {
  const navigate = useNavigate()
  const [showClassroomModal, setShowClassroomModal] = useState(false)
  const { hasProFeatures } = useSubscription()
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onTouched' })
  const [showArchived, setShowArchived] = useState(false)

  const { markOnboardingItemCompleted } = useOnboardingChecklist()

  const { isLoading, data: { classrooms = [] } = {} } = useQuery({
    queryKey: ['classrooms', showArchived],
    gqlQuery: CLASSROOMS_QUERY,
    variables: { showArchived }
  })
  const { mutateAsync: createClassroom, isLoading: isCreating } = useMutation({
    gqlMutation: CREATE_CLASSROOM_MUTATION,
    onSuccess: data => {
      navigate(`/educators/classrooms/${data.createClassroom.classroom.id}`)
      markOnboardingItemCompleted('createdClassroom')
    }
  })

  const submit = data => {
    createClassroom({ createClassroomInput: data })
  }

  return (
    <>
      <PageHeading title='Classrooms' />

      <Card className='p-5 md:mx-5 my-5 w-auto flex place-content-between shrink-0'>
        <Toggle
          value={showArchived}
          onChange={() => setShowArchived(!showArchived)}
          label='Show archived classrooms'
        />

        <If condition={!hasProFeatures}>
          <ProFeatureTooltip anchorSelect='#new-classroom-button' />
        </If>

        <Button
          id='new-classroom-button'
          disabled={!hasProFeatures}
          onClick={() => setShowClassroomModal(true)}
          label='New classroom'
        />
      </Card>

      <Choose>
        <When condition={isLoading}>
          <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
        </When>

        <When condition={classrooms.length < 1}>
          <NoResults openModal={() => setShowClassroomModal(true)} />
        </When>

        <Otherwise>
          <div className='overflow-x-scroll md:mx-5 mx-0 p-0.5'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header width='50%'>Name</Table.Header>
                  <Table.Header width='40%'>Students</Table.Header>
                  <Table.Header width='10%' />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='classroom' of={classrooms}>
                  <Table.Row key={classroom.id}>
                    <Table.Cell>
                      <Link className='block' to={`/educators/classrooms/${classroom.id}`}>
                        {classroom.name}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block' to={`/educators/classrooms/${classroom.id}`}>
                        {classroom.paginatedStudents.nodesCount} students
                      </Link>
                    </Table.Cell>

                    <Table.Cell>
                      <Link className='block text-blue-500' to={`/educators/classrooms/${classroom.id}`}>
                        view
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </div>
        </Otherwise>
      </Choose>

      <Modal
        isOpen={showClassroomModal}
        onClose={() => setShowClassroomModal(false)}
      >
        <form
          onSubmit={handleSubmit(submit)}
          className='flex flex-col space-y-4'
        >
          <h2 className='font-heading text-2xl font-bold'>New classroom</h2>

          <div>
            <TextInput
              id='name'
              label='Name'
              required
              {...register('name', { required: 'Name is required' })}
            />
            <If condition={errors.name}>
              <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.name.message}</p>
            </If>
          </div>

          <Button
            disabled={isCreating}
            className='w-fit ml-auto'
            type='submit'
            label={isCreating ? 'Saving...' : 'Save'}
          />
        </form>
      </Modal>
    </>
  )
}

export default ClassroomList
