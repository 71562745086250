const TextField = ({ stepIndex, maxIndex, value, setValue, nextStep, previousStep, title, subtitle, placeholder }) => {

  const isDisabled = !value || value.length < 10;

  return (
    <div className="max-w-md mx-auto pb-5">
      <h2 className="text-mirage-950 mb-4">
        {stepIndex} / {maxIndex}
      </h2>

      <h1 className="text-mirage-950 text-3xl font-semibold my-3">
        {title}
      </h1>

      <p className="text-mirage-800 text-lg font-normal">
        {subtitle}
      </p>

      <textarea
        rows={4}
        className="block w-full rounded-lg p-3 mt-5 mb-6 bg-mirage-50 border-0 shadow-[0_2px_1px_0_#EBEBF3]"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />

      <div className="flex gap-3 items-center">
        <button
          onClick={previousStep}
          className="bg-mirage-50 text-mirage-950 text-md rounded-lg py-3 px-5"
          tabIndex={1}
        >
          Back
        </button>
        <button
          onClick={nextStep}
          disabled={isDisabled}
          className="bg-mirage-950 text-white text-md rounded-lg py-3 px-5 shadow-[0_2.5px_32px_0_rgba(0,0,64,0.1)] transition-opacity duration-500 ease-in-out disabled:opacity-20"
          tabIndex={0}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default TextField
