import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { NodeViewWrapper } from '@tiptap/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const MathNode = ({ editor, selected, updateAttributes, getPos, ...props }) => {
  const mathFieldRef = useRef(null)

  const updateValue = value => {
    updateAttributes({
      value
    })
  }

  // Focus the math field when the node is selected.
  useEffect(() => {
    if (!selected) return

    const { from, to } = editor.state.selection

    // If only the math node is selected, focus the math field.
    if (from + 1 === to) {
      mathFieldRef.current.focus()
    }
  }, [selected, editor, mathFieldRef])

  // Display the virtual keyboard when the math field is focused.
  useEffect(() => {
    mathFieldRef.current.addEventListener('focus', () => {
      window.mathVirtualKeyboard.show()

      // Add margin bottom to editor to prevent the virtual keyboard from covering the math field
      const element = document.getElementById('math-keyboard-spacer')
      if (!element) return
      element.style.marginBottom = window.mathVirtualKeyboard.boundingRect.height + 'px'
    })

    mathFieldRef.current.addEventListener('blur-sm', () => {
      window.mathVirtualKeyboard.hide()

      // Remove margin bottom from editor
      const element = document.getElementById('math-keyboard-spacer')
      if (!element) return
      element.style.marginBottom = '0px'
    })

    mathFieldRef.current.addEventListener('move-out', e => {
      // Focus TipTap editor on the element before the math node.
      // Prevent default to avoid any unexpected behavior
      e.preventDefault()

      // Get the current position of the node
      const pos = getPos()

      if (e.detail.direction === 'forward' || e.detail.direction === 'downward') {
        // If moving forward (right), focus after the node
        editor.commands.focus(pos + props.node.nodeSize)
      } else {
        // If moving backward (left), focus before the node
        editor.commands.focus(pos - props.node.nodeSize)
      }
    })

    return () => {
      if (!mathFieldRef.current) return

      mathFieldRef.current.removeEventListener('focus')
      mathFieldRef.current.removeEventListener('blur-sm')
      mathFieldRef.current.removeEventListener('move-out')
    }
  }, [mathFieldRef, editor, getPos])

  return (
    <NodeViewWrapper className='math w-fit relative'>
      <If condition={selected}>
        <button
          type='button'
          aria-label='Remove attachment'
          onClick={() => props.deleteNode()}
          className='absolute top-[-10px] right-[-10px] p-1 bg-gray-300 rounded-full'
        >
          <XMarkIcon className='h-3 w-3 text-black' />
        </button>
      </If>

      <math-field
        ref={mathFieldRef}
        id='mathlive-input'
        math-virtual-keyboard-policy='manual'
        virtual-keyboards='numeric functions symbols roman greek'
        class={twMerge(
          'math',
          selected && 'ring-2 ring-inset ring-gray-300',
          'w-fit controls-hidden min-h-[40px] block rounded-md border-0 my-3 py-1.5 px-3 text-gray-900 focus-within:outline-hidden text-xl focus:ring-2 focus:ring-inset focus:ring-blue-600')}
        onInput={(e) => updateValue(e.target.value)}
      >
        {props.node.attrs.value}
      </math-field>
    </NodeViewWrapper>
  )
}

export default MathNode
