import { useState } from 'react'
import { gql } from 'graphql-request'
import { useMutation } from '@hooks/graphql'
import QRCode from 'react-qr-code'

import Modal from '@components/Modal'
import Button from '@components/Button'
import TextInput from '@components/TextInput'
import { useFlashMessage } from '@components/FlashMessage'

const MFA_ENABLE_MUTATION = gql`
  mutation mfaEnable($input: EnableInput!) {
    mfaEnable(input: $input) {
      success
    }
  }
`

const GENERATE_SECRET_MUTATION = gql`
  mutation generateSecret($input: GenerateSecretInput!) {
    mfaGenerateSecret(input: $input) {
      otpSecret
      email
      errors {
        message
      }
    }
  }
`

const EnableMFAModal = ({ onClose, onSuccess }) => {
  const [step, setStep] = useState('setup') // setup, verification
  const [otpCode, setOtpCode] = useState('')
  const [qrCode, setQrCode] = useState('')
  const [secret, setSecret] = useState('')
  const [error, setError] = useState('')

  const { setFlashMessage } = useFlashMessage()

  const { mutate: generateSecret, isLoading: isGeneratingSecret } = useMutation({
    gqlMutation: GENERATE_SECRET_MUTATION,
    onSuccess: (data) => {
      if (data.mfaGenerateSecret.otpSecret) {
        const otpSecret = data.mfaGenerateSecret.otpSecret;
        setSecret(otpSecret)
        
        // Generate QR code
        const appName = "Mindjoy"
        const identifier = data.mfaGenerateSecret.email
        const encodedLabel = encodeURIComponent(`${appName}:${identifier}`)
        const qrCodeData = `otpauth://totp/${encodedLabel}?secret=${otpSecret}&issuer=${encodeURIComponent(appName)}`
        
        setQrCode(qrCodeData)
        setStep('verification')
      } else if (data.mfaGenerateSecret.errors && data.mfaGenerateSecret.errors.length > 0) {
        setError(data.mfaGenerateSecret.errors[0].message)
      }
    },
    onError: (error) => {
      console.error('Error generating secret:', error);
      setError('An error occurred while generating the secret. Please try again.');
    }
  });

  const { mutate: enableMFA, isLoading } = useMutation({
    gqlMutation: MFA_ENABLE_MUTATION,
    onSuccess: (data) => {
      setFlashMessage('Multi-factor authentication successfully enabled', 'success')
      onSuccess()
      onClose()
    },
    onError: (error) => {
      console.error('Error enabling MFA:', error)
      setError('An error occurred while enabling MFA. Please try again.')
    }
  })

  const handleVerify = (e) => {
    e.preventDefault()
    
    if (!otpCode) {
      setError('Please enter the verification code')
      return
    }

    enableMFA({ 
      input: { 
        otpAttempt: otpCode
      }
    })
  }

  // Initialize MFA setup by generating a new secret
  const initializeMFASetup = () => {
    setError('');
    generateSecret({ input: {} });
  }

  return (
    <Modal
      title="Enable Multi-Factor Authentication"
      isOpen={true}
      onClose={onClose}
      className="p-6"
    >
      <Choose>
        <When condition={step === 'setup'}>
          <div>
            <p className="mb-4">
              Multi-factor Authentication adds an extra layer of security to your account by requiring a verification code in addition to your password.
            </p>
            <p>
              You'll need to set up an authentication app like Google Authenticator, Microsoft Authenticator, or Authy on your mobile device.
            </p>
            <If condition={isGeneratingSecret}>
              <p className="mt-4 text-gray-600">Preparing your security key...</p>
            </If>
            <If condition={!!error}>
              <p className="mt-4 text-red-500">{error}</p>
            </If>
            <div className="mt-6 flex justify-end">
              <Button
                onClick={initializeMFASetup}
                label="Continue"
                disabled={isGeneratingSecret}
              />
            </div>
          </div>
        </When>
        
        <When condition={step === 'verification'}>
          <form onSubmit={handleVerify}>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold mb-2">Step 1: Scan QR Code</h3>
                <p className="mb-4">
                  Scan this QR code with your authentication app:
                </p>
                <div className="bg-gray-100 p-4 mb-4 flex justify-center">
                  {/* Display QR code using react-qr-code */}
                  <Choose>
                    <When condition={!!qrCode}>
                      <QRCode
                        value={qrCode}
                        size={192}
                        className="w-48 h-48"
                      />
                    </When>
                    <Otherwise>
                      <div className="border-2 border-gray-400 w-48 h-48 flex items-center justify-center">
                        QR Code Placeholder
                      </div>
                    </Otherwise>
                  </Choose>
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-2">Step 2: Manual Entry Option</h3>
                <p className="mb-2">
                  If you can't scan the QR code, enter this code manually in your app:
                </p>
                <div className="bg-gray-100 p-3 font-mono text-center mb-4">
                  {secret}
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-2">Step 3: Verify</h3>
                <p className="mb-3">
                  Enter the 6-digit verification code from your authentication app:
                </p>
                <TextInput
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                  placeholder="6-digit code"
                  maxLength={6}
                  className="w-full"
                />
                <If condition={!!error}>
                  <p className="text-red-500 mt-2">{error}</p>
                </If>
                
                <div className="mt-6 flex justify-end">
                  <Button
                    type="submit"
                    label={
                      <Choose>
                        <When condition={isLoading}>Verifying...</When>
                        <Otherwise>Verify and Enable</Otherwise>
                      </Choose>
                    }
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </form>
        </When>
      </Choose>
    </Modal>
  )
}

export default EnableMFAModal 