import { useState } from 'react'
import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'

import Label from './Label'

const CopyToClipboardInput = ({ label, onChange, className, ...props }) => {
  const [codeCopied, setCodeCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.value)
  }

  return (
    <Label className={className} title={label}>
      <div className="relative">
        <input
          className="block font-normal w-full rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6 disabled:bg-gray-100"
          type="text"
          onChange={onChange}
          disabled
          {...props}
        />
        <button
          aria-label="Copy to clipboard"
          type="button"
          className="absolute p-0.5 top-1/2 rounded-lg transform -translate-y-1/2 right-3 hover:bg-gray-200 bg-gray-100"
          onClick={() => {
            copyToClipboard()
            setCodeCopied(true)
            setTimeout(() => setCodeCopied(false), 1000)
          }}>
          <Choose>
            <When condition={codeCopied}>
              <CheckIcon className="h-6 w-6 text-gray-500" />
            </When>

            <Otherwise>
              <DocumentDuplicateIcon className="h-6 w-6 text-gray-500" />
            </Otherwise>
          </Choose>
        </button>
      </div>
    </Label>
  )
}

export default CopyToClipboardInput
