import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { CheckIcon, ClockIcon, DocumentCheckIcon, LightBulbIcon } from '@heroicons/react/24/outline'

const WrapperComponent = ({
  children,
  id,
  courseId,
  isNextProject,
  isInProgress,
  isCompleted,
  isReturned,
  isAssignmentClosed
}) => (
  <Choose>
    <When condition={isInProgress || isCompleted || isReturned || isNextProject}>
      <Link
        className={twMerge(
          'flex flex-row w-full p-3 sm:rounded-lg shadow-sm bg-white hover:bg-gray-100 hover:cursor-pointer',
          isNextProject && !isAssignmentClosed ? 'border-2 border-green-600 hover:bg-green-100' : 'border-2 border-transparent'
        )}
        to={`/students/courses/${courseId}/lessons/${id}`}
      >
        {children}
      </Link>
    </When>

    <Otherwise>
      <div className='flex flex-row w-full p-3 sm:rounded-lg shadow-sm bg-gray-100 hover:cursor-disabled'>
        {children}
      </div>
    </Otherwise>
  </Choose>
)

const Lesson = ({
  id,
  name,
  status,
  courseId,
  isNextProject,
  projectPath,
  isAssignmentClosed,
  score,
  totalPossibleScore,
  isCourseInsightsEnabled
}) => {
  return (
    <WrapperComponent
      id={id}
      courseId={courseId}
      isCompleted={status === 'COMPLETED'}
      isInProgress={status === 'IN_PROGRESS'}
      isReturned={status === 'RETURNED'}
      isNextProject={isNextProject}
      projectPath={projectPath}
      isAssignmentClosed={isAssignmentClosed}
    >

      <div className='w-full text-left'>
        <p className='text-lg font-semibold'>{name}</p>

        <Choose>
          <When condition={status === 'IN_PROGRESS'}>
            <p className='text-sm text-gray-500 inline-block'>In progress</p>
            <ClockIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <When condition={status === 'COMPLETED'}>
            <p className='text-sm text-gray-500 inline-block'>Completed</p>
            <CheckIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <When condition={status === 'RETURNED'}>
            <p className='text-sm text-gray-500 inline-block'>Returned</p>
            <DocumentCheckIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <Otherwise>
            <p className='text-sm text-gray-500'>Not started</p>
          </Otherwise>
        </Choose>
      </div>

      <If condition={(status === 'COMPLETED' || status === 'RETURNED') && isCourseInsightsEnabled}>
        <Link className='flex shrink-0 text-sm self-end text-blue-500 mr-3 hover:bg-blue-100 rounded-md p-1' to={`/students/courses/${courseId}/lessons/${id}/insights`}>
          <LightBulbIcon className='size-5 inline-block mr-1' />
          Insights
        </Link>
      </If>

      <If condition={status === 'RETURNED' && totalPossibleScore > 0}>
        <p className='flex text-sm self-end text-gray-500 shrink-0 bg-gray-100 rounded-md p-1 border border-gray-300'>
          {score} / {totalPossibleScore}
        </p>
      </If>
    </WrapperComponent>
  )
}

export default Lesson
