import { twMerge } from 'tailwind-merge'
import { PlusIcon } from '@heroicons/react/24/outline'

import { formatTimestamp } from '@helpers/format'
import Spinner from '@components/Spinner'
import Image from '@components/Image'

const ChatList = ({ pages, selectedChatId, setSelectedChatId, createChat, isCreating, hasNextPage, fetchNextPage, imageUrl }) => {
  return (
    <div className='flex flex-1 flex-col overflow-y-auto p-3 shrink-0'>
      <If condition={imageUrl}>
        <Image
          className='object-cover w-full h-[200px] rounded-lg mb-3'
          src={imageUrl}
          placeholder={<div className='rounded-lg h-[200px] w-full bg-gray-200 animate-pulse border shadow-sm mb-3' />}
        />
      </If>

      <button
        onClick={() => createChat()}
        className='text-purple-500 flex mb-3'
      >
        <Choose>
          <When condition={isCreating}>
            <Spinner className='self-center' />
          </When>

          <Otherwise>
            <PlusIcon className='h-6 w-6 mr-2' />
            New chat
          </Otherwise>
        </Choose>
      </button>

      <For each='page' of={pages}>
        <For each='chat' of={page.node.currentUserChats.nodes}>
          <div
            className={twMerge(
              'flex items-center justify-between bg-white shadow-sm rounded-lg w-full mb-3 border-2 border-gray-200',
              chat.id === selectedChatId && 'border-purple-500'
            )}
          >
            <button className='p-3 h-full text-left' onClick={() => setSelectedChatId(chat.id)}>
              {formatTimestamp(chat.createdAt)}
            </button>
          </div>
        </For>
      </For>

      <If condition={hasNextPage}>
        <button
          onClick={fetchNextPage}
          className='text-purple-500 self-center'
        >
          Load more
        </button>
      </If>
    </div>
  )
}

export default ChatList;
