const options = {
  learningGoal: [
    {
      emoji: '🎓',
      title: 'Doing well academically',
      value: 'academic_excellence'
    },
    {
      emoji: '🌈',
      title: 'Learning new things for fun',
      value: 'learn_for_fun'
    },
    {
      emoji: '💼',
      title: 'Growing my career skills',
      value: 'grow_career_skills'
    },
    {
      emoji: '🧩',
      title: 'Filling a specific knowledge gap',
      value: 'fill_knowledge_gap'
    },
    {
      emoji: '📝',
      title: 'Getting ready for a test or exam',
      value: 'prepare_for_test'
    }
  ],
  learningFocus: [
    {
      emoji: '‍👩‍💻',
      title: 'Learning specific skills',
      value: 'learn_specific_skills'
    },
    {
      emoji: '🔎',
      title: 'Following my curiosity',
      value: 'follow_curiosity'
    },
    {
      emoji: '🛠',
      title: 'Building my problem-solving skills',
      value: 'build_problem_solving_skills'
    },
    {
      emoji: '✏️',
      title: 'Mastering the basics',
      value: 'master_basics'
    }
  ],
  learningPreference: [
    {
      emoji: '👀',
      title: 'Seeing and drawing',
      subtitle: 'I like mind maps, drawings, and diagrams.',
      value: 'visual'
    },
    {
      emoji: '✏️',
      title: 'Reading and writing',
      subtitle: 'I like writing things down and reading notes.',
      value: 'textual'
    },
    {
      emoji: '🎧',
      title: 'Speaking and listening',
      subtitle: 'I like hearing explanations and talking about ideas.',
      value: 'auditory'
    },
    {
      emoji: '🤔',
      title: 'Mixed',
      subtitle: 'I like a variety of methods for different things.',
      value: 'mixed'
    }
  ],
  learningMotivator: [
    {
      emoji: '✨',
      title: 'Autonomy',
      subtitle: 'I enjoy learning in a way that fits my goals.',
      value: 'autonomy'
    },
    {
      emoji: '🏆',
      title: 'Excellence',
      subtitle: "I'm driven by the desire to improve my abilities.",
      value: 'excellence'
    },
    {
      emoji: '❤️',
      title: 'Community',
      subtitle: 'I feel motivated when I connect with peers.',
      value: 'community'
    },
    {
      emoji: '👀',
      title: 'Recognition',
      subtitle: 'I feel energised when my efforts are noticed.',
      value: 'recognition'
    },
    {
      emoji: '💡',
      title: 'Curiosity',
      subtitle: "I'm inspired when exploring new ideas.",
      value: 'curiosity'
    },
    {
      emoji: '🌍',
      title: 'Purpose',
      subtitle: 'I want to do meaningful things in the world.',
      value: 'purpose'
    }
  ]
}

export default options
