import { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '@components/Button'
import Table from '@components/Table'

import NoResults from './NoResults'
import InviteEducatorsModal from './InviteEducatorsModal'
import EducatorListActions from './EducatorListActions'
import ExtendTrialsModal from './ExtendTrialsModal'

const EducatorList = ({ organizationId, educators, refetch }) => {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showExtendTrialsModal, setShowExtendTrialsModal] = useState(false)

  return (
    <div className='mx-5 my-3'>
      <div className='flex flex-col w-full pb-10'>
        <div className='flex justify-end gap-3 mb-3 mr-5 md:mr-0'>
          <Button
            variant='outlined'
            label='Extend trials'
            onClick={() => setShowExtendTrialsModal(true)}
          />
          <Button
            label='Invite educators'
            onClick={() => setShowInviteModal(true)}
          />
        </div>

        <Choose>
          <When condition={educators.length === 0}>
            <NoResults />
          </When>

          <Otherwise>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Name</Table.Header>
                  <Table.Header>Email</Table.Header>
                  <Table.Header>Role</Table.Header>
                  <Table.Header />
                </Table.Row>
              </Table.Head>

              <Table.Body>
                <For each='educator' of={educators}>
                  <Table.Row key={educator.profile.id}>
                    <Table.Cell>
                      <Link className='block' to={`${educator.profile.id}`}>
                        {educator.profile.fullName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link className='block' to={`${educator.profile.id}`}>
                        {educator.profile.email}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link className='block' to={`${educator.profile.id}`}>
                        {educator.role.toLowerCase()}
                      </Link>
                    </Table.Cell>
                    <Table.Cell className='flex items-center justify-end'>
                      <EducatorListActions
                        id={educator.profile.id}
                        userId={educator.profile.user.id}
                        organizationId={organizationId}
                        email={educator.profile.email}
                        fullName={educator.profile.fullName}
                        role={educator.role}
                        refetch={refetch}
                      />
                    </Table.Cell>
                  </Table.Row>
                </For>
              </Table.Body>
            </Table>
          </Otherwise>
        </Choose>
      </div>

      <InviteEducatorsModal
        organizationId={organizationId}
        isOpen={showInviteModal}
        closeModal={() => setShowInviteModal(false)}
      />

      <ExtendTrialsModal
        organizationId={organizationId}
        isOpen={showExtendTrialsModal}
        closeModal={() => setShowExtendTrialsModal(false)}
      />
    </div>
  )
}

export default EducatorList
